:root {
  --ffsd: 0px;
  --1vw: calc((100vw - var(--sbw, 0px)) / 100);
  --1vh: var(--inner1Vh, 1vh);
}

@media (prefers-reduced-motion: reduce) {
  .animated {
    animation: none !important;
  }
}

html {
  zoom: var(--rzf, 1);
  font-size: max(calc(min(var(--1vw, 1vw), 13.66px) * var(--rfso, 1)), var(--minfs, 0px));
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

body {
  font-size: calc(1rem * var(--bfso, 1));
}

body,
html,
p,
ul,
ol,
li {
  margin: 0;
  padding: 0;
  font-synthesis: none;
  font-kerning: none;
  font-variant-ligatures: none;
  font-feature-settings: "kern" 0, "calt" 0, "liga" 0, "clig" 0, "dlig" 0, "hlig" 0;
  font-family: unset;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricprecision;
  white-space: normal;
}

li {
  text-align: unset;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  -webkit-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-touch-callout: none;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: italic;
  font-weight: 100;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: italic;
  font-weight: 100;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../../public/fonts/OpenSans.ttf);
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: YAFdJt8dAY0-b;
  src: url(../../../public/fonts/OpenSans-Bold.ttf);
  font-style: nprmal;
  font-weight: 900;
}

@media (max-width: 375px) {
  #OqsN2dWNJz0dIZvP {
    grid-area: 2 / 2 / 4 / 4;
    position: relative;
  }

  #wKMkfaDmuqZ7EL4H {
    grid-area: 3 / 3 / 5 / 5;
    position: relative;
    clip-path: polygon(calc(5.81069312% + 0%) calc((0.04713676 * 100rem) + 0%), calc(100% - (0.59787622% + 0%)) calc((0.04713676 * 100rem) + 0%), calc(100% - (0.59787622% + 0%)) calc(100% - ((0.00013726 * 100rem) + 0%)), calc(5.81069312% + 0%) calc(100% - ((0.00013726 * 100rem) + 0%)));
    margin-left: -6.20857388%;
    margin-right: -0.63881513%;
    margin-bottom: -0.01372621%;
    margin-top: -4.71367625%;
  }

  #oyFLJsABht87qRYA {
    grid-template-columns: 0 0 100% 0;
    grid-template-rows: 0 0 100% 0;
  }

  #znDzx9uq82zRePeS {
    grid-area: 2 / 2 / 11 / 11;
    position: relative;
  }

  #Y5LcI1eqCsmC32CV {
    grid-area: 2 / 2 / 29 / 29;
    position: relative;
  }

  #oBThJfU6tD300DQE {
    stroke-width: calc(100rem * 0.0 / 375.0);
  }

  #ZEoizaRHe7bZECcT {
    grid-area: 3 / 5 / 7 / 8;
    position: relative;
  }

  #zZsJapxwbcbPSxWJ {
    font-size: calc(1.17848707em - var(--ffsd));
  }

  #KEne4nohIFkh8FBf {
    --first-font-size: 1.17848707em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #zwX8vilUbYQ08nZL {
    min-width: 4.94024298rem;
  }

  #ZlD5SxsxfsZ2Mvy4 {
    grid-area: 8 / 3 / 10 / 7;
    position: relative;
  }

  #GUakv73886zOAUPq {
    grid-area: 11 / 4 / 15 / 9;
    position: relative;
  }

  #sAG6HMCqxXBPKKLc {
    grid-area: 19 / 6 / 26 / 10;
    position: relative;
  }

  #jnrVRUU53B2fCAHP {
    grid-area: 13 / 11 / 20 / 14;
    position: relative;
  }

  #jb9HpuJVsfxE6N9W {
    font-size: calc(1.18605637em - var(--ffsd));
  }

  #RAUOMvJvxwWTexs1 {
    --first-font-size: 1.18605637em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #PZrXwhL4nQx6TjAN {
    min-width: 4.35755834rem;
  }

  #hEnnesjQvbnuNdJz {
    grid-area: 21 / 12 / 24 / 13;
    position: relative;
  }

  #bV8ZVBpbkUz4uAZl {
    grid-area: 14 / 15 / 22 / 17;
    position: relative;
  }

  #nZhBlEiXWPiUxJo0 {
    font-size: calc(1.17830029em - var(--ffsd));
  }

  #EjcIhZMpf9iCmVjd {
    --first-font-size: 1.17830029em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #vKgPANXP9AhoG2CJ {
    min-width: 5.0038699rem;
  }

  #FPnmhk4ZGR4mC228 {
    grid-area: 23 / 16 / 25 / 18;
    position: relative;
  }

  #lbq8K7o6oawkjLTP {
    grid-area: 4 / 22 / 5 / 26;
    position: relative;
  }

  #DN6SSQPqn4mnxENP {
    font-size: calc(1.17848707em - var(--ffsd));
  }

  #neXnyDARM09GG5vH {
    --first-font-size: 1.17848707em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #yVRfpCdZ2PLqM9AL {
    min-width: 4.60799859rem;
  }

  #D1ZqOerd2c4MvUky {
    grid-area: 6 / 23 / 9 / 27;
    position: relative;
  }

  #eFXDw6lelKuAPXbY {
    grid-area: 12 / 19 / 16 / 24;
    position: relative;
  }

  #FOAZ2finbqk9NlMs {
    font-size: calc(1.23551599em - var(--ffsd));
  }

  #q6a32FiEUQa2AFUS {
    --first-font-size: 1.23551599em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #TmNIgOXPK21btcd8 {
    min-width: 5.24037255rem;
  }

  #krJ1PeZrtS7reFNz {
    grid-area: 17 / 20 / 18 / 25;
    position: relative;
  }

  #Lx3eASFtfM01wzTq {
    grid-area: 2 / 3 / 3 / 4;
    position: relative;
  }

  #eUISANMG3dWcg8cF {
    font-size: calc(1.17848707em - var(--ffsd));
  }

  #JSev41LwJbTR3dwc {
    --first-font-size: 1.17848707em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #uLtG7tsBVHcB8tQr {
    min-width: 6.48588152rem;
  }

  #GqviADSBMeR7y5af {
    grid-area: 4 / 2 / 5 / 5;
    position: relative;
  }

  #T43u1e6hvdOPtjQh {
    grid-template-columns: 0 1.0274732rem 4.29760179rem 1.0274732rem;
    grid-template-rows: 0 minmax(4.38180645rem, max-content) minmax(0.16174056rem, max-content) minmax(1.37133041rem, max-content);
  }

  #kn97wPNLMBp5ebrQ {
    grid-template-columns: 0 1.03542261rem 0 0.0248264rem 0 4.78208325rem 0.28259159rem 0 0.0248264rem 2.60921479rem 1.04642797rem 4.22422501rem 0.33918576rem 4.74779296rem 0.70261728rem 4.87053657rem 0 4.13569562rem 0.73673485rem 4.07956602rem 0.17345303rem 0 0.85402016rem 0 3.62064509rem 0 1.7044299rem 0.60533098rem;
    grid-template-rows: 0 minmax(1.25193572rem, max-content) minmax(0.29500479rem, max-content) minmax(4.47466526rem, max-content) 0 minmax(0.29500479rem, max-content) minmax(0.39066042rem, max-content) minmax(0.6856652rem, max-content) minmax(0.6856652rem, max-content) minmax(0.08291527rem, max-content) minmax(0.34594511rem, max-content) minmax(1.93863675rem, max-content) minmax(2.54475062rem, max-content) minmax(0.26016876rem, max-content) minmax(1.10021794rem, max-content) 0 minmax(1.47964862rem, max-content) minmax(0.20978968rem, max-content) minmax(0.01526313rem, max-content) 0 minmax(2.50806572rem, max-content) 0 minmax(0.57029345rem, max-content) minmax(0.84083391rem, max-content) minmax(1.15504503rem, max-content) minmax(1.30840208rem, max-content) minmax(5.91487742rem, max-content) minmax(1.06191068rem, max-content);
  }

  #GoGORTLYxzrR7X6X {
    grid-area: 4 / 7 / 10 / 8;
    position: relative;
  }

  #P2XxREr5GFW3GJqp {
    grid-area: 5 / 4 / 8 / 6;
    position: relative;
  }

  #wBjmx07a0TUzHNgz {
    grid-area: 6 / 5 / 9 / 10;
    position: relative;
  }

  #pkXUoFRqAeXqm8WY {
    grid-template-columns: 0 5.13697382rem 0 2.64785329rem 1.62682366rem 0.74234941rem 4.26227619rem 31.32032369rem 0 5.1073533rem;
    grid-template-rows: 0 minmax(4.89387427rem, max-content) minmax(24.00943353rem, max-content) minmax(4.26462164rem, max-content) minmax(0.07939968rem, max-content) minmax(1.06191068rem, max-content) minmax(0.96742311rem, max-content) minmax(0.09448758rem, max-content) minmax(0.59858335rem, max-content) minmax(15.19399282rem, max-content);
  }

  #JTgx7u7Qa1RKLRFN {
    grid-area: 2 / 5 / 12 / 8;
    position: relative;
  }

  #dhV3p3fCQDn5y7oJ {
    grid-area: 3 / 9 / 5 / 12;
    position: relative;
  }

  #oFANS7V1QSuAiKdj {
    grid-area: 2 / 2 / 5 / 5;
    position: relative;
  }

  #b4adkyrn7x5PE2Nh {
    grid-area: 3 / 3 / 4 / 4;
    position: relative;
  }

  #p5vErsmSRsjWjNFP {
    grid-template-columns: 0 1.60000149rem 14.28394393rem 7.75644378rem;
    grid-template-rows: 0 minmax(1.44659216rem, max-content) minmax(14.28394393rem, max-content) minmax(1.08369072rem, max-content);
  }

  #MSWnB8FTDmgj24i4 {
    grid-area: 4 / 3 / 6 / 7;
    position: relative;
  }

  #SxsrVJ1tSwpYzFEU {
    grid-area: 7 / 4 / 10 / 6;
    position: relative;
  }

  #XMtUYKCOD1OyTybP {
    grid-area: 8 / 10 / 11 / 11;
    position: relative;
  }

  #vYOYARE87xLSgJRl {
    grid-area: 9 / 2 / 13 / 13;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: -4.26666667rem;
    right: -4.26666667rem;
    clip-path: polygon(0% calc((0 * 91.46666667rem) + 0%), 100% calc((0 * 91.46666667rem) + 0%), 100% calc(100% - ((0.1115322 * 91.46666667rem) + 0%)), 0% calc(100% - ((0.1115322 * 91.46666667rem) + 0%)));
    margin-left: -3.48790157%;
    margin-right: -1.88860708%;
    margin-bottom: -11.15321955%;
    margin-top: 0%;
  }

  #dLX3STwT8vA1TRta {
    grid-template-columns: 4.26666667rem 6.63116494rem 3.40655315rem 8.41364144rem 6.17239643rem 5.64779817rem 39.02375877rem 0 1.07672438rem 12.55764472rem 3.71844216rem 4.8185425rem 4.26666667rem;
    grid-template-rows: minmax(0.99011006rem, max-content) minmax(4.98645709rem, max-content) minmax(0.00776553rem, max-content) minmax(7.80099954rem, max-content) minmax(9.01322728rem, max-content) minmax(3.7734139rem, max-content) minmax(6.85465749rem, max-content) minmax(5.80898158rem, max-content) minmax(6.91493525rem, max-content) minmax(5.18359791rem, max-content) minmax(0.81969109rem, max-content) minmax(0.01254878rem, max-content);
  }

  #LepCE1qH99a70ORi {
    min-height: calc(34.78273559 * var(--1vh, 1vh));
  }

  #kTSajshTOj0Nxj8x {
    grid-area: 2 / 2 / 4 / 4;
    position: relative;
  }

  #Nev0cJY7mPKEfdC7 {
    grid-area: 3 / 3 / 5 / 5;
    position: relative;
    clip-path: polygon(calc(6.64745701% + 0%) calc((0.02424923 * 173.97188473rem) + 0%), calc(100% - (1.53912369% + 0%)) calc((0.02424923 * 173.97188473rem) + 0%), calc(100% - (1.53912369% + 0%)) calc(100% - ((0.03482805 * 173.97188473rem) + 0%)), calc(6.64745701% + 0%) calc(100% - ((0.03482805 * 173.97188473rem) + 0%)));
    margin-left: -7.2401802%;
    margin-right: -1.67636028%;
    margin-bottom: -3.48280525%;
    margin-top: -2.42492253%;
  }

  #aKI0wS7OtSyHdMdl {
    grid-template-columns: 0 0 173.97188473% 0;
    left: -36.98594236%;
    grid-template-rows: 0 0 100% 0;
  }

  #rnplIgVxKJsEqApo {
    grid-area: 2 / 2 / 4 / 4;
    position: relative;
  }

  #Hbmqhk4G9RETOeMQ {
    grid-area: 2 / 2 / 13 / 13;
    position: relative;
  }

  #UHx4Zugbf9XHa3lP {
    font-size: calc(4.0041846em - var(--ffsd));
  }

  #T5dq7bMNN2RFcINL {
    --first-font-size: 4.0041846em;
    --last-font-size: var(--first-font-size);
    transform: translateX(calc(var(--first-font-size) * -0.016));
    margin-top: calc(var(--first-font-size) * 0.35);
    margin-bottom: calc(var(--last-font-size) * 0.35);
  }

  #kIJyDrwfAAzBqpU7 {
    min-width: 30.51057336rem;
  }

  #CwdWQBLO94jyEhUJ {
    grid-area: 3 / 7 / 4 / 12;
    position: relative;
  }

  #Eg0viCLNOGCzvH0z {
    font-size: calc(4.0041846em - var(--ffsd));
  }

  #G56aSqbHlQcyhFbc {
    --first-font-size: 4.0041846em;
    --last-font-size: var(--first-font-size);
    transform: translateX(calc(var(--first-font-size) * -0.016));
    margin-top: calc(var(--first-font-size) * 0.35);
    margin-bottom: calc(var(--last-font-size) * 0.35);
  }

  #iPcYmMsD3tlsIBG9 {
    min-width: 15.69441314rem;
  }

  #Vx9NPAGIerWsKCCO {
    grid-area: 5 / 6 / 6 / 8;
    position: relative;
  }

  #btvB0DXn4Iny0c8H {
    font-size: calc(4.0041846em - var(--ffsd));
  }

  #YqGUao9bBR8JoIca {
    --first-font-size: 4.0041846em;
    --last-font-size: var(--first-font-size);
    transform: translateX(calc(var(--first-font-size) * -0.016));
    margin-top: calc(var(--first-font-size) * 0.35);
    margin-bottom: calc(var(--last-font-size) * 0.35);
  }

  #J0mWfXUDNyVOSqP9 {
    min-width: 17.95980789rem;
  }

  #JiU5wzn8HfKfJeYx {
    grid-area: 7 / 3 / 8 / 9;
    position: relative;
  }

  #kQ2nZow0ri6YnKWs {
    font-size: calc(4.0041846em - var(--ffsd));
  }

  #JjNSja5veWoxbGUr {
    --first-font-size: 4.0041846em;
    --last-font-size: var(--first-font-size);
    transform: translateX(calc(var(--first-font-size) * -0.016));
    margin-top: calc(var(--first-font-size) * 0.35);
    margin-bottom: calc(var(--last-font-size) * 0.35);
  }

  #H80PzYxC8GlULTvo {
    min-width: 25.82177377rem;
  }

  #XC49D7rFAG9bMpiB {
    grid-area: 9 / 4 / 10 / 11;
    position: relative;
  }

  #lL5bi5dsw434D1fe {
    font-size: calc(4.0041846em - var(--ffsd));
  }

  #w0XS94Nsi1cu2I3g {
    --first-font-size: 4.0041846em;
    --last-font-size: var(--first-font-size);
    transform: translateX(calc(var(--first-font-size) * -0.016));
    margin-top: calc(var(--first-font-size) * 0.35);
    margin-bottom: calc(var(--last-font-size) * 0.35);
  }

  #Y9cko4qoeleSE6fV {
    min-width: 24.7043944rem;
  }

  #eZCVnmBqFzYkFZhR {
    grid-area: 11 / 5 / 12 / 10;
    position: relative;
  }

  #csTTVYjNsZmkEQyf {
    grid-template-columns: 0 6.1192397rem 0 0 0.00161317rem 0.0725571rem 15.4885227rem 2.26378158rem 6.74458651rem 1.11737937rem 4.76296986rem 37.31879591rem;
    grid-template-rows: 0 minmax(6.09499219rem, max-content) minmax(4.73778527rem, max-content) minmax(0.77394242rem, max-content) minmax(4.73778527rem, max-content) minmax(0.77394242rem, max-content) minmax(4.73778527rem, max-content) minmax(0.76070469rem, max-content) minmax(4.73778527rem, max-content) minmax(0.76070469rem, max-content) minmax(4.73778527rem, max-content) minmax(4.46095739rem, max-content);
  }

  #y77AlqzCid4FXdOu {
    grid-area: 3 / 3 / 5 / 5;
    position: relative;
  }

  #plvX7Vf2FrLKtHzJ {
    grid-template-columns: 4.26666667rem 17.57722077rem 4.97040492rem 68.91904099rem 4.26666667rem;
    grid-template-rows: minmax(26.15935906rem, max-content) minmax(8.17853737rem, max-content) minmax(28.11542751rem, max-content) minmax(9.19874267rem, max-content) minmax(26.15935906rem, max-content);
  }

  #VdeILkon1psweVJf {
    min-height: calc(65.21726441 * var(--1vh, 1vh));
  }
}

@media (min-width: 375.05px) and (max-width: 480px) {
  #OqsN2dWNJz0dIZvP {
    grid-area: 2 / 2 / 4 / 4;
    position: relative;
  }

  #wKMkfaDmuqZ7EL4H {
    grid-area: 3 / 3 / 5 / 5;
    position: relative;
    clip-path: polygon(calc(5.81069312% + 0%) calc((0.04713676 * 100rem) + 0%), calc(100% - (0.59787622% + 0%)) calc((0.04713676 * 100rem) + 0%), calc(100% - (0.59787622% + 0%)) calc(100% - ((0.00013726 * 100rem) + 0%)), calc(5.81069312% + 0%) calc(100% - ((0.00013726 * 100rem) + 0%)));
    margin-left: -6.20857388%;
    margin-right: -0.63881513%;
    margin-bottom: -0.01372621%;
    margin-top: -4.71367625%;
  }

  #oyFLJsABht87qRYA {
    grid-template-columns: 0 0 100% 0;
    grid-template-rows: 0 0 100% 0;
  }

  #znDzx9uq82zRePeS {
    grid-area: 2 / 2 / 11 / 11;
    position: relative;
  }

  #Y5LcI1eqCsmC32CV {
    grid-area: 2 / 2 / 29 / 29;
    position: relative;
  }

  #oBThJfU6tD300DQE {
    stroke-width: calc(100rem * 0.0 / 480.0);
  }

  #ZEoizaRHe7bZECcT {
    grid-area: 3 / 5 / 7 / 8;
    position: relative;
  }

  #zZsJapxwbcbPSxWJ {
    font-size: calc(1.20253782em - var(--ffsd));
  }

  #KEne4nohIFkh8FBf {
    --first-font-size: 1.20253782em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #zwX8vilUbYQ08nZL {
    min-width: 5.00917651rem;
  }

  #ZlD5SxsxfsZ2Mvy4 {
    grid-area: 8 / 3 / 10 / 7;
    position: relative;
  }

  #GUakv73886zOAUPq {
    grid-area: 11 / 4 / 15 / 9;
    position: relative;
  }

  #sAG6HMCqxXBPKKLc {
    grid-area: 19 / 6 / 26 / 10;
    position: relative;
  }

  #jnrVRUU53B2fCAHP {
    grid-area: 13 / 11 / 20 / 14;
    position: relative;
  }

  #jb9HpuJVsfxE6N9W {
    font-size: calc(1.2102616em - var(--ffsd));
  }

  #RAUOMvJvxwWTexs1 {
    --first-font-size: 1.2102616em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #PZrXwhL4nQx6TjAN {
    min-width: 4.41460035rem;
  }

  #hEnnesjQvbnuNdJz {
    grid-area: 21 / 12 / 24 / 13;
    position: relative;
  }

  #bV8ZVBpbkUz4uAZl {
    grid-area: 14 / 15 / 22 / 17;
    position: relative;
  }

  #nZhBlEiXWPiUxJo0 {
    font-size: calc(1.20234724em - var(--ffsd));
  }

  #EjcIhZMpf9iCmVjd {
    --first-font-size: 1.20234724em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #vKgPANXP9AhoG2CJ {
    min-width: 5.07410194rem;
  }

  #FPnmhk4ZGR4mC228 {
    grid-area: 23 / 16 / 25 / 18;
    position: relative;
  }

  #lbq8K7o6oawkjLTP {
    grid-area: 4 / 22 / 5 / 26;
    position: relative;
  }

  #DN6SSQPqn4mnxENP {
    font-size: calc(1.20253782em - var(--ffsd));
  }

  #neXnyDARM09GG5vH {
    --first-font-size: 1.20253782em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #yVRfpCdZ2PLqM9AL {
    min-width: 4.67015162rem;
  }

  #D1ZqOerd2c4MvUky {
    grid-area: 6 / 23 / 9 / 27;
    position: relative;
  }

  #eFXDw6lelKuAPXbY {
    grid-area: 12 / 19 / 16 / 24;
    position: relative;
  }

  #FOAZ2finbqk9NlMs {
    font-size: calc(1.2607306em - var(--ffsd));
  }

  #q6a32FiEUQa2AFUS {
    --first-font-size: 1.2607306em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #TmNIgOXPK21btcd8 {
    min-width: 5.31543117rem;
  }

  #krJ1PeZrtS7reFNz {
    grid-area: 17 / 20 / 18 / 25;
    position: relative;
  }

  #Lx3eASFtfM01wzTq {
    grid-area: 2 / 3 / 3 / 4;
    position: relative;
  }

  #eUISANMG3dWcg8cF {
    font-size: calc(1.20253782em - var(--ffsd));
  }

  #JSev41LwJbTR3dwc {
    --first-font-size: 1.20253782em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #uLtG7tsBVHcB8tQr {
    min-width: 6.58635869rem;
  }

  #GqviADSBMeR7y5af {
    grid-area: 4 / 2 / 5 / 5;
    position: relative;
  }

  #T43u1e6hvdOPtjQh {
    grid-template-columns: 0 1.04844204rem 4.38530795rem 1.04844204rem;
    grid-template-rows: 0 minmax(4.47123108rem, max-content) minmax(0.16504139rem, max-content) minmax(1.39931674rem, max-content);
  }

  #kn97wPNLMBp5ebrQ {
    grid-template-columns: 0 1.05655369rem 0 0.02533306rem 0 4.87967678rem 0.28835876rem 0 0.02533306rem 2.66246408rem 1.06778365rem 4.31043368rem 0.34610792rem 4.8446867rem 0.71695641rem 4.96993527rem 0 4.22009757rem 0.75177025rem 4.16282247rem 0.17699289rem 0 0.87144915rem 0 3.69453581rem 0 1.73921418rem 0.61768468rem;
    grid-template-rows: 0 minmax(1.27748543rem, max-content) minmax(0.30102529rem, max-content) minmax(4.56598496rem, max-content) 0 minmax(0.30102529rem, max-content) minmax(0.39863308rem, max-content) minmax(0.69965837rem, max-content) minmax(0.69965837rem, max-content) minmax(0.08460742rem, max-content) minmax(0.35300522rem, max-content) minmax(1.97820076rem, max-content) minmax(2.5966843rem, max-content) minmax(0.26547832rem, max-content) minmax(1.12267137rem, max-content) 0 minmax(1.50984553rem, max-content) minmax(0.2140711rem, max-content) minmax(0.01557462rem, max-content) 0 minmax(2.55925073rem, max-content) 0 minmax(0.58193209rem, max-content) minmax(0.85799378rem, max-content) minmax(1.17861738rem, max-content) minmax(1.33510416rem, max-content) minmax(6.03558921rem, max-content) minmax(1.08358233rem, max-content);
  }

  #GoGORTLYxzrR7X6X {
    grid-area: 4 / 7 / 10 / 8;
    position: relative;
  }

  #P2XxREr5GFW3GJqp {
    grid-area: 5 / 4 / 8 / 6;
    position: relative;
  }

  #wBjmx07a0TUzHNgz {
    grid-area: 6 / 5 / 9 / 10;
    position: relative;
  }

  #pkXUoFRqAeXqm8WY {
    grid-template-columns: 0 5.24181002rem 0 2.70189111rem 1.66002415rem 0.7574994rem 4.34926142rem 31.95951397rem 0 5.211585rem;
    grid-template-rows: 0 minmax(4.99374925rem, max-content) minmax(24.49942197rem, max-content) minmax(4.35165474rem, max-content) minmax(0.08102008rem, max-content) minmax(1.08358233rem, max-content) minmax(0.98716643rem, max-content) minmax(0.0964159rem, max-content) minmax(0.61079933rem, max-content) minmax(15.50407431rem, max-content);
  }

  #JTgx7u7Qa1RKLRFN {
    grid-area: 2 / 5 / 12 / 8;
    position: relative;
  }

  #dhV3p3fCQDn5y7oJ {
    grid-area: 3 / 9 / 5 / 12;
    position: relative;
  }

  #oFANS7V1QSuAiKdj {
    grid-area: 2 / 2 / 5 / 5;
    position: relative;
  }

  #b4adkyrn7x5PE2Nh {
    grid-area: 3 / 3 / 4 / 4;
    position: relative;
  }

  #p5vErsmSRsjWjNFP {
    grid-template-columns: 0 1.63265458rem 14.57545299rem 7.91473855rem;
    grid-template-rows: 0 minmax(1.47611445rem, max-content) minmax(14.57545299rem, max-content) minmax(1.10580686rem, max-content);
  }

  #MSWnB8FTDmgj24i4 {
    grid-area: 4 / 3 / 6 / 7;
    position: relative;
  }

  #SxsrVJ1tSwpYzFEU {
    grid-area: 7 / 4 / 10 / 6;
    position: relative;
  }

  #XMtUYKCOD1OyTybP {
    grid-area: 8 / 10 / 11 / 11;
    position: relative;
  }

  #vYOYARE87xLSgJRl {
    grid-area: 9 / 2 / 13 / 13;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: -3.33333333rem;
    right: -3.33333333rem;
    clip-path: polygon(0% calc((0 * 93.33333333rem) + 0%), 100% calc((0 * 93.33333333rem) + 0%), 100% calc(100% - ((0.1115322 * 93.33333333rem) + 0%)), 0% calc(100% - ((0.1115322 * 93.33333333rem) + 0%)));
    margin-left: -3.48790157%;
    margin-right: -1.88860708%;
    margin-bottom: -11.15321955%;
    margin-top: 0%;
  }

  #dLX3STwT8vA1TRta {
    grid-template-columns: 3.33333333rem 6.76649483rem 3.47607465rem 8.58534841rem 6.29836371rem 5.76305935rem 39.82016201rem 0 1.09869834rem 12.81392318rem 3.79432874rem 4.91688011rem 3.33333333rem;
    grid-template-rows: minmax(0.77352348rem, max-content) minmax(5.08822152rem, max-content) minmax(0.00792401rem, max-content) minmax(7.96020361rem, max-content) minmax(9.19717069rem, max-content) minmax(3.85042234rem, max-content) minmax(6.99454846rem, max-content) minmax(5.92753223rem, max-content) minmax(7.05605638rem, max-content) minmax(5.28938563rem, max-content) minmax(0.83641948rem, max-content) minmax(0.01280487rem, max-content);
  }

  #LepCE1qH99a70ORi {
    min-height: calc(37.77493869 * var(--1vh, 1vh));
  }

  #kTSajshTOj0Nxj8x {
    grid-area: 2 / 2 / 4 / 4;
    position: relative;
  }

  #Nev0cJY7mPKEfdC7 {
    grid-area: 3 / 3 / 5 / 5;
    position: relative;
    clip-path: polygon(calc(6.64745701% + 0%) calc((0.02424923 * 155.26711691rem) + 0%), calc(100% - (1.53912369% + 0%)) calc((0.02424923 * 155.26711691rem) + 0%), calc(100% - (1.53912369% + 0%)) calc(100% - ((0.03482805 * 155.26711691rem) + 0%)), calc(6.64745701% + 0%) calc(100% - ((0.03482805 * 155.26711691rem) + 0%)));
    margin-left: -7.2401802%;
    margin-right: -1.67636028%;
    margin-bottom: -3.48280525%;
    margin-top: -2.42492253%;
  }

  #aKI0wS7OtSyHdMdl {
    grid-template-columns: 0 0 155.26711691% 0;
    left: -27.63355845%;
    grid-template-rows: 0 0 100% 0;
  }

  #rnplIgVxKJsEqApo {
    grid-area: 2 / 2 / 4 / 4;
    position: relative;
  }

  #Hbmqhk4G9RETOeMQ {
    grid-area: 2 / 2 / 13 / 13;
    position: relative;
  }

  #UHx4Zugbf9XHa3lP {
    font-size: calc(4.08590265em - var(--ffsd));
  }

  #T5dq7bMNN2RFcINL {
    --first-font-size: 4.08590265em;
    --last-font-size: var(--first-font-size);
    transform: translateX(calc(var(--first-font-size) * -0.016));
    margin-top: calc(var(--first-font-size) * 0.35);
    margin-bottom: calc(var(--last-font-size) * 0.35);
  }

  #kIJyDrwfAAzBqpU7 {
    min-width: 31.10135037rem;
  }

  #CwdWQBLO94jyEhUJ {
    grid-area: 3 / 7 / 4 / 12;
    position: relative;
  }

  #Eg0viCLNOGCzvH0z {
    font-size: calc(4.08590265em - var(--ffsd));
  }

  #G56aSqbHlQcyhFbc {
    --first-font-size: 4.08590265em;
    --last-font-size: var(--first-font-size);
    transform: translateX(calc(var(--first-font-size) * -0.016));
    margin-top: calc(var(--first-font-size) * 0.35);
    margin-bottom: calc(var(--last-font-size) * 0.35);
  }

  #iPcYmMsD3tlsIBG9 {
    min-width: 15.98281953rem;
  }

  #Vx9NPAGIerWsKCCO {
    grid-area: 5 / 6 / 6 / 8;
    position: relative;
  }

  #btvB0DXn4Iny0c8H {
    font-size: calc(4.08590265em - var(--ffsd));
  }

  #YqGUao9bBR8JoIca {
    --first-font-size: 4.08590265em;
    --last-font-size: var(--first-font-size);
    transform: translateX(calc(var(--first-font-size) * -0.016));
    margin-top: calc(var(--first-font-size) * 0.35);
    margin-bottom: calc(var(--last-font-size) * 0.35);
  }

  #J0mWfXUDNyVOSqP9 {
    min-width: 18.29444682rem;
  }

  #JiU5wzn8HfKfJeYx {
    grid-area: 7 / 3 / 8 / 9;
    position: relative;
  }

  #kQ2nZow0ri6YnKWs {
    font-size: calc(4.08590265em - var(--ffsd));
  }

  #JjNSja5veWoxbGUr {
    --first-font-size: 4.08590265em;
    --last-font-size: var(--first-font-size);
    transform: translateX(calc(var(--first-font-size) * -0.016));
    margin-top: calc(var(--first-font-size) * 0.35);
    margin-bottom: calc(var(--last-font-size) * 0.35);
  }

  #H80PzYxC8GlULTvo {
    min-width: 26.31686099rem;
  }

  #XC49D7rFAG9bMpiB {
    grid-area: 9 / 4 / 10 / 11;
    position: relative;
  }

  #lL5bi5dsw434D1fe {
    font-size: calc(4.08590265em - var(--ffsd));
  }

  #w0XS94Nsi1cu2I3g {
    --first-font-size: 4.08590265em;
    --last-font-size: var(--first-font-size);
    transform: translateX(calc(var(--first-font-size) * -0.016));
    margin-top: calc(var(--first-font-size) * 0.35);
    margin-bottom: calc(var(--last-font-size) * 0.35);
  }

  #Y9cko4qoeleSE6fV {
    min-width: 25.17667796rem;
  }

  #eZCVnmBqFzYkFZhR {
    grid-area: 11 / 5 / 12 / 10;
    position: relative;
  }

  #csTTVYjNsZmkEQyf {
    grid-template-columns: 0 6.24412214rem 0 0 0.00164609rem 0.07403786rem 15.804615rem 2.30998121rem 6.88223113rem 1.14018303rem 4.86017333rem 38.08040399rem;
    grid-template-rows: 0 minmax(6.21937978rem, max-content) minmax(4.83447477rem, max-content) minmax(0.78973716rem, max-content) minmax(4.83447477rem, max-content) minmax(0.78973716rem, max-content) minmax(4.83447477rem, max-content) minmax(0.77622928rem, max-content) minmax(4.83447477rem, max-content) minmax(0.77622928rem, max-content) minmax(4.83447477rem, max-content) minmax(4.55199734rem, max-content);
  }

  #y77AlqzCid4FXdOu {
    grid-area: 3 / 3 / 5 / 5;
    position: relative;
  }

  #plvX7Vf2FrLKtHzJ {
    grid-template-columns: 3.33333333rem 17.93593956rem 5.07184175rem 70.32555203rem 3.33333333rem;
    grid-template-rows: minmax(20.43699927rem, max-content) minmax(8.34544629rem, max-content) minmax(28.68921175rem, max-content) minmax(9.38647211rem, max-content) minmax(20.43699927rem, max-content);
  }

  #VdeILkon1psweVJf {
    min-height: calc(62.22506131 * var(--1vh, 1vh));
  }
}

@media (min-width: 480.05px) and (max-width: 768px) {
  #OqsN2dWNJz0dIZvP {
    grid-area: 2 / 2 / 4 / 4;
    position: relative;
  }

  #wKMkfaDmuqZ7EL4H {
    grid-area: 3 / 3 / 5 / 5;
    position: relative;
    clip-path: polygon(calc(5.81069312% + 0%) calc((0.04713676 * 100rem) + 0%), calc(100% - (0.59787622% + 0%)) calc((0.04713676 * 100rem) + 0%), calc(100% - (0.59787622% + 0%)) calc(100% - ((0.00013726 * 100rem) + 0%)), calc(5.81069312% + 0%) calc(100% - ((0.00013726 * 100rem) + 0%)));
    margin-left: -6.20857388%;
    margin-right: -0.63881513%;
    margin-bottom: -0.01372621%;
    margin-top: -4.71367625%;
  }

  #oyFLJsABht87qRYA {
    grid-template-columns: 0 0 100% 0;
    grid-template-rows: 0 0 100% 0;
  }

  #znDzx9uq82zRePeS {
    grid-area: 2 / 2 / 11 / 11;
    position: relative;
  }

  #Y5LcI1eqCsmC32CV {
    grid-area: 2 / 2 / 29 / 29;
    position: relative;
  }

  #oBThJfU6tD300DQE {
    stroke-width: calc(100rem * 0.0 / 768.0);
  }

  #ZEoizaRHe7bZECcT {
    grid-area: 3 / 5 / 7 / 8;
    position: relative;
  }

  #zZsJapxwbcbPSxWJ {
    font-size: calc(1.18106393em - var(--ffsd));
  }

  #KEne4nohIFkh8FBf {
    --first-font-size: 1.18106393em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #zwX8vilUbYQ08nZL {
    min-width: 4.88252455rem;
  }

  #ZlD5SxsxfsZ2Mvy4 {
    grid-area: 8 / 3 / 10 / 7;
    position: relative;
  }

  #GUakv73886zOAUPq {
    grid-area: 11 / 4 / 15 / 9;
    position: relative;
  }

  #sAG6HMCqxXBPKKLc {
    grid-area: 19 / 6 / 26 / 10;
    position: relative;
  }

  #jnrVRUU53B2fCAHP {
    grid-area: 13 / 11 / 20 / 14;
    position: relative;
  }

  #jb9HpuJVsfxE6N9W {
    font-size: calc(1.18864979em - var(--ffsd));
  }

  #RAUOMvJvxwWTexs1 {
    --first-font-size: 1.18864979em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #PZrXwhL4nQx6TjAN {
    min-width: 4.29856582rem;
  }

  #hEnnesjQvbnuNdJz {
    grid-area: 21 / 12 / 24 / 13;
    position: relative;
  }

  #bV8ZVBpbkUz4uAZl {
    grid-area: 14 / 15 / 22 / 17;
    position: relative;
  }

  #nZhBlEiXWPiUxJo0 {
    font-size: calc(1.18087675em - var(--ffsd));
  }

  #EjcIhZMpf9iCmVjd {
    --first-font-size: 1.18087675em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #vKgPANXP9AhoG2CJ {
    min-width: 4.94629059rem;
  }

  #FPnmhk4ZGR4mC228 {
    grid-area: 23 / 16 / 25 / 18;
    position: relative;
  }

  #lbq8K7o6oawkjLTP {
    grid-area: 4 / 22 / 5 / 26;
    position: relative;
  }

  #DN6SSQPqn4mnxENP {
    font-size: calc(1.18106393em - var(--ffsd));
  }

  #neXnyDARM09GG5vH {
    --first-font-size: 1.18106393em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #yVRfpCdZ2PLqM9AL {
    min-width: 4.54955368rem;
  }

  #D1ZqOerd2c4MvUky {
    grid-area: 6 / 23 / 9 / 27;
    position: relative;
  }

  #eFXDw6lelKuAPXbY {
    grid-area: 12 / 19 / 16 / 24;
    position: relative;
  }

  #FOAZ2finbqk9NlMs {
    font-size: calc(1.23821755em - var(--ffsd));
  }

  #q6a32FiEUQa2AFUS {
    --first-font-size: 1.23821755em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #TmNIgOXPK21btcd8 {
    min-width: 5.18331037rem;
  }

  #krJ1PeZrtS7reFNz {
    grid-area: 17 / 20 / 18 / 25;
    position: relative;
  }

  #Lx3eASFtfM01wzTq {
    grid-area: 2 / 3 / 3 / 4;
    position: relative;
  }

  #eUISANMG3dWcg8cF {
    font-size: calc(1.18106393em - var(--ffsd));
  }

  #JSev41LwJbTR3dwc {
    --first-font-size: 1.18106393em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #uLtG7tsBVHcB8tQr {
    min-width: 6.43154276rem;
  }

  #GqviADSBMeR7y5af {
    grid-area: 4 / 2 / 5 / 5;
    position: relative;
  }

  #T43u1e6hvdOPtjQh {
    grid-template-columns: 0 1.02971986rem 4.30699888rem 1.02971986rem;
    grid-template-rows: 0 minmax(4.39138766rem, max-content) minmax(0.16209422rem, max-content) minmax(1.37432894rem, max-content);
  }

  #kn97wPNLMBp5ebrQ {
    grid-template-columns: 0 1.03768666rem 0 0.02488069rem 0 4.7925397rem 0.2832095rem 0 0.02488069rem 2.61492007rem 1.04871608rem 4.23346165rem 0.33992742rem 4.75817443rem 0.70415362rem 4.88118643rem 0 4.14473869rem 0.73834578rem 4.08848635rem 0.1738323rem 0 0.85588755rem 0 3.62856196rem 0 1.70815678rem 0.60665459rem;
    grid-template-rows: 0 minmax(1.25467319rem, max-content) minmax(0.29564984rem, max-content) minmax(4.48444951rem, max-content) 0 minmax(0.29564984rem, max-content) minmax(0.39151463rem, max-content) minmax(0.68716447rem, max-content) minmax(0.68716447rem, max-content) minmax(0.08309657rem, max-content) minmax(0.34670155rem, max-content) minmax(1.94287575rem, max-content) minmax(2.55031494rem, max-content) minmax(0.26073764rem, max-content) minmax(1.10262366rem, max-content) 0 minmax(1.48288401rem, max-content) minmax(0.21024841rem, max-content) minmax(0.0152965rem, max-content) 0 minmax(2.51354983rem, max-content) 0 minmax(0.57154044rem, max-content) minmax(0.84267247rem, max-content) minmax(1.15757064rem, max-content) minmax(1.31126301rem, max-content) minmax(5.92781083rem, max-content) minmax(1.06423265rem, max-content);
  }

  #GoGORTLYxzrR7X6X {
    grid-area: 4 / 7 / 10 / 8;
    position: relative;
  }

  #P2XxREr5GFW3GJqp {
    grid-area: 5 / 4 / 8 / 6;
    position: relative;
  }

  #wBjmx07a0TUzHNgz {
    grid-area: 6 / 5 / 9 / 10;
    position: relative;
  }

  #pkXUoFRqAeXqm8WY {
    grid-template-columns: 0 5.14820627rem 0 2.65364306rem 1.63038086rem 0.74397262rem 4.27159604rem 31.38880837rem 0 5.11852099rem;
    grid-template-rows: 0 minmax(4.90457516rem, max-content) minmax(24.06193229rem, max-content) minmax(4.27394662rem, max-content) minmax(0.0795733rem, max-content) minmax(1.06423265rem, max-content) minmax(0.96953846rem, max-content) minmax(0.09469418rem, max-content) minmax(0.5998922rem, max-content) minmax(15.22721584rem, max-content);
  }

  #JTgx7u7Qa1RKLRFN {
    grid-area: 2 / 5 / 12 / 8;
    position: relative;
  }

  #dhV3p3fCQDn5y7oJ {
    grid-area: 3 / 9 / 5 / 12;
    position: relative;
  }

  #oFANS7V1QSuAiKdj {
    grid-area: 2 / 2 / 5 / 5;
    position: relative;
  }

  #b4adkyrn7x5PE2Nh {
    grid-area: 3 / 3 / 4 / 4;
    position: relative;
  }

  #p5vErsmSRsjWjNFP {
    grid-template-columns: 0 1.60350003rem 14.31517704rem 7.77340393rem;
    grid-template-rows: 0 minmax(1.44975526rem, max-content) minmax(14.31517704rem, max-content) minmax(1.08606031rem, max-content);
  }

  #MSWnB8FTDmgj24i4 {
    grid-area: 4 / 3 / 6 / 7;
    position: relative;
  }

  #SxsrVJ1tSwpYzFEU {
    grid-area: 7 / 4 / 10 / 6;
    position: relative;
  }

  #XMtUYKCOD1OyTybP {
    grid-area: 8 / 10 / 11 / 11;
    position: relative;
  }

  #vYOYARE87xLSgJRl {
    grid-area: 9 / 2 / 13 / 13;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: -4.16666667rem;
    right: -4.16666667rem;
    clip-path: polygon(0% calc((0 * 91.66666667rem) + 0%), 100% calc((0 * 91.66666667rem) + 0%), 100% calc(100% - ((0.1115322 * 91.66666667rem) + 0%)), 0% calc(100% - ((0.1115322 * 91.66666667rem) + 0%)));
    margin-left: -3.48790157%;
    margin-right: -1.88860708%;
    margin-bottom: -11.15321955%;
    margin-top: 0%;
  }

  #dLX3STwT8vA1TRta {
    grid-template-columns: 4.16666667rem 6.64566457rem 3.41400188rem 8.43203862rem 6.18589292rem 5.66014758rem 39.10908769rem 0 1.07907873rem 12.58510313rem 3.72657287rem 4.82907868rem 4.16666667rem;
    grid-template-rows: minmax(0.48345218rem, max-content) minmax(4.99736042rem, max-content) minmax(0.00778251rem, max-content) minmax(7.81805712rem, max-content) minmax(9.0329355rem, max-content) minmax(3.7816648rem, max-content) minmax(6.86964581rem, max-content) minmax(5.82168344rem, max-content) minmax(6.93005538rem, max-content) minmax(5.19493231rem, max-content) minmax(0.82148342rem, max-content) minmax(0.01257622rem, max-content);
  }

  #LepCE1qH99a70ORi {
    min-height: calc(41.41949257 * var(--1vh, 1vh));
  }

  #kTSajshTOj0Nxj8x {
    grid-area: 2 / 2 / 4 / 4;
    position: relative;
  }

  #Nev0cJY7mPKEfdC7 {
    grid-area: 3 / 3 / 5 / 5;
    position: relative;
    clip-path: polygon(calc(6.64745701% + 0%) calc((0.02424923 * 130.23558512rem) + 0%), calc(100% - (1.53912369% + 0%)) calc((0.02424923 * 130.23558512rem) + 0%), calc(100% - (1.53912369% + 0%)) calc(100% - ((0.03482805 * 130.23558512rem) + 0%)), calc(6.64745701% + 0%) calc(100% - ((0.03482805 * 130.23558512rem) + 0%)));
    margin-left: -7.2401802%;
    margin-right: -1.67636028%;
    margin-bottom: -3.48280525%;
    margin-top: -2.42492253%;
  }

  #aKI0wS7OtSyHdMdl {
    grid-template-columns: 0 0 130.23558512% 0;
    left: -15.11779256%;
    grid-template-rows: 0 0 100% 0;
  }

  #rnplIgVxKJsEqApo {
    grid-area: 2 / 2 / 4 / 4;
    position: relative;
  }

  #Hbmqhk4G9RETOeMQ {
    grid-area: 2 / 2 / 13 / 13;
    position: relative;
  }

  #UHx4Zugbf9XHa3lP {
    font-size: calc(4.01294011em - var(--ffsd));
  }

  #T5dq7bMNN2RFcINL {
    --first-font-size: 4.01294011em;
    --last-font-size: var(--first-font-size);
    transform: translateX(calc(var(--first-font-size) * -0.016));
    margin-top: calc(var(--first-font-size) * 0.35);
    margin-bottom: calc(var(--last-font-size) * 0.35);
  }

  #kIJyDrwfAAzBqpU7 {
    min-width: 30.50876673rem;
  }

  #CwdWQBLO94jyEhUJ {
    grid-area: 3 / 7 / 4 / 12;
    position: relative;
  }

  #Eg0viCLNOGCzvH0z {
    font-size: calc(4.01294011em - var(--ffsd));
  }

  #G56aSqbHlQcyhFbc {
    --first-font-size: 4.01294011em;
    --last-font-size: var(--first-font-size);
    transform: translateX(calc(var(--first-font-size) * -0.016));
    margin-top: calc(var(--first-font-size) * 0.35);
    margin-bottom: calc(var(--last-font-size) * 0.35);
  }

  #iPcYmMsD3tlsIBG9 {
    min-width: 15.66020966rem;
  }

  #Vx9NPAGIerWsKCCO {
    grid-area: 5 / 6 / 6 / 8;
    position: relative;
  }

  #btvB0DXn4Iny0c8H {
    font-size: calc(4.01294011em - var(--ffsd));
  }

  #YqGUao9bBR8JoIca {
    --first-font-size: 4.01294011em;
    --last-font-size: var(--first-font-size);
    transform: translateX(calc(var(--first-font-size) * -0.016));
    margin-top: calc(var(--first-font-size) * 0.35);
    margin-bottom: calc(var(--last-font-size) * 0.35);
  }

  #J0mWfXUDNyVOSqP9 {
    min-width: 17.93055789rem;
  }

  #JiU5wzn8HfKfJeYx {
    grid-area: 7 / 3 / 8 / 9;
    position: relative;
  }

  #kQ2nZow0ri6YnKWs {
    font-size: calc(4.01294011em - var(--ffsd));
  }

  #JjNSja5veWoxbGUr {
    --first-font-size: 4.01294011em;
    --last-font-size: var(--first-font-size);
    transform: translateX(calc(var(--first-font-size) * -0.016));
    margin-top: calc(var(--first-font-size) * 0.35);
    margin-bottom: calc(var(--last-font-size) * 0.35);
  }

  #H80PzYxC8GlULTvo {
    min-width: 25.80971466rem;
  }

  #XC49D7rFAG9bMpiB {
    grid-area: 9 / 4 / 10 / 11;
    position: relative;
  }

  #lL5bi5dsw434D1fe {
    font-size: calc(4.01294011em - var(--ffsd));
  }

  #w0XS94Nsi1cu2I3g {
    --first-font-size: 4.01294011em;
    --last-font-size: var(--first-font-size);
    transform: translateX(calc(var(--first-font-size) * -0.016));
    margin-top: calc(var(--first-font-size) * 0.35);
    margin-bottom: calc(var(--last-font-size) * 0.35);
  }

  #Y9cko4qoeleSE6fV {
    min-width: 24.68989204rem;
  }

  #eZCVnmBqFzYkFZhR {
    grid-area: 11 / 5 / 12 / 10;
    position: relative;
  }

  #csTTVYjNsZmkEQyf {
    grid-template-columns: 0 6.13261996rem 0 0 0.00161669rem 0.07271575rem 15.52238973rem 2.26873154rem 6.75933415rem 1.11982262rem 4.77338452rem 37.40039677rem;
    grid-template-rows: 0 minmax(6.10831943rem, max-content) minmax(4.74814486rem, max-content) minmax(0.77563472rem, max-content) minmax(4.74814486rem, max-content) minmax(0.77563472rem, max-content) minmax(4.74814486rem, max-content) minmax(0.76236804rem, max-content) minmax(4.74814486rem, max-content) minmax(0.76236804rem, max-content) minmax(4.74814486rem, max-content) minmax(4.47071167rem, max-content);
  }

  #y77AlqzCid4FXdOu {
    grid-area: 3 / 3 / 5 / 5;
    position: relative;
  }

  #plvX7Vf2FrLKtHzJ {
    grid-template-columns: 4.16666667rem 17.61565492rem 4.98127315rem 69.0697386rem 4.16666667rem;
    grid-template-rows: minmax(13.81479121rem, max-content) minmax(8.19642047rem, max-content) minmax(28.17690439rem, max-content) minmax(9.21885654rem, max-content) minmax(13.81479121rem, max-content);
  }

  #VdeILkon1psweVJf {
    min-height: calc(58.58050743 * var(--1vh, 1vh));
  }
}

@media (min-width: 768.05px) and (max-width: 1024px) {
  #OqsN2dWNJz0dIZvP {
    grid-area: 2 / 2 / 4 / 4;
    position: relative;
  }

  #wKMkfaDmuqZ7EL4H {
    grid-area: 3 / 3 / 5 / 5;
    position: relative;
    clip-path: polygon(calc(5.81069312% + 0%) calc((0.04713676 * 100rem) + 0%), calc(100% - (0.59787622% + 0%)) calc((0.04713676 * 100rem) + 0%), calc(100% - (0.59787622% + 0%)) calc(100% - ((0.00013726 * 100rem) + 0%)), calc(5.81069312% + 0%) calc(100% - ((0.00013726 * 100rem) + 0%)));
    margin-left: -6.20857388%;
    margin-right: -0.63881513%;
    margin-bottom: -0.01372621%;
    margin-top: -4.71367625%;
  }

  #oyFLJsABht87qRYA {
    grid-template-columns: 0 0 100% 0;
    grid-template-rows: 0 0 100% 0;
  }

  #znDzx9uq82zRePeS {
    grid-area: 2 / 2 / 11 / 11;
    position: relative;
  }

  #Y5LcI1eqCsmC32CV {
    grid-area: 2 / 2 / 29 / 29;
    position: relative;
  }

  #oBThJfU6tD300DQE {
    stroke-width: calc(100rem * 0.0 / 1024.0);
  }

  #ZEoizaRHe7bZECcT {
    grid-area: 3 / 5 / 7 / 8;
    position: relative;
  }

  #zZsJapxwbcbPSxWJ {
    font-size: calc(1.2079063em - var(--ffsd));
  }

  #KEne4nohIFkh8FBf {
    --first-font-size: 1.2079063em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #zwX8vilUbYQ08nZL {
    min-width: 4.97573534rem;
  }

  #ZlD5SxsxfsZ2Mvy4 {
    grid-area: 8 / 3 / 10 / 7;
    position: relative;
  }

  #GUakv73886zOAUPq {
    grid-area: 11 / 4 / 15 / 9;
    position: relative;
  }

  #sAG6HMCqxXBPKKLc {
    grid-area: 19 / 6 / 26 / 10;
    position: relative;
  }

  #jnrVRUU53B2fCAHP {
    grid-area: 13 / 11 / 20 / 14;
    position: relative;
  }

  #jb9HpuJVsfxE6N9W {
    font-size: calc(1.21566456em - var(--ffsd));
  }

  #RAUOMvJvxwWTexs1 {
    --first-font-size: 1.21566456em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #PZrXwhL4nQx6TjAN {
    min-width: 4.37850482rem;
  }

  #hEnnesjQvbnuNdJz {
    grid-area: 21 / 12 / 24 / 13;
    position: relative;
  }

  #bV8ZVBpbkUz4uAZl {
    grid-area: 14 / 15 / 22 / 17;
    position: relative;
  }

  #nZhBlEiXWPiUxJo0 {
    font-size: calc(1.20771486em - var(--ffsd));
  }

  #EjcIhZMpf9iCmVjd {
    --first-font-size: 1.20771486em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #vKgPANXP9AhoG2CJ {
    min-width: 5.04095061rem;
  }

  #FPnmhk4ZGR4mC228 {
    grid-area: 23 / 16 / 25 / 18;
    position: relative;
  }

  #lbq8K7o6oawkjLTP {
    grid-area: 4 / 22 / 5 / 26;
    position: relative;
  }

  #DN6SSQPqn4mnxENP {
    font-size: calc(1.2079063em - var(--ffsd));
  }

  #neXnyDARM09GG5vH {
    --first-font-size: 1.2079063em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #yVRfpCdZ2PLqM9AL {
    min-width: 4.63519694rem;
  }

  #D1ZqOerd2c4MvUky {
    grid-area: 6 / 23 / 9 / 27;
    position: relative;
  }

  #eFXDw6lelKuAPXbY {
    grid-area: 12 / 19 / 16 / 24;
    position: relative;
  }

  #FOAZ2finbqk9NlMs {
    font-size: calc(1.26635886em - var(--ffsd));
  }

  #q6a32FiEUQa2AFUS {
    --first-font-size: 1.26635886em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #TmNIgOXPK21btcd8 {
    min-width: 5.2833572rem;
  }

  #krJ1PeZrtS7reFNz {
    grid-area: 17 / 20 / 18 / 25;
    position: relative;
  }

  #Lx3eASFtfM01wzTq {
    grid-area: 2 / 3 / 3 / 4;
    position: relative;
  }

  #eUISANMG3dWcg8cF {
    font-size: calc(1.2079063em - var(--ffsd));
  }

  #JSev41LwJbTR3dwc {
    --first-font-size: 1.2079063em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #uLtG7tsBVHcB8tQr {
    min-width: 6.55995851rem;
  }

  #GqviADSBMeR7y5af {
    grid-area: 4 / 2 / 5 / 5;
    position: relative;
  }

  #T43u1e6hvdOPtjQh {
    grid-template-columns: 0 1.05312258rem 4.40488522rem 1.05312258rem;
    grid-template-rows: 0 minmax(4.49119193rem, max-content) minmax(0.16577818rem, max-content) minmax(1.40556369rem, max-content);
  }

  #kn97wPNLMBp5ebrQ {
    grid-template-columns: 0 1.06127044rem 0 0.02544616rem 0 4.90146105rem 0.28964608rem 0 0.02544616rem 2.67435008rem 1.07255054rem 4.32967669rem 0.34765305rem 4.86631476rem 0.72015711rem 4.99212248rem 0 4.23893729rem 0.75512637rem 4.1814065rem 0.17778304rem 0 0.87533954rem 0 3.71102927rem 0 1.74697853rem 0.6204422rem;
    grid-template-rows: 0 minmax(1.28318849rem, max-content) minmax(0.30236916rem, max-content) minmax(4.58636882rem, max-content) 0 minmax(0.30236916rem, max-content) minmax(0.40041269rem, max-content) minmax(0.70278184rem, max-content) minmax(0.70278184rem, max-content) minmax(0.08498513rem, max-content) minmax(0.35458114rem, max-content) minmax(1.98703202rem, max-content) minmax(2.60827664rem, max-content) minmax(0.26666349rem, max-content) minmax(1.12768329rem, max-content) 0 minmax(1.51658592rem, max-content) minmax(0.21502678rem, max-content) minmax(0.01564415rem, max-content) 0 minmax(2.57067596rem, max-content) 0 minmax(0.58453rem, max-content) minmax(0.86182411rem, max-content) minmax(1.18387907rem, max-content) minmax(1.34106445rem, max-content) minmax(6.0625338rem, max-content) minmax(1.08841975rem, max-content);
  }

  #GoGORTLYxzrR7X6X {
    grid-area: 4 / 7 / 10 / 8;
    position: relative;
  }

  #P2XxREr5GFW3GJqp {
    grid-area: 5 / 4 / 8 / 6;
    position: relative;
  }

  #wBjmx07a0TUzHNgz {
    grid-area: 6 / 5 / 9 / 10;
    position: relative;
  }

  #pkXUoFRqAeXqm8WY {
    grid-template-columns: 0 5.26521096rem 0 2.71395313rem 1.66743497rem 0.76088109rem 4.36867777rem 32.10219037rem 0 5.23485101rem;
    grid-template-rows: 0 minmax(5.01604278rem, max-content) minmax(24.60879439rem, max-content) minmax(4.37108177rem, max-content) minmax(0.08138178rem, max-content) minmax(1.08841975rem, max-content) minmax(0.99157343rem, max-content) minmax(0.09684632rem, max-content) minmax(0.61352612rem, max-content) minmax(15.57328892rem, max-content);
  }

  #JTgx7u7Qa1RKLRFN {
    grid-area: 2 / 5 / 12 / 8;
    position: relative;
  }

  #dhV3p3fCQDn5y7oJ {
    grid-area: 3 / 9 / 5 / 12;
    position: relative;
  }

  #oFANS7V1QSuAiKdj {
    grid-area: 2 / 2 / 5 / 5;
    position: relative;
  }

  #b4adkyrn7x5PE2Nh {
    grid-area: 3 / 3 / 4 / 4;
    position: relative;
  }

  #p5vErsmSRsjWjNFP {
    grid-template-columns: 0 1.63994321rem 14.64052198rem 7.9500722rem;
    grid-template-rows: 0 minmax(1.48270424rem, max-content) minmax(14.64052198rem, max-content) minmax(1.1107435rem, max-content);
  }

  #MSWnB8FTDmgj24i4 {
    grid-area: 4 / 3 / 6 / 7;
    position: relative;
  }

  #SxsrVJ1tSwpYzFEU {
    grid-area: 7 / 4 / 10 / 6;
    position: relative;
  }

  #XMtUYKCOD1OyTybP {
    grid-area: 8 / 10 / 11 / 11;
    position: relative;
  }

  #vYOYARE87xLSgJRl {
    grid-area: 9 / 2 / 13 / 13;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: -3.125rem;
    right: -3.125rem;
    clip-path: polygon(0% calc((0 * 93.75rem) + 0%), 100% calc((0 * 93.75rem) + 0%), 100% calc(100% - ((0.1115322 * 93.75rem) + 0%)), 0% calc(100% - ((0.1115322 * 93.75rem) + 0%)));
    margin-left: -3.48790157%;
    margin-right: -1.88860708%;
    margin-bottom: -11.15321955%;
    margin-top: 0%;
  }

  #dLX3STwT8vA1TRta {
    grid-template-columns: 3.125rem 6.7967024rem 3.49159284rem 8.62367586rem 6.3264814rem 5.7887873rem 39.99793059rem 0 1.10360325rem 12.8711282rem 3.8112677rem 4.93883046rem 3.125rem;
    grid-template-rows: minmax(0.36258913rem, max-content) minmax(5.1109368rem, max-content) minmax(0.00795938rem, max-content) minmax(7.99574023rem, max-content) minmax(9.23822949rem, max-content) minmax(3.86761173rem, max-content) minmax(7.02577412rem, max-content) minmax(5.95399443rem, max-content) minmax(7.08755663rem, max-content) minmax(5.31299895rem, max-content) minmax(0.84015349rem, max-content) minmax(0.01286204rem, max-content);
  }

  #LepCE1qH99a70ORi {
    min-height: calc(43.95251921 * var(--1vh, 1vh));
  }

  #kTSajshTOj0Nxj8x {
    grid-area: 2 / 2 / 4 / 4;
    position: relative;
  }

  #Nev0cJY7mPKEfdC7 {
    grid-area: 3 / 3 / 5 / 5;
    position: relative;
    clip-path: polygon(calc(6.64745701% + 0%) calc((0.02424923 * 119.79278252rem) + 0%), calc(100% - (1.53912369% + 0%)) calc((0.02424923 * 119.79278252rem) + 0%), calc(100% - (1.53912369% + 0%)) calc(100% - ((0.03482805 * 119.79278252rem) + 0%)), calc(6.64745701% + 0%) calc(100% - ((0.03482805 * 119.79278252rem) + 0%)));
    margin-left: -7.2401802%;
    margin-right: -1.67636028%;
    margin-bottom: -3.48280525%;
    margin-top: -2.42492253%;
  }

  #aKI0wS7OtSyHdMdl {
    grid-template-columns: 0 0 119.79278252% 0;
    left: -9.89639126%;
    grid-template-rows: 0 0 100% 0;
  }

  #rnplIgVxKJsEqApo {
    grid-area: 2 / 2 / 4 / 4;
    position: relative;
  }

  #Hbmqhk4G9RETOeMQ {
    grid-area: 2 / 2 / 13 / 13;
    position: relative;
  }

  #UHx4Zugbf9XHa3lP {
    font-size: calc(4.10414329em - var(--ffsd));
  }

  #T5dq7bMNN2RFcINL {
    --first-font-size: 4.10414329em;
    --last-font-size: var(--first-font-size);
    transform: translateX(calc(var(--first-font-size) * -0.016));
    margin-top: calc(var(--first-font-size) * 0.35);
    margin-bottom: calc(var(--last-font-size) * 0.35);
  }

  #kIJyDrwfAAzBqpU7 {
    min-width: 31.18439211rem;
  }

  #CwdWQBLO94jyEhUJ {
    grid-area: 3 / 7 / 4 / 12;
    position: relative;
  }

  #Eg0viCLNOGCzvH0z {
    font-size: calc(4.10414329em - var(--ffsd));
  }

  #G56aSqbHlQcyhFbc {
    --first-font-size: 4.10414329em;
    --last-font-size: var(--first-font-size);
    transform: translateX(calc(var(--first-font-size) * -0.016));
    margin-top: calc(var(--first-font-size) * 0.35);
    margin-bottom: calc(var(--last-font-size) * 0.35);
  }

  #iPcYmMsD3tlsIBG9 {
    min-width: 15.99836783rem;
  }

  #Vx9NPAGIerWsKCCO {
    grid-area: 5 / 6 / 6 / 8;
    position: relative;
  }

  #btvB0DXn4Iny0c8H {
    font-size: calc(4.10414329em - var(--ffsd));
  }

  #YqGUao9bBR8JoIca {
    --first-font-size: 4.10414329em;
    --last-font-size: var(--first-font-size);
    transform: translateX(calc(var(--first-font-size) * -0.016));
    margin-top: calc(var(--first-font-size) * 0.35);
    margin-bottom: calc(var(--last-font-size) * 0.35);
  }

  #J0mWfXUDNyVOSqP9 {
    min-width: 18.32031489rem;
  }

  #JiU5wzn8HfKfJeYx {
    grid-area: 7 / 3 / 8 / 9;
    position: relative;
  }

  #kQ2nZow0ri6YnKWs {
    font-size: calc(4.10414329em - var(--ffsd));
  }

  #JjNSja5veWoxbGUr {
    --first-font-size: 4.10414329em;
    --last-font-size: var(--first-font-size);
    transform: translateX(calc(var(--first-font-size) * -0.016));
    margin-top: calc(var(--first-font-size) * 0.35);
    margin-bottom: calc(var(--last-font-size) * 0.35);
  }

  #H80PzYxC8GlULTvo {
    min-width: 26.3785434rem;
  }

  #XC49D7rFAG9bMpiB {
    grid-area: 9 / 4 / 10 / 11;
    position: relative;
  }

  #lL5bi5dsw434D1fe {
    font-size: calc(4.10414329em - var(--ffsd));
  }

  #w0XS94Nsi1cu2I3g {
    --first-font-size: 4.10414329em;
    --last-font-size: var(--first-font-size);
    transform: translateX(calc(var(--first-font-size) * -0.016));
    margin-top: calc(var(--first-font-size) * 0.35);
    margin-bottom: calc(var(--last-font-size) * 0.35);
  }

  #Y9cko4qoeleSE6fV {
    min-width: 25.23327027rem;
  }

  #eZCVnmBqFzYkFZhR {
    grid-area: 11 / 5 / 12 / 10;
    position: relative;
  }

  #csTTVYjNsZmkEQyf {
    grid-template-columns: 0 6.27199768rem 0 0 0.00165344rem 0.07436839rem 15.87517132rem 2.32029362rem 6.91295538rem 1.14527313rem 4.88187053rem 38.25040579rem;
    grid-template-rows: 0 minmax(6.24714487rem, max-content) minmax(4.85605725rem, max-content) minmax(0.79326278rem, max-content) minmax(4.85605725rem, max-content) minmax(0.79326278rem, max-content) minmax(4.85605725rem, max-content) minmax(0.77969459rem, max-content) minmax(4.85605725rem, max-content) minmax(0.77969459rem, max-content) minmax(4.85605725rem, max-content) minmax(4.57231876rem, max-content);
  }

  #y77AlqzCid4FXdOu {
    grid-area: 3 / 3 / 5 / 5;
    position: relative;
  }

  #plvX7Vf2FrLKtHzJ {
    grid-template-columns: 3.125rem 18.01601072rem 5.0944839rem 70.63950538rem 3.125rem;
    grid-template-rows: minmax(10.36109341rem, max-content) minmax(8.38270275rem, max-content) minmax(28.81728858rem, max-content) minmax(9.428376rem, max-content) minmax(10.36109341rem, max-content);
  }

  #VdeILkon1psweVJf {
    min-height: calc(56.04748079 * var(--1vh, 1vh));
  }
}

@media (min-width: 1024.05px) {
  #OqsN2dWNJz0dIZvP {
    grid-area: 2 / 2 / 4 / 4;
    position: relative;
  }

  #wKMkfaDmuqZ7EL4H {
    grid-area: 3 / 3 / 5 / 5;
    position: relative;
    clip-path: polygon(calc(5.81069312% + 0%) calc((0.04713676 * 100rem) + 0%), calc(100% - (0.59787622% + 0%)) calc((0.04713676 * 100rem) + 0%), calc(100% - (0.59787622% + 0%)) calc(100% - ((0.00013726 * 100rem) + 0%)), calc(5.81069312% + 0%) calc(100% - ((0.00013726 * 100rem) + 0%)));
    margin-left: -6.20857388%;
    margin-right: -0.63881513%;
    margin-bottom: -0.01372621%;
    margin-top: -4.71367625%;
  }

  #oyFLJsABht87qRYA {
    grid-template-columns: 0 0 100% 0;
    grid-template-rows: 0 0 100% 0;
  }

  #znDzx9uq82zRePeS {
    grid-area: 2 / 2 / 11 / 11;
    position: relative;
  }

  #Y5LcI1eqCsmC32CV {
    grid-area: 2 / 2 / 29 / 29;
    position: relative;
  }

  #oBThJfU6tD300DQE {
    stroke-width: calc(100rem * 0.0 / 1366.0);
  }

  #ZEoizaRHe7bZECcT {
    grid-area: 3 / 5 / 7 / 8;
    position: relative;
  }

  #zZsJapxwbcbPSxWJ {
    font-size: calc(1.28843338em - var(--ffsd));
  }

  #KEne4nohIFkh8FBf {
    --first-font-size: 1.28843338em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #zwX8vilUbYQ08nZL {
    min-width: 5.29197091rem;
  }

  #ZlD5SxsxfsZ2Mvy4 {
    grid-area: 8 / 3 / 10 / 7;
    position: relative;
  }

  #GUakv73886zOAUPq {
    grid-area: 11 / 4 / 15 / 9;
    position: relative;
  }

  #sAG6HMCqxXBPKKLc {
    grid-area: 19 / 6 / 26 / 10;
    position: relative;
  }

  #jnrVRUU53B2fCAHP {
    grid-area: 13 / 11 / 20 / 14;
    position: relative;
  }

  #jb9HpuJVsfxE6N9W {
    font-size: calc(1.29670886em - var(--ffsd));
  }

  #RAUOMvJvxwWTexs1 {
    --first-font-size: 1.29670886em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #PZrXwhL4nQx6TjAN {
    min-width: 4.65492503rem;
  }

  #hEnnesjQvbnuNdJz {
    grid-area: 21 / 12 / 24 / 13;
    position: relative;
  }

  #bV8ZVBpbkUz4uAZl {
    grid-area: 14 / 15 / 22 / 17;
    position: relative;
  }

  #nZhBlEiXWPiUxJo0 {
    font-size: calc(1.28822918em - var(--ffsd));
  }

  #EjcIhZMpf9iCmVjd {
    --first-font-size: 1.28822918em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #vKgPANXP9AhoG2CJ {
    min-width: 5.36153387rem;
  }

  #FPnmhk4ZGR4mC228 {
    grid-area: 23 / 16 / 25 / 18;
    position: relative;
  }

  #lbq8K7o6oawkjLTP {
    grid-area: 4 / 22 / 5 / 26;
    position: relative;
  }

  #DN6SSQPqn4mnxENP {
    font-size: calc(1.28843338em - var(--ffsd));
  }

  #neXnyDARM09GG5vH {
    --first-font-size: 1.28843338em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #yVRfpCdZ2PLqM9AL {
    min-width: 4.92872996rem;
  }

  #D1ZqOerd2c4MvUky {
    grid-area: 6 / 23 / 9 / 27;
    position: relative;
  }

  #eFXDw6lelKuAPXbY {
    grid-area: 12 / 19 / 16 / 24;
    position: relative;
  }

  #FOAZ2finbqk9NlMs {
    font-size: calc(1.35078278em - var(--ffsd));
  }

  #q6a32FiEUQa2AFUS {
    --first-font-size: 1.35078278em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #TmNIgOXPK21btcd8 {
    min-width: 5.6201009rem;
  }

  #krJ1PeZrtS7reFNz {
    grid-area: 17 / 20 / 18 / 25;
    position: relative;
  }

  #Lx3eASFtfM01wzTq {
    grid-area: 2 / 3 / 3 / 4;
    position: relative;
  }

  #eUISANMG3dWcg8cF {
    font-size: calc(1.28843338em - var(--ffsd));
  }

  #JSev41LwJbTR3dwc {
    --first-font-size: 1.28843338em;
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #uLtG7tsBVHcB8tQr {
    min-width: 6.98180896rem;
  }

  #GqviADSBMeR7y5af {
    grid-area: 4 / 2 / 5 / 5;
    position: relative;
  }

  #T43u1e6hvdOPtjQh {
    grid-template-columns: 0 1.12333075rem 4.69854423rem 1.12333075rem;
    grid-template-rows: 0 minmax(4.79060472rem, max-content) minmax(0.17683006rem, max-content) minmax(1.49926794rem, max-content);
  }

  #kn97wPNLMBp5ebrQ {
    grid-template-columns: 0 1.13202181rem 0 0.02714257rem 0 5.22822512rem 0.30895582rem 0 0.02714257rem 2.85264008rem 1.14405391rem 4.6183218rem 0.37082991rem 5.19073575rem 0.76816758rem 5.32493065rem 0 4.52153311rem 0.80546813rem 4.46016693rem 0.18963524rem 0 0.93369551rem 0 3.95843122rem 0 1.86344376rem 0.66180501rem;
    grid-template-rows: 0 minmax(1.36873439rem, max-content) minmax(0.3225271rem, max-content) minmax(4.89212674rem, max-content) 0 minmax(0.3225271rem, max-content) minmax(0.42710687rem, max-content) minmax(0.74963397rem, max-content) minmax(0.74963397rem, max-content) minmax(0.0906508rem, max-content) minmax(0.37821988rem, max-content) minmax(2.11950082rem, max-content) minmax(2.78216175rem, max-content) minmax(0.28444106rem, max-content) minmax(1.20286218rem, max-content) 0 minmax(1.61769164rem, max-content) minmax(0.2293619rem, max-content) minmax(0.01668709rem, max-content) 0 minmax(2.74205436rem, max-content) 0 minmax(0.62349867rem, max-content) minmax(0.91927905rem, max-content) minmax(1.26280434rem, max-content) minmax(1.43046874rem, max-content) minmax(6.46670272rem, max-content) minmax(1.16098107rem, max-content);
  }

  #GoGORTLYxzrR7X6X {
    grid-area: 4 / 7 / 10 / 8;
    position: relative;
  }

  #P2XxREr5GFW3GJqp {
    grid-area: 5 / 4 / 8 / 6;
    position: relative;
  }

  #wBjmx07a0TUzHNgz {
    grid-area: 6 / 5 / 9 / 10;
    position: relative;
  }

  #pkXUoFRqAeXqm8WY {
    grid-template-columns: 0 5.61622502rem 0 2.89488334rem 1.7785973rem 0.8116065rem 4.65992295rem 34.2423364rem 0 5.58384108rem;
    grid-template-rows: 0 minmax(5.35044563rem, max-content) minmax(26.24938068rem, max-content) minmax(4.66248722rem, max-content) minmax(0.08680723rem, max-content) minmax(1.16098107rem, max-content) minmax(1.05767832rem, max-content) minmax(0.10330275rem, max-content) minmax(0.65442786rem, max-content) minmax(16.61150819rem, max-content);
  }

  #JTgx7u7Qa1RKLRFN {
    grid-area: 2 / 5 / 12 / 8;
    position: relative;
  }

  #dhV3p3fCQDn5y7oJ {
    grid-area: 3 / 9 / 5 / 12;
    position: relative;
  }

  #oFANS7V1QSuAiKdj {
    grid-area: 2 / 2 / 5 / 5;
    position: relative;
  }

  #b4adkyrn7x5PE2Nh {
    grid-area: 3 / 3 / 4 / 4;
    position: relative;
  }

  #p5vErsmSRsjWjNFP {
    grid-template-columns: 0 1.74927276rem 15.61655678rem 8.48007702rem;
    grid-template-rows: 0 minmax(1.58155119rem, max-content) minmax(15.61655678rem, max-content) minmax(1.18479307rem, max-content);
  }

  #MSWnB8FTDmgj24i4 {
    grid-area: 4 / 3 / 6 / 7;
    position: relative;
  }

  #SxsrVJ1tSwpYzFEU {
    grid-area: 7 / 4 / 10 / 6;
    position: relative;
  }

  #XMtUYKCOD1OyTybP {
    grid-area: 8 / 10 / 11 / 11;
    position: relative;
  }

  #vYOYARE87xLSgJRl {
    grid-area: 9 / 2 / 13 / 13;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: calc(min(1366px - 100vw, 0px) / 2);
    right: calc(min(1366px - 100vw, 0px) / 2);
    clip-path: polygon(0% calc((0 * 100rem) + 0%), 100% calc((0 * 100rem) + 0%), 100% calc(100% - ((0.1115322 * 100rem) + 0%)), 0% calc(100% - ((0.1115322 * 100rem) + 0%)));
    margin-left: -3.48790157%;
    margin-right: -1.88860708%;
    margin-bottom: -11.15321955%;
    margin-top: 0%;
  }

  #dLX3STwT8vA1TRta {
    grid-template-columns: 0 7.24981589rem 3.72436569rem 9.19858758rem 6.74824683rem 6.17470645rem 42.6644593rem 0 1.1771768rem 13.72920341rem 4.06535222rem 5.26808583rem;
    grid-template-rows: minmax(0.27180913rem, max-content) minmax(5.45166592rem, max-content) minmax(0.00849001rem, max-content) minmax(8.52878958rem, max-content) minmax(9.85411145rem, max-content) minmax(4.12545251rem, max-content) minmax(7.49415906rem, max-content) minmax(6.35092739rem, max-content) minmax(7.56006041rem, max-content) minmax(5.66719888rem, max-content) minmax(0.89616373rem, max-content) minmax(0.01371951rem, max-content);
  }

  #LepCE1qH99a70ORi {
    min-height: calc(50 * var(--1vh, 1vh));
  }

  #kTSajshTOj0Nxj8x {
    grid-area: 2 / 2 / 4 / 4;
    position: relative;
  }

  #Nev0cJY7mPKEfdC7 {
    grid-area: 3 / 3 / 5 / 5;
    position: relative;
    clip-path: polygon(calc(6.64745701% + 0%) calc((0.02424923 * 100rem) + 0%), calc(100% - (1.53912369% + 0%)) calc((0.02424923 * 100rem) + 0%), calc(100% - (1.53912369% + 0%)) calc(100% - ((0.03482805 * 100rem) + 0%)), calc(6.64745701% + 0%) calc(100% - ((0.03482805 * 100rem) + 0%)));
    margin-left: -7.2401802%;
    margin-right: -1.67636028%;
    margin-bottom: -3.48280525%;
    margin-top: -2.42492253%;
  }

  #aKI0wS7OtSyHdMdl {
    grid-template-columns: 0 0 100% 0;
    grid-template-rows: 0 0 100% 0;
  }

  #rnplIgVxKJsEqApo {
    grid-area: 2 / 2 / 4 / 4;
    position: relative;
  }

  #Hbmqhk4G9RETOeMQ {
    grid-area: 2 / 2 / 13 / 13;
    position: relative;
  }

  #UHx4Zugbf9XHa3lP {
    font-size: calc(3.7875183em - var(--ffsd));
  }

  #T5dq7bMNN2RFcINL {
    --first-font-size: 3.7875183em;
    --last-font-size: var(--first-font-size);
    transform: translateX(calc(var(--first-font-size) * -0.016));
    margin-top: calc(var(--first-font-size) * 0.35);
    margin-bottom: calc(var(--last-font-size) * 0.35);
  }

  #kIJyDrwfAAzBqpU7 {
    min-width: 28.77013177rem;
  }

  #CwdWQBLO94jyEhUJ {
    grid-area: 3 / 7 / 4 / 12;
    position: relative;
  }

  #Eg0viCLNOGCzvH0z {
    font-size: calc(3.7875183em - var(--ffsd));
  }

  #G56aSqbHlQcyhFbc {
    --first-font-size: 3.7875183em;
    --last-font-size: var(--first-font-size);
    transform: translateX(calc(var(--first-font-size) * -0.016));
    margin-top: calc(var(--first-font-size) * 0.35);
    margin-bottom: calc(var(--last-font-size) * 0.35);
  }

  #iPcYmMsD3tlsIBG9 {
    min-width: 14.7556735rem;
  }

  #Vx9NPAGIerWsKCCO {
    grid-area: 5 / 6 / 6 / 8;
    position: relative;
  }

  #btvB0DXn4Iny0c8H {
    font-size: calc(3.7875183em - var(--ffsd));
  }

  #YqGUao9bBR8JoIca {
    --first-font-size: 3.7875183em;
    --last-font-size: var(--first-font-size);
    transform: translateX(calc(var(--first-font-size) * -0.016));
    margin-top: calc(var(--first-font-size) * 0.35);
    margin-bottom: calc(var(--last-font-size) * 0.35);
  }

  #J0mWfXUDNyVOSqP9 {
    min-width: 16.89848781rem;
  }

  #JiU5wzn8HfKfJeYx {
    grid-area: 7 / 3 / 8 / 9;
    position: relative;
  }

  #kQ2nZow0ri6YnKWs {
    font-size: calc(3.7875183em - var(--ffsd));
  }

  #JjNSja5veWoxbGUr {
    --first-font-size: 3.7875183em;
    --last-font-size: var(--first-font-size);
    transform: translateX(calc(var(--first-font-size) * -0.016));
    margin-top: calc(var(--first-font-size) * 0.35);
    margin-bottom: calc(var(--last-font-size) * 0.35);
  }

  #H80PzYxC8GlULTvo {
    min-width: 24.33504297rem;
  }

  #XC49D7rFAG9bMpiB {
    grid-area: 9 / 4 / 10 / 11;
    position: relative;
  }

  #lL5bi5dsw434D1fe {
    font-size: calc(3.7875183em - var(--ffsd));
  }

  #w0XS94Nsi1cu2I3g {
    --first-font-size: 3.7875183em;
    --last-font-size: var(--first-font-size);
    transform: translateX(calc(var(--first-font-size) * -0.016));
    margin-top: calc(var(--first-font-size) * 0.35);
    margin-bottom: calc(var(--last-font-size) * 0.35);
  }

  #Y9cko4qoeleSE6fV {
    min-width: 23.27812496rem;
  }

  #eZCVnmBqFzYkFZhR {
    grid-area: 11 / 5 / 12 / 10;
    position: relative;
  }

  #csTTVYjNsZmkEQyf {
    grid-template-columns: 0 5.78812783rem 0 0 0.00152588rem 0.06863104rem 14.65043924rem 2.14128843rem 6.37963715rem 1.05691801rem 4.50524572rem 35.29947707rem;
    grid-template-rows: 0 minmax(5.76519236rem, max-content) minmax(4.48142387rem, max-content) minmax(0.73206442rem, max-content) minmax(4.48142387rem, max-content) minmax(0.73206442rem, max-content) minmax(4.48142387rem, max-content) minmax(0.71954299rem, max-content) minmax(4.48142387rem, max-content) minmax(0.71954299rem, max-content) minmax(4.48142387rem, max-content) minmax(4.21957513rem, max-content);
  }

  #y77AlqzCid4FXdOu {
    grid-area: 3 / 3 / 5 / 5;
    position: relative;
  }

  #plvX7Vf2FrLKtHzJ {
    grid-template-columns: 0.91191538rem 16.62611792rem 4.70145647rem 65.1898339rem 12.57067633rem;
    grid-template-rows: minmax(3.78461079rem, max-content) minmax(7.73599697rem, max-content) minmax(26.59410264rem, max-content) minmax(8.700999rem, max-content) minmax(9.40683818rem, max-content);
  }

  #VdeILkon1psweVJf {
    min-height: calc(50 * var(--1vh, 1vh));
  }
}

@keyframes pulse {
  0% {
    background-color: rgba(226, 226, 226, 0.05);
  }

  50% {
    background-color: rgba(226, 226, 226, 0.1);
  }

  100% {
    background-color: rgba(226, 226, 226, 0.05);
  }
}
