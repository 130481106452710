:root {
  --ffsd: 0px;
  --1vw: calc((100vw - var(--sbw, 0px)) / 100);
  --1vh: var(--inner1Vh, 1vh);
}

@media (prefers-reduced-motion: reduce) {
  .animated {
    animation: none !important;
  }
}

html {
  zoom: var(--rzf, 1);
  font-size: max(calc(min(var(--1vw, 1vw), 13.66px) * var(--rfso, 1)), var(--minfs, 0px));
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

body {
  font-size: calc(1rem * var(--bfso, 1));
}

body,
html,
p,
ul,
ol,
li {
  margin: 0;
  padding: 0;
  font-synthesis: none;
  font-kerning: none;
  font-variant-ligatures: none;
  font-feature-settings: "kern" 0, "calt" 0, "liga" 0, "clig" 0, "dlig" 0, "hlig" 0;
  font-family: unset;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricprecision;
  white-space: normal;
}

li {
  text-align: unset;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  -webkit-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-touch-callout: none;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../public/fonts/OpenSans-Bold.ttf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../public/fonts/OpenSans-Bold.ttf);
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../public/fonts/OpenSans-Bold.ttf);
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../public/fonts/OpenSans-Bold.ttf);
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../public/fonts/OpenSans-Bold.ttf);
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../public/fonts/OpenSans-Bold.ttf);
  font-style: italic;
  font-weight: 100;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../public/fonts/OpenSans-Bold.ttf);
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../public/fonts/OpenSans-Bold.ttf);
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../public/fonts/OpenSans-Bold.ttf);
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../public/fonts/OpenSans-Bold.ttf);
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../public/fonts/OpenSans-Bold.ttf);
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../public/fonts/OpenSans-Bold.ttf);
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../public/fonts/OpenSans-Bold.ttf);
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../public/fonts/OpenSans-Bold.ttf);
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../public/fonts/OpenSans-Bold.ttf);
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../public/fonts/OpenSans-Bold.ttf);
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../public/fonts/OpenSans-Bold.ttf);
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: YACgEZ1cb1Q-0;
  src: url(../../public/fonts/OpenSans-Bold.ttf);
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../public/fonts/OpenSans.ttf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../public/fonts/OpenSans.ttf);
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../public/fonts/OpenSans.ttf);
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../public/fonts/OpenSans.ttf);
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../public/fonts/OpenSans.ttf);
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../public/fonts/OpenSans.ttf);
  font-style: italic;
  font-weight: 100;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../public/fonts/OpenSans.ttf);
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../public/fonts/OpenSans.ttf);
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../public/fonts/OpenSans.ttf);
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../public/fonts/OpenSans.ttf);
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../public/fonts/OpenSans.ttf);
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: YAFdJt8dAY0-0;
  src: url(../../public/fonts/OpenSans.ttf);
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: YAFdJt8dAY0-b;
  src: url(../../public/fonts/OpenSans-Bold.ttf);
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: YAFdJt8dAY0-b;
  src: url(../../public/fonts/OpenSans-Bold.ttf);
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: YAFdJt8dAY0-b;
  src: url(../../public/fonts/OpenSans-Bold.ttf);
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: YAFdJt8dAY0-b;
  src: url(../../public/fonts/OpenSans-Bold.ttf);
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: YAFdJt8dAY0-b;
  src: url(../../public/fonts/OpenSans-Bold.ttf);
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: YAFdJt8dAY0-b;
  src: url(../../public/fonts/OpenSans-Bold.ttf);
  font-style: italic;
  font-weight: 900;
}

@media (max-width: 375px) {
  #UhIZjZkX5MlGT3al {
    grid-area: 2 / 2 / 4 / 4;
    position: relative;
  }

  #TL9AXZmz0EWXiV75 {
    grid-area: 3 / 3 / 5 / 5;
    position: relative;
  }

  #rKIr9zWBRCjsajSf {
    grid-template-columns: 0 561.93479778% 93.57823346% 0;
    left: -277.75651562%;
    grid-template-rows: 0 76.77704195% 23.22295805% 0;
  }

  #QjhCorpQeqNzHyxr {
    grid-area: 2 / 2 / 5 / 5;
    position: relative;
  }

  #mo8RU4eoOuC1Xeu4 {
    display: none;
  }

  #SNRqqfot5mrdySNN {
    display: none;
  }

  #JsAaxJa1oO5rmrPW {
    display: block;
  }

  #XB8f7bYHtFkI16Gv {
    grid-area: 2 / 2 / 12 / 13;
    position: relative;
  }

  #zumRjPFlOJIBYDhg {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(7.35172907em - var(--ffsd)));
  }

  #zLN1xfZ7xFcIP5Gy {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 7.35172907em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #fSuCLcGAPwUFPDqU {
    min-width: 66rem;
  }

  #fXkG4afEC6lBS0FQ {
    grid-area: 3 / 3 / 4 / 8;
    position: relative;
  }

  #BoK9RZiARwaOZ39R {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.89383103em - var(--ffsd)));
  }

  #YDORAyqMeHAtSarW {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.89383103em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #aJ7FONkEAHXNxxHu {
    min-width: 66rem;
  }

  #szMGYf5Pp9DA5ihu {
    grid-area: 5 / 4 / 6 / 9;
    position: relative;
  }

  #Iur7dXhjNq9ASuHP {
    grid-area: 7 / 5 / 8 / 10;
    position: relative;
  }

  #YGHqMqsapk2RRn0j {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.97987793em - var(--ffsd)));
  }

  #SsHhdvCo0kg8Msx4 {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.97987793em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #qQ1NEfRqS96UwCln {
    min-width: 66rem;
  }

  #MYA23zVKslLDthSK {
    grid-area: 9 / 6 / 10 / 11;
    position: relative;
  }

  #Pzzo8oaiQVgfC08V {
    grid-area: 11 / 7 / 13 / 12;
    position: relative;
  }

  #xBjWDCIHbOuYx0DU {
    grid-template-columns: 0 6.4rem 0 0 0 0 65.86666667rem 0 0 0 0 6.4rem;
    grid-template-rows: 0 minmax(8.64329295rem, max-content) minmax(8.69301681rem, max-content) minmax(10.56001359rem, max-content) minmax(8.15157748rem, max-content) minmax(3.41643041rem, max-content) minmax(14.4448968rem, max-content) minmax(7.99232952rem, max-content) minmax(5.81372138rem, max-content) minmax(6.4rem, max-content) minmax(20.995rem, max-content) 0;
  }

  #aaSG147XZh7OMimw {
    grid-template-columns: 0 6.4rem 78.66666667rem 6.4rem;
    grid-template-rows: 0 minmax(16.07071389rem, max-content) minmax(95.11027894rem, max-content) minmax(16.07071389rem, max-content);
  }

  #dPVkGRVWgnbGXhoW {
    grid-area: 2 / 2 / 3 / 9;
    grid-template-columns: 0 91.46666667rem;
    grid-template-rows: 0 minmax(127.25170672rem, max-content);
  }

  #IQF3Ep2yt3EX14OE {
    grid-area: 2 / 2 / 3 / 9;
    position: relative;
  }

  #DMBMsVZi4WULzlTl {
    grid-area: 2 / 2 / 3 / 4;
    position: relative;
  }

  #Uyaq83tn5q6evDET {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.67898667em - var(--ffsd)));
  }

  #Mfx73xOlY7KZhUCJ {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.67898667em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #qNDyGDm4hkQYCNWI {
    min-width: 31.72381864rem;
  }

  #YeQijaYUxMux8Xqx {
    grid-area: 4 / 3 / 5 / 5;
    position: relative;
  }

  #jJaQLgDXMjYcNc0d {
    grid-area: 4 / 4 / 5 / 7;
    grid-template-columns: 0 0 31.59048531rem 0;
    grid-template-rows: 0 minmax(31.59048531rem, max-content) 0 minmax(23.30259987rem, max-content) minmax(3.3616028rem, max-content);
  }

  #bqx5L97Cgpb6bNnL {
    grid-area: 4 / 4 / 5 / 7;
    position: relative;
  }

  #avweY2aftVjtPiqj {
    grid-area: 2 / 2 / 3 / 4;
    position: relative;
  }

  #eGSC8L0I0qgTi6NX {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(7.82221333em - var(--ffsd)));
  }

  #BXjpmalCC8VMd7LE {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 7.82221333em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #i2wu6z7RfNqrODUj {
    min-width: 27.72486658rem;
  }

  #AJsfFDXGwkWgEBvT {
    grid-area: 4 / 3 / 5 / 5;
    position: relative;
  }

  #rAQiFpSKZI6wC6hI {
    grid-area: 6 / 5 / 7 / 8;
    grid-template-columns: 0 0 27.59153325rem 0;
    grid-template-rows: 0 minmax(27.59153325rem, max-content) minmax(2.95455878rem, max-content) minmax(9.36889067rem, max-content);
  }

  #tE3EbBcBp0CVfwhB {
    grid-area: 6 / 5 / 7 / 8;
    position: relative;
  }

  #QJ1sBkgu1KnIl8JT {
    grid-area: 2 / 3 / 3 / 4;
    position: relative;
  }

  #H6kEj03N7wQwUfTg {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(7.82221333em - var(--ffsd)));
  }

  #Q1D4QowNUi0vK5OC {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 7.82221333em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #KUGXEYrg2haBzDKr {
    min-width: 42.99892767rem;
  }

  #ZH9m94dW9SmTCfIc {
    grid-area: 4 / 2 / 5 / 5;
    position: relative;
  }

  #qEWd97vZXYlVrz3J {
    grid-area: 8 / 3 / 9 / 6;
    grid-template-columns: 0 6.07111566rem 30.72336301rem 6.07111566rem;
    grid-template-rows: 0 minmax(30.72336301rem, max-content) 0 minmax(9.36889067rem, max-content);
  }

  #VMXkuJ3ga6NfJ5HR {
    grid-area: 8 / 3 / 9 / 6;
    position: relative;
  }

  #n7jyUkOIsGk7yWmF {
    grid-template-columns: 4.26666667rem 17.04842587rem 12.88966481rem 16.88861687rem 13.08731266rem 1.61455578rem 12.88966481rem 17.04842587rem 4.26666667rem;
    grid-template-rows: minmax(4.26666667rem, max-content) minmax(127.25170672rem, max-content) minmax(6.4rem, max-content) minmax(58.25468798rem, max-content) minmax(6.4rem, max-content) minmax(39.91498269rem, max-content) minmax(81.69916151rem, max-content) minmax(40.09225368rem, max-content) minmax(4.26666667rem, max-content);
  }

  #PbN9emFs7ia7ueUZ {
    min-height: calc(100 * var(--1vh, 1vh));
  }
}

@media (min-width: 375.05px) and (max-width: 480px) {
  .input0 {
    font-family: 'YAFdJt8dAY0-0' !important;
    font-size: 30pt;
    border-radius: 15px;
    background-color: rgb(211, 208, 208);
  }

  #UhIZjZkX5MlGT3al {
    grid-area: 2 / 2 / 4 / 4;
    position: relative;
  }

  #TL9AXZmz0EWXiV75 {
    grid-area: 3 / 3 / 5 / 5;
    position: relative;
  }

  #rKIr9zWBRCjsajSf {
    grid-template-columns: 0 451.87603724% 75.25029857% 0;
    left: -213.5631679%;
    grid-template-rows: 0 76.77704195% 23.22295805% 0;
  }

  #QjhCorpQeqNzHyxr {
    grid-area: 2 / 2 / 5 / 5;
    position: relative;
  }

  #mo8RU4eoOuC1Xeu4 {
    display: none;
  }

  #SNRqqfot5mrdySNN {
    display: block;
  }

  #JsAaxJa1oO5rmrPW {
    display: none;
  }

  #XB8f7bYHtFkI16Gv {
    grid-area: 2 / 2 / 12 / 13;
    position: relative;
  }

  #zumRjPFlOJIBYDhg {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(7.32318331em - var(--ffsd)));
  }

  #zLN1xfZ7xFcIP5Gy {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 7.32318331em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #fSuCLcGAPwUFPDqU {
    min-width: 73.4375rem;
  }

  #fXkG4afEC6lBS0FQ {
    grid-area: 3 / 3 / 4 / 11;
    position: relative;
  }

  #BoK9RZiARwaOZ39R {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(7.32318331em - var(--ffsd)));
  }

  #YDORAyqMeHAtSarW {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 7.32318331em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #aJ7FONkEAHXNxxHu {
    min-width: 70.07305213rem;
  }

  #szMGYf5Pp9DA5ihu {
    grid-area: 5 / 5 / 6 / 10;
    position: relative;
  }

  #Iur7dXhjNq9ASuHP {
    grid-area: 7 / 6 / 8 / 8;
    position: relative;
  }

  #YGHqMqsapk2RRn0j {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.89052963em - var(--ffsd)));
  }

  #SsHhdvCo0kg8Msx4 {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.89052963em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #qQ1NEfRqS96UwCln {
    min-width: 73.4375rem;
  }

  #MYA23zVKslLDthSK {
    grid-area: 9 / 4 / 10 / 12;
    position: relative;
  }

  #Pzzo8oaiQVgfC08V {
    grid-area: 11 / 7 / 13 / 9;
    position: relative;
  }

  #xBjWDCIHbOuYx0DU {
    grid-template-columns: 0 5rem 0 1.68222393rem 0 1.37192938rem 64.23898602rem 2.98604068rem 1.37192938rem 1.68222393rem 0 5rem;
    grid-template-rows: 0 minmax(6.75257262rem, max-content) minmax(8.65926302rem, max-content) minmax(8.25001062rem, max-content) minmax(8.65926302rem, max-content) minmax(2.66908626rem, max-content) minmax(14.38880924rem, max-content) minmax(6.24400744rem, max-content) minmax(4.54196983rem, max-content) minmax(5rem, max-content) minmax(21.42797726rem, max-content) 0;
  }

  #aaSG147XZh7OMimw {
    grid-template-columns: 0 5rem 83.33333333rem 5rem;
    grid-template-rows: 0 minmax(10.62981534rem, max-content) minmax(86.5929593rem, max-content) minmax(10.62981534rem, max-content);
  }

  #dPVkGRVWgnbGXhoW {
    grid-area: 2 / 2 / 3 / 9;
    grid-template-columns: 0 93.33333333rem;
    grid-template-rows: 0 minmax(107.85258999rem, max-content);
  }

  #IQF3Ep2yt3EX14OE {
    grid-area: 2 / 2 / 3 / 9;
    position: relative;
  }

  #DMBMsVZi4WULzlTl {
    grid-area: 2 / 2 / 3 / 4;
    position: relative;
  }

  #Uyaq83tn5q6evDET {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.21795833em - var(--ffsd)));
  }

  #Mfx73xOlY7KZhUCJ {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.21795833em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #qNDyGDm4hkQYCNWI {
    min-width: 24.78423332rem;
  }

  #YeQijaYUxMux8Xqx {
    grid-area: 4 / 3 / 5 / 5;
    position: relative;
  }

  #jJaQLgDXMjYcNc0d {
    grid-area: 4 / 4 / 5 / 7;
    grid-template-columns: 0 0 24.68006665rem 0;
    grid-template-rows: 0 minmax(24.68006665rem, max-content) 0 minmax(18.20515615rem, max-content) minmax(2.62625219rem, max-content);
  }

  #bqx5L97Cgpb6bNnL {
    grid-area: 4 / 4 / 5 / 7;
    position: relative;
  }

  #avweY2aftVjtPiqj {
    grid-area: 2 / 2 / 3 / 4;
    position: relative;
  }

  #eGSC8L0I0qgTi6NX {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.11110417em - var(--ffsd)));
  }

  #BXjpmalCC8VMd7LE {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.11110417em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #i2wu6z7RfNqrODUj {
    min-width: 21.66005202rem;
  }

  #AJsfFDXGwkWgEBvT {
    grid-area: 4 / 3 / 5 / 5;
    position: relative;
  }

  #rAQiFpSKZI6wC6hI {
    grid-area: 6 / 5 / 7 / 8;
    grid-template-columns: 0 0 21.55588535rem 0;
    grid-template-rows: 0 minmax(21.55588535rem, max-content) minmax(2.30824904rem, max-content) minmax(7.31944583rem, max-content);
  }

  #tE3EbBcBp0CVfwhB {
    grid-area: 6 / 5 / 7 / 8;
    position: relative;
  }

  #QJ1sBkgu1KnIl8JT {
    grid-area: 2 / 3 / 3 / 4;
    position: relative;
  }

  #H6kEj03N7wQwUfTg {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.11110417em - var(--ffsd)));
  }

  #Q1D4QowNUi0vK5OC {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.11110417em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #KUGXEYrg2haBzDKr {
    min-width: 33.59291225rem;
  }

  #ZH9m94dW9SmTCfIc {
    grid-area: 4 / 2 / 5 / 5;
    position: relative;
  }

  #qEWd97vZXYlVrz3J {
    grid-area: 8 / 3 / 9 / 6;
    grid-template-columns: 0 4.74305911rem 24.00262735rem 4.74305911rem;
    grid-template-rows: 0 minmax(24.00262735rem, max-content) 0 minmax(7.31944583rem, max-content);
  }

  #VMXkuJ3ga6NfJ5HR {
    grid-area: 8 / 3 / 9 / 6;
    position: relative;
  }

  #n7jyUkOIsGk7yWmF {
    grid-template-columns: 3.33333333rem 24.25658271rem 10.07005063rem 13.19423193rem 10.22446301rem 1.2613717rem 10.07005063rem 24.25658271rem 3.33333333rem;
    grid-template-rows: minmax(3.33333333rem, max-content) minmax(107.85258999rem, max-content) minmax(5rem, max-content) minmax(45.51147498rem, max-content) minmax(5rem, max-content) minmax(31.18358023rem, max-content) minmax(63.82746993rem, max-content) minmax(31.32207319rem, max-content) minmax(3.33333333rem, max-content);
  }

  #PbN9emFs7ia7ueUZ {
    min-height: calc(100 * var(--1vh, 1vh));
  }
}

@media (min-width: 480.05px) and (max-width: 768px) {
  #UhIZjZkX5MlGT3al {
    grid-area: 2 / 2 / 4 / 4;
    position: relative;
  }

  #TL9AXZmz0EWXiV75 {
    grid-area: 3 / 3 / 5 / 5;
    position: relative;
  }

  #rKIr9zWBRCjsajSf {
    grid-template-columns: 0 281.7639239% 46.92176095% 0;
    left: -114.34284243%;
    grid-template-rows: 0 76.77704195% 23.22295805% 0;
  }

  #QjhCorpQeqNzHyxr {
    grid-area: 2 / 2 / 5 / 5;
    position: relative;
  }

  #mo8RU4eoOuC1Xeu4 {
    display: block;
  }

  #SNRqqfot5mrdySNN {
    display: none;
  }

  #JsAaxJa1oO5rmrPW {
    display: none;
  }

  #XB8f7bYHtFkI16Gv {
    grid-area: 2 / 2 / 12 / 11;
    position: relative;
  }

  #zumRjPFlOJIBYDhg {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.57698957em - var(--ffsd)));
  }

  #zLN1xfZ7xFcIP5Gy {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.57698957em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #fSuCLcGAPwUFPDqU {
    min-width: 83.59662854rem;
  }

  #fXkG4afEC6lBS0FQ {
    grid-area: 3 / 3 / 4 / 12;
    position: relative;
  }

  #BoK9RZiARwaOZ39R {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.57698957em - var(--ffsd)));
  }

  #YDORAyqMeHAtSarW {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.57698957em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #aJ7FONkEAHXNxxHu {
    min-width: 43.79565758rem;
  }

  #szMGYf5Pp9DA5ihu {
    grid-area: 5 / 4 / 6 / 10;
    position: relative;
  }

  #Iur7dXhjNq9ASuHP {
    grid-area: 7 / 5 / 8 / 8;
    position: relative;
  }

  #YGHqMqsapk2RRn0j {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.43158102em - var(--ffsd)));
  }

  #SsHhdvCo0kg8Msx4 {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.43158102em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #qQ1NEfRqS96UwCln {
    min-width: 28.21172426rem;
  }

  #MYA23zVKslLDthSK {
    grid-area: 10 / 6 / 11 / 7;
    position: relative;
  }

  .input0 {
    font-family: 'YAFdJt8dAY0-0' !important;
    font-size: 30pt;
    border-radius: 15px;
    background-color: rgb(211, 208, 208);
  }

  #Pzzo8oaiQVgfC08V {
    grid-area: 9 / 9 / 13 / 13;
    position: relative;
  }

  #xBjWDCIHbOuYx0DU {
    grid-template-columns: 0 1.5022658rem 0 0 1.13954615rem 28.14662009rem 11.72065589rem 0.50906056rem 2.21467073rem 39.80097096rem 0 0;
    grid-template-rows: 0 minmax(4.22035789rem, max-content) minmax(5.41203939rem, max-content) minmax(5.15625664rem, max-content) minmax(5.41203939rem, max-content) minmax(1.66817891rem, max-content) minmax(8.99300578rem, max-content) minmax(3.90250465rem, max-content) minmax(0.00076755rem, max-content) minmax(2.83873114rem, max-content) minmax(10.5529871rem, max-content) 0;
  }

  #aaSG147XZh7OMimw {
    grid-template-columns: 0 2.38993237rem 85.03379017rem 2.37431805rem;
    grid-template-rows: 0 minmax(10.16226918rem, max-content) minmax(48.15686842rem, max-content) minmax(2.40678735rem, max-content);
  }

  #dPVkGRVWgnbGXhoW {
    grid-area: 2 / 2 / 3 / 9;
    grid-template-columns: 0 89.79804059rem;
    grid-template-rows: 0 minmax(60.72592495rem, max-content);
  }

  #IQF3Ep2yt3EX14OE {
    grid-area: 2 / 2 / 3 / 9;
    position: relative;
  }

  #DMBMsVZi4WULzlTl {
    grid-area: 2 / 2 / 3 / 4;
    position: relative;
  }

  #Uyaq83tn5q6evDET {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.26122396em - var(--ffsd)));
  }

  #Mfx73xOlY7KZhUCJ {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.26122396em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #qNDyGDm4hkQYCNWI {
    min-width: 15.49014582rem;
  }

  #YeQijaYUxMux8Xqx {
    grid-area: 4 / 3 / 5 / 5;
    position: relative;
  }

  #jJaQLgDXMjYcNc0d {
    grid-area: 4 / 4 / 5 / 7;
    grid-template-columns: 0 0 15.42504166rem 0;
    grid-template-rows: 0 minmax(15.42504166rem, max-content) 0 minmax(11.37822259rem, max-content) minmax(1.64140762rem, max-content);
  }

  #bqx5L97Cgpb6bNnL {
    grid-area: 4 / 4 / 5 / 7;
    position: relative;
  }

  #avweY2aftVjtPiqj {
    grid-area: 2 / 2 / 3 / 4;
    position: relative;
  }

  #eGSC8L0I0qgTi6NX {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.8194401em - var(--ffsd)));
  }

  #BXjpmalCC8VMd7LE {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.8194401em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #i2wu6z7RfNqrODUj {
    min-width: 13.53753251rem;
  }

  #AJsfFDXGwkWgEBvT {
    grid-area: 4 / 3 / 5 / 5;
    position: relative;
  }

  #rAQiFpSKZI6wC6hI {
    grid-area: 6 / 5 / 7 / 8;
    grid-template-columns: 0 0 13.47242834rem 0;
    grid-template-rows: 0 minmax(13.47242834rem, max-content) minmax(1.44265565rem, max-content) minmax(4.57465365rem, max-content);
  }

  #tE3EbBcBp0CVfwhB {
    grid-area: 6 / 5 / 7 / 8;
    position: relative;
  }

  #QJ1sBkgu1KnIl8JT {
    grid-area: 2 / 3 / 3 / 4;
    position: relative;
  }

  #H6kEj03N7wQwUfTg {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.8194401em - var(--ffsd)));
  }

  #Q1D4QowNUi0vK5OC {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.8194401em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #KUGXEYrg2haBzDKr {
    min-width: 20.99557015rem;
  }

  #ZH9m94dW9SmTCfIc {
    grid-area: 4 / 2 / 5 / 5;
    position: relative;
  }

  #qEWd97vZXYlVrz3J {
    grid-area: 8 / 3 / 9 / 6;
    grid-template-columns: 0 2.96441195rem 15.0016421rem 2.96441195rem;
    grid-template-rows: 0 minmax(15.0016421rem, max-content) 0 minmax(4.57465365rem, max-content);
  }

  #VMXkuJ3ga6NfJ5HR {
    grid-area: 8 / 3 / 9 / 6;
    position: relative;
  }

  #n7jyUkOIsGk7yWmF {
    grid-template-columns: 5.10097971rem 30.89271782rem 6.29378165rem 8.24639496rem 6.39028938rem 0.78835732rem 6.29378165rem 30.89271782rem 5.10097971rem;
    grid-template-rows: minmax(4.16666667rem, max-content) minmax(60.72592495rem, max-content) minmax(4.16666667rem, max-content) minmax(28.44467186rem, max-content) minmax(4.16666667rem, max-content) minmax(19.48973764rem, max-content) minmax(39.8921687rem, max-content) minmax(19.57629574rem, max-content) minmax(4.16666667rem, max-content);
  }

  #PbN9emFs7ia7ueUZ {
    min-height: calc(100 * var(--1vh, 1vh));
  }
}


@media (min-width: 768.05px) and (max-width: 1024px) {
  #UhIZjZkX5MlGT3al {
    grid-area: 2 / 2 / 4 / 4;
    position: relative;
  }

  .input0 {
    font-family: 'YAFdJt8dAY0-0' !important;
    font-size: 30pt;
    border-radius: 15px;
    background-color: rgb(211, 208, 208);
  }

  #TL9AXZmz0EWXiV75 {
    grid-area: 3 / 3 / 5 / 5;
    position: relative;
  }

  #rKIr9zWBRCjsajSf {
    grid-template-columns: 0 262.61491937% 43.73290341% 0;
    left: -103.17391139%;
    grid-template-rows: 0 76.77704195% 23.22295805% 0;
  }

  #QjhCorpQeqNzHyxr {
    grid-area: 2 / 2 / 5 / 5;
    position: relative;
  }

  #mo8RU4eoOuC1Xeu4 {
    display: block;
  }

  #SNRqqfot5mrdySNN {
    display: none;
  }

  #JsAaxJa1oO5rmrPW {
    display: none;
  }

  #XB8f7bYHtFkI16Gv {
    grid-area: 2 / 2 / 12 / 11;
    position: relative;
  }

  #zumRjPFlOJIBYDhg {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.43274218em - var(--ffsd)));
  }

  #zLN1xfZ7xFcIP5Gy {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.43274218em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #fSuCLcGAPwUFPDqU {
    min-width: 77.90345278rem;
  }

  #fXkG4afEC6lBS0FQ {
    grid-area: 3 / 3 / 4 / 12;
    position: relative;
  }

  #BoK9RZiARwaOZ39R {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.43274218em - var(--ffsd)));
  }

  #YDORAyqMeHAtSarW {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.43274218em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #aJ7FONkEAHXNxxHu {
    min-width: 40.80740218rem;
  }

  #szMGYf5Pp9DA5ihu {
    grid-area: 5 / 4 / 6 / 10;
    position: relative;
  }

  #Iur7dXhjNq9ASuHP {
    grid-area: 7 / 5 / 8 / 8;
    position: relative;
  }

  #YGHqMqsapk2RRn0j {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.82368577em - var(--ffsd)));
  }

  #SsHhdvCo0kg8Msx4 {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.82368577em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #qQ1NEfRqS96UwCln {
    min-width: 26.28257112rem;
  }

  #MYA23zVKslLDthSK {
    grid-area: 10 / 6 / 11 / 7;
    position: relative;
  }

  #Pzzo8oaiQVgfC08V {
    grid-area: 9 / 9 / 13 / 13;
    position: relative;
  }

  #xBjWDCIHbOuYx0DU {
    grid-template-columns: 0 1.40017007rem 0 0 1.06210126rem 26.233743rem 10.92410646rem 0.47446421rem 2.06415913rem 37.0960506rem 0 0;
    grid-template-rows: 0 minmax(3.93353745rem, max-content) minmax(5.04423088rem, max-content) minmax(4.80583143rem, max-content) minmax(5.04423088rem, max-content) minmax(1.55480753rem, max-content) minmax(8.38183063rem, max-content) minmax(3.63728588rem, max-content) minmax(0.00071538rem, max-content) minmax(2.64580767rem, max-content) minmax(9.8357938rem, max-content) 0;
  }

  #aaSG147XZh7OMimw {
    grid-template-columns: 0 2.22750978rem 79.25479472rem 2.21295663rem;
    grid-template-rows: 0 minmax(9.47162953rem, max-content) minmax(44.88407153rem, max-content) minmax(2.24321928rem, max-content);
  }

  #dPVkGRVWgnbGXhoW {
    grid-area: 2 / 2 / 3 / 9;
    grid-template-columns: 0 83.69526113rem;
    grid-template-rows: 0 minmax(56.59892034rem, max-content);
  }

  #IQF3Ep2yt3EX14OE {
    grid-area: 2 / 2 / 3 / 9;
    position: relative;
  }

  #DMBMsVZi4WULzlTl {
    grid-area: 2 / 2 / 3 / 4;
    position: relative;
  }

  #Uyaq83tn5q6evDET {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.44591797em - var(--ffsd)));
  }

  #Mfx73xOlY7KZhUCJ {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.44591797em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #qNDyGDm4hkQYCNWI {
    min-width: 14.42556598rem;
  }

  #YeQijaYUxMux8Xqx {
    grid-area: 4 / 3 / 5 / 5;
    position: relative;
  }

  #jJaQLgDXMjYcNc0d {
    grid-area: 4 / 4 / 5 / 7;
    grid-template-columns: 0 0 14.37673785rem 0;
    grid-template-rows: 0 minmax(14.37673785rem, max-content) 0 minmax(10.60494533rem, max-content) minmax(1.52985564rem, max-content);
  }

  #bqx5L97Cgpb6bNnL {
    grid-area: 4 / 4 / 5 / 7;
    position: relative;
  }

  #avweY2aftVjtPiqj {
    grid-area: 2 / 2 / 3 / 4;
    position: relative;
  }

  #eGSC8L0I0qgTi6NX {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.86458008em - var(--ffsd)));
  }

  #BXjpmalCC8VMd7LE {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.86458008em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #i2wu6z7RfNqrODUj {
    min-width: 12.60565454rem;
  }

  #AJsfFDXGwkWgEBvT {
    grid-area: 4 / 3 / 5 / 5;
    position: relative;
  }

  #rAQiFpSKZI6wC6hI {
    grid-area: 6 / 5 / 7 / 8;
    grid-template-columns: 0 0 12.55682642rem 0;
    grid-template-rows: 0 minmax(12.55682642rem, max-content) minmax(1.34461109rem, max-content) minmax(4.26375485rem, max-content);
  }

  #tE3EbBcBp0CVfwhB {
    grid-area: 6 / 5 / 7 / 8;
    position: relative;
  }

  #QJ1sBkgu1KnIl8JT {
    grid-area: 2 / 3 / 3 / 4;
    position: relative;
  }

  #H6kEj03N7wQwUfTg {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.86458008em - var(--ffsd)));
  }

  #Q1D4QowNUi0vK5OC {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.86458008em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #KUGXEYrg2haBzDKr {
    min-width: 19.55683526rem;
  }

  #ZH9m94dW9SmTCfIc {
    grid-area: 4 / 2 / 5 / 5;
    position: relative;
  }

  #qEWd97vZXYlVrz3J {
    grid-area: 8 / 3 / 9 / 6;
    grid-template-columns: 0 2.76294706rem 13.98211302rem 2.76294706rem;
    grid-template-rows: 0 minmax(13.98211302rem, max-content) 0 minmax(4.26375485rem, max-content);
  }

  #VMXkuJ3ga6NfJ5HR {
    grid-area: 8 / 3 / 9 / 6;
    position: relative;
  }

  #n7jyUkOIsGk7yWmF {
    grid-template-columns: 8.15236943rem 28.79321273rem 5.86604891rem 7.68596035rem 5.95599787rem 0.73477963rem 5.86604891rem 28.79321273rem 8.15236943rem;
    grid-template-rows: minmax(3.88349515rem, max-content) minmax(56.59892034rem, max-content) minmax(3.88349515rem, max-content) minmax(26.51153883rem, max-content) minmax(3.88349515rem, max-content) minmax(18.16519237rem, max-content) minmax(37.18105044rem, max-content) minmax(18.24586788rem, max-content) minmax(3.88349515rem, max-content);
  }

  #PbN9emFs7ia7ueUZ {
    min-height: calc(100 * var(--1vh, 1vh));
  }
}

@media (min-width: 1024.05px) {
  #UhIZjZkX5MlGT3al {
    grid-area: 2 / 2 / 4 / 4;
    position: relative;
  }

  .input0 {
    font-family: 'YAFdJt8dAY0-0' !important;
    font-size: 30pt;
    border-radius: 15px;
    background-color: rgb(211, 208, 208);
  }

  #TL9AXZmz0EWXiV75 {
    grid-area: 3 / 3 / 5 / 5;
    position: relative;
  }

  #rKIr9zWBRCjsajSf {
    grid-template-columns: 0 85.7244282% 14.2755718% 0;
    grid-template-rows: 0 76.77704195% 23.22295805% 0;
  }

  #QjhCorpQeqNzHyxr {
    grid-area: 2 / 2 / 5 / 5;
    position: relative;
  }

  #mo8RU4eoOuC1Xeu4 {
    display: block;
  }

  #SNRqqfot5mrdySNN {
    display: none;
  }

  #JsAaxJa1oO5rmrPW {
    display: none;
  }

  #XB8f7bYHtFkI16Gv {
    grid-area: 2 / 2 / 12 / 12;
    position: relative;
  }

  #zumRjPFlOJIBYDhg {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.57330014em - var(--ffsd)));
  }

  #zLN1xfZ7xFcIP5Gy {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.57330014em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #fSuCLcGAPwUFPDqU {
    min-width: 23.0916833rem;
  }

  #fXkG4afEC6lBS0FQ {
    grid-area: 3 / 3 / 4 / 8;
    position: relative;
  }

  #BoK9RZiARwaOZ39R {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.57330014em - var(--ffsd)));
  }

  #YDORAyqMeHAtSarW {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.57330014em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #aJ7FONkEAHXNxxHu {
    min-width: 24.62303442rem;
  }

  #szMGYf5Pp9DA5ihu {
    grid-area: 5 / 4 / 6 / 11;
    position: relative;
  }

  #Iur7dXhjNq9ASuHP {
    grid-area: 7 / 5 / 8 / 9;
    position: relative;
  }

  #YGHqMqsapk2RRn0j {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.3670968em - var(--ffsd)));
  }

  #SsHhdvCo0kg8Msx4 {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.3670968em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #qQ1NEfRqS96UwCln {
    min-width: 15.8613501rem;
  }

  #MYA23zVKslLDthSK {
    grid-area: 10 / 6 / 11 / 7;
    position: relative;
  }

  #Pzzo8oaiQVgfC08V {
    grid-area: 9 / 10 / 13 / 13;
    position: relative;
  }

  #xBjWDCIHbOuYx0DU {
    grid-template-columns: 0 0.8446121rem 0 0 0.64068188rem 15.82474687rem 6.58965133rem 0 0.28620681rem 1.24514431rem 22.37711984rem 0;
    grid-template-rows: 0 minmax(2.37279272rem, max-content) minmax(3.04278642rem, max-content) minmax(2.89897884rem, max-content) minmax(3.04278642rem, max-content) minmax(0.93789268rem, max-content) minmax(5.05609695rem, max-content) minmax(2.19408753rem, max-content) minmax(0.00043154rem, max-content) minmax(1.59600697rem, max-content) minmax(5.93315819rem, max-content) 0;
  }

  #aaSG147XZh7OMimw {
    grid-template-columns: 0 1.34368086rem 47.80816314rem 1.3349021rem;
    grid-template-rows: 0 minmax(5.71348663rem, max-content) minmax(27.07501826rem, max-content) minmax(1.35315716rem, max-content);
  }

  #dPVkGRVWgnbGXhoW {
    grid-area: 4 / 2 / 9 / 3;
    grid-template-columns: 0 50.4867461rem;
    grid-template-rows: 0 minmax(34.14166205rem, max-content);
  }

  #IQF3Ep2yt3EX14OE {
    grid-area: 4 / 2 / 9 / 3;
    position: relative;
  }

  #DMBMsVZi4WULzlTl {
    grid-area: 2 / 2 / 3 / 4;
    position: relative;
  }

  #Uyaq83tn5q6evDET {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.83354319em - var(--ffsd)));
  }

  #Mfx73xOlY7KZhUCJ {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.83354319em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #qNDyGDm4hkQYCNWI {
    min-width: 7.61561606rem;
  }

  #YeQijaYUxMux8Xqx {
    grid-area: 4 / 3 / 5 / 5;
    position: relative;
  }

  #jJaQLgDXMjYcNc0d {
    grid-area: 5 / 4 / 7 / 5;
    grid-template-columns: 0 1.09333855rem 7.57901283rem 0;
    grid-template-rows: 0 minmax(8.67235138rem, max-content) 0 minmax(7.31996779rem, max-content);
  }

  #bqx5L97Cgpb6bNnL {
    grid-area: 5 / 4 / 7 / 5;
    position: relative;
  }

  #avweY2aftVjtPiqj {
    grid-area: 2 / 2 / 3 / 5;
    position: relative;
  }

  #eGSC8L0I0qgTi6NX {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.14738653em - var(--ffsd)));
  }

  #BXjpmalCC8VMd7LE {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.14738653em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #i2wu6z7RfNqrODUj {
    min-width: 6.26525153rem;
  }

  #AJsfFDXGwkWgEBvT {
    grid-area: 4 / 3 / 5 / 4;
    position: relative;
  }

  #rAQiFpSKZI6wC6hI {
    grid-area: 2 / 7 / 3 / 9;
    grid-template-columns: 0 0.67294706rem 6.22864831rem 0.67294706rem;
    grid-template-rows: 0 minmax(7.57454244rem, max-content) minmax(0.81109776rem, max-content) minmax(2.57198682rem, max-content);
  }

  #tE3EbBcBp0CVfwhB {
    grid-area: 2 / 7 / 3 / 9;
    position: relative;
  }

  #QJ1sBkgu1KnIl8JT {
    grid-area: 2 / 3 / 3 / 4;
    position: relative;
  }

  #H6kEj03N7wQwUfTg {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.14738653em - var(--ffsd)));
  }

  #Q1D4QowNUi0vK5OC {
    --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.14738653em);
    --last-font-size: var(--first-font-size);
    margin-top: calc(var(--first-font-size) * -0.1);
    margin-bottom: calc(var(--last-font-size) * -0.1);
  }

  #KUGXEYrg2haBzDKr {
    min-width: 11.80424442rem;
  }

  #ZH9m94dW9SmTCfIc {
    grid-area: 4 / 2 / 5 / 5;
    position: relative;
  }

  #qEWd97vZXYlVrz3J {
    grid-area: 6 / 6 / 8 / 8;
    grid-template-columns: 0 1.66666792rem 8.43430537rem 1.66666792rem;
    grid-template-rows: 0 minmax(8.43430537rem, max-content) 0 minmax(2.57198682rem, max-content);
  }

  #VMXkuJ3ga6NfJ5HR {
    grid-area: 6 / 6 / 8 / 8;
    position: relative;
  }

  #n7jyUkOIsGk7yWmF {
    grid-template-columns: 2.17258648rem 50.4867461rem 10.08779578rem 8.67235138rem 8.42112786rem 8.17485771rem 3.59278349rem 3.98175895rem 4.40999226rem;
    grid-template-rows: minmax(0.14344125rem, max-content) minmax(10.95762702rem, max-content) minmax(5.35756251rem, max-content) minmax(10.71505899rem, max-content) minmax(6.35577203rem, max-content) minmax(9.63654714rem, max-content) minmax(1.36974505rem, max-content) minmax(6.06453884rem, max-content) minmax(5.62225476rem, max-content);
  }

  #PbN9emFs7ia7ueUZ {
    min-height: calc(100 * var(--1vh, 1vh));
  }
}

@keyframes pulse {
  0% {
    background-color: rgba(226, 226, 226, 0.05);
  }

  50% {
    background-color: rgba(226, 226, 226, 0.1);
  }

  100% {
    background-color: rgba(226, 226, 226, 0.05);
  }
}