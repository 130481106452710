:root {
    --ffsd: 0px;
    --1vw: calc((100vw - var(--sbw, 0px)) / 100);
    --1vh: var(--inner1Vh, 1vh);
}

@media (prefers-reduced-motion: reduce) {
    .animated {
        animation: none !important;
    }
}

html {
    zoom: var(--rzf, 1);
    font-size: max(calc(min(var(--1vw, 1vw), 13.66px) * var(--rfso, 1)), var(--minfs, 0px));
    -webkit-text-size-adjust: 100%;
    scroll-behavior: smooth;
}

body {
    font-size: calc(1rem * var(--bfso, 1));
}

body,
html,
p,
ul,
ol,
li {
    margin: 0;
    padding: 0;
    font-synthesis: none;
    font-kerning: none;
    font-variant-ligatures: none;
    font-feature-settings: "kern" 0, "calt" 0, "liga" 0, "clig" 0, "dlig" 0, "hlig" 0;
    font-family: unset;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: geometricprecision;
    white-space: normal;
}

@keyframes pulse {}

.animated {
    animation-play-state: running !important;
}

li {
    text-align: unset;
}

a {
    text-decoration: none;
    color: inherit;
}

img {
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    -webkit-touch-callout: none;
}

@font-face {
    font-family: YACgEUY9wwg-0;
    src: url(../../public/fonts/peace_sans.otf);
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: YACgEUY9wwg-0;
    src: url(../../public/fonts/peace_sans.otf);
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: YACgEUY9wwg-0;
    src: url(../../public/fonts/peace_sans.otf);
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: YACgEUY9wwg-0;
    src: url(../../public/fonts/peace_sans.otf);
    font-style: italic;
    font-weight: 700;
}

@font-face {
    font-family: YACgEUY9wwg-0;
    src: url(../../public/fonts/peace_sans.otf);
    font-style: normal;
    font-weight: 100;
}

@font-face {
    font-family: YACgEUY9wwg-0;
    src: url(../../public/fonts/peace_sans.otf);
    font-style: italic;
    font-weight: 100;
}

@font-face {
    font-family: YACgEUY9wwg-0;
    src: url(../../public/fonts/peace_sans.otf);
    font-style: normal;
    font-weight: 200;
}

@font-face {
    font-family: YACgEUY9wwg-0;
    src: url(../../public/fonts/peace_sans.otf);
    font-style: italic;
    font-weight: 200;
}

@font-face {
    font-family: YACgEUY9wwg-0;
    src: url(../../public/fonts/peace_sans.otf);
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: YACgEUY9wwg-0;
    src: url(../../public/fonts/peace_sans.otf);
    font-style: italic;
    font-weight: 300;
}

@font-face {
    font-family: YACgEUY9wwg-0;
    src: url(../../public/fonts/peace_sans.otf);
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: YACgEUY9wwg-0;
    src: url(../../public/fonts/peace_sans.otf);
    font-style: italic;
    font-weight: 500;
}

@font-face {
    font-family: YACgEUY9wwg-0;
    src: url(../../public/fonts/peace_sans.otf);
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: YACgEUY9wwg-0;
    src: url(../../public/fonts/peace_sans.otf);
    font-style: italic;
    font-weight: 600;
}

@font-face {
    font-family: YACgEUY9wwg-0;
    src: url(../../public/fonts/peace_sans.otf);
    font-style: normal;
    font-weight: 800;
}

@font-face {
    font-family: YACgEUY9wwg-0;
    src: url(../../public/fonts/peace_sans.otf);
    font-style: italic;
    font-weight: 800;
}

@font-face {
    font-family: YACgEUY9wwg-0;
    src: url(../../public/fonts/peace_sans.otf);
    font-style: normal;
    font-weight: 900;
}

@font-face {
    font-family: YACgEUY9wwg-0;
    src: url(../../public/fonts/peace_sans.otf);
    font-style: italic;
    font-weight: 900;
}

@font-face {
    font-family: YAD02ZiRE08-0;
    src: url(../../public/fonts/ZingRustDemo-Base.otf);
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: YAD02ZiRE08-0;
    src: url(../../public/fonts/ZingRustDemo-Base.otf);
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: YAD02ZiRE08-0;
    src: url(../../public/fonts/ZingRustDemo-Base.otf);
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: YAD02ZiRE08-0;
    src: url(../../public/fonts/ZingRustDemo-Base.otf);
    font-style: italic;
    font-weight: 700;
}

@font-face {
    font-family: YAD02ZiRE08-0;
    src: url(../../public/fonts/ZingRustDemo-Base.otf);
    font-style: normal;
    font-weight: 100;
}

@font-face {
    font-family: YAD02ZiRE08-0;
    src: url(../../public/fonts/ZingRustDemo-Base.otf);
    font-style: italic;
    font-weight: 100;
}

@font-face {
    font-family: YAD02ZiRE08-0;
    src: url(../../public/fonts/ZingRustDemo-Base.otf);
    font-style: normal;
    font-weight: 200;
}

@font-face {
    font-family: YAD02ZiRE08-0;
    src: url(../../public/fonts/ZingRustDemo-Base.otf);
    font-style: italic;
    font-weight: 200;
}

@font-face {
    font-family: YAD02ZiRE08-0;
    src: url(../../public/fonts/ZingRustDemo-Base.otf);
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: YAD02ZiRE08-0;
    src: url(../../public/fonts/ZingRustDemo-Base.otf);
    font-style: italic;
    font-weight: 300;
}

@font-face {
    font-family: YAD02ZiRE08-0;
    src: url(../../public/fonts/ZingRustDemo-Base.otf);
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: YAD02ZiRE08-0;
    src: url(../../public/fonts/ZingRustDemo-Base.otf);
    font-style: italic;
    font-weight: 500;
}

@font-face {
    font-family: YAD02ZiRE08-0;
    src: url(../../public/fonts/ZingRustDemo-Base.otf);
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: YAD02ZiRE08-0;
    src: url(../../public/fonts/ZingRustDemo-Base.otf);
    font-style: italic;
    font-weight: 600;
}

@font-face {
    font-family: YAD02ZiRE08-0;
    src: url(../../public/fonts/ZingRustDemo-Base.otf);
    font-style: normal;
    font-weight: 800;
}

@font-face {
    font-family: YAD02ZiRE08-0;
    src: url(../../public/fonts/ZingRustDemo-Base.otf);
    font-style: italic;
    font-weight: 800;
}

@font-face {
    font-family: YAD02ZiRE08-0;
    src: url(../../public/fonts/ZingRustDemo-Base.otf);
    font-style: normal;
    font-weight: 900;
}

@font-face {
    font-family: YAD02ZiRE08-0;
    src: url(../../public/fonts/ZingRustDemo-Base.otf);
    font-style: italic;
    font-weight: 900;
}

@font-face {
    font-family: YADoUIDNzaE-1;
    src: url(../../public/fonts/edo.ttf);
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: YADoUIDNzaE-1;
    src: url(../../public/fonts/edo.ttf);
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: YADoUIDNzaE-1;
    src: url(../../public/fonts/edo.ttf);
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: YADoUIDNzaE-1;
    src: url(../../public/fonts/edo.ttf);
    font-style: italic;
    font-weight: 700;
}

@font-face {
    font-family: YADoUIDNzaE-1;
    src: url(../../public/fonts/edo.ttf);
    font-style: normal;
    font-weight: 100;
}

@font-face {
    font-family: YADoUIDNzaE-1;
    src: url(../../public/fonts/edo.ttf);
    font-style: italic;
    font-weight: 100;
}

@font-face {
    font-family: YADoUIDNzaE-1;
    src: url(../../public/fonts/edo.ttf);
    font-style: normal;
    font-weight: 200;
}

@font-face {
    font-family: YADoUIDNzaE-1;
    src: url(../../public/fonts/edo.ttf);
    font-style: italic;
    font-weight: 200;
}

@font-face {
    font-family: YADoUIDNzaE-1;
    src: url(../../public/fonts/edo.ttf);
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: YADoUIDNzaE-1;
    src: url(../../public/fonts/edo.ttf);
    font-style: italic;
    font-weight: 300;
}

@font-face {
    font-family: YADoUIDNzaE-1;
    src: url(../../public/fonts/edo.ttf);
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: YADoUIDNzaE-1;
    src: url(../../public/fonts/edo.ttf);
    font-style: italic;
    font-weight: 500;
}

@font-face {
    font-family: YADoUIDNzaE-1;
    src: url(../../public/fonts/edo.ttf);
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: YADoUIDNzaE-1;
    src: url(../../public/fonts/edo.ttf);
    font-style: italic;
    font-weight: 600;
}

@font-face {
    font-family: YADoUIDNzaE-1;
    src: url(../../public/fonts/edo.ttf);
    font-style: normal;
    font-weight: 800;
}

@font-face {
    font-family: YADoUIDNzaE-1;
    src: url(../../public/fonts/edo.ttf);
    font-style: italic;
    font-weight: 800;
}

@font-face {
    font-family: YADoUIDNzaE-1;
    src: url(../../public/fonts/edo.ttf);
    font-style: normal;
    font-weight: 900;
}

@font-face {
    font-family: YADoUIDNzaE-1;
    src: url(../../public/fonts/edo.ttf);
    font-style: italic;
    font-weight: 900;
}

@font-face {
    font-family: YAFdJt8dAY0-1;
    src: url(../../public/fonts/OpenSans-Bold.ttf);
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: YAFdJt8dAY0-1;
    src: url(../../public/fonts/OpenSans-Bold.ttf);
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: YAFdJt8dAY0-1;
    src: url(../../public/fonts/OpenSans-Bold.ttf);
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: YAFdJt8dAY0-1;
    src: url(../../public/fonts/OpenSans-Bold.ttf);
    font-style: italic;
    font-weight: 700;
}

@font-face {
    font-family: YAFdJt8dAY0-1;
    src: url(../../public/fonts/OpenSans-Bold.ttf);
    font-style: normal;
    font-weight: 100;
}

@font-face {
    font-family: YAFdJt8dAY0-1;
    src: url(../../public/fonts/OpenSans-Bold.ttf);
    font-style: italic;
    font-weight: 100;
}

@font-face {
    font-family: YAFdJt8dAY0-1;
    src: url(../../public/fonts/OpenSans-Bold.ttf);
    font-style: normal;
    font-weight: 200;
}

@font-face {
    font-family: YAFdJt8dAY0-1;
    src: url(../../public/fonts/OpenSans-Bold.ttf);
    font-style: italic;
    font-weight: 200;
}

@font-face {
    font-family: YAFdJt8dAY0-1;
    src: url(../../public/fonts/OpenSans-Bold.ttf);
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: YAFdJt8dAY0-1;
    src: url(../../public/fonts/OpenSans-Bold.ttf);
    font-style: italic;
    font-weight: 300;
}

@font-face {
    font-family: YAFdJt8dAY0-1;
    src: url(../../public/fonts/OpenSans-Bold.ttf);
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: YAFdJt8dAY0-1;
    src: url(../../public/fonts/OpenSans-Bold.ttf);
    font-style: italic;
    font-weight: 500;
}

@font-face {
    font-family: YAFdJt8dAY0-1;
    src: url(../../public/fonts/OpenSans-Bold.ttf);
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: YAFdJt8dAY0-1;
    src: url(../../public/fonts/OpenSans-Bold.ttf);
    font-style: italic;
    font-weight: 600;
}

@font-face {
    font-family: YAFdJt8dAY0-1;
    src: url(../../public/fonts/OpenSans-Bold.ttf);
    font-style: normal;
    font-weight: 800;
}

@font-face {
    font-family: YAFdJt8dAY0-1;
    src: url(../../public/fonts/OpenSans-Bold.ttf);
    font-style: italic;
    font-weight: 800;
}

@font-face {
    font-family: YAFdJt8dAY0-1;
    src: url(../../public/fonts/OpenSans-Bold.ttf);
    font-style: normal;
    font-weight: 900;
}

@font-face {
    font-family: YAFdJt8dAY0-1;
    src: url(../../public/fonts/OpenSans-Bold.ttf);
    font-style: italic;
    font-weight: 900;
}

@media (max-width: 375px) {
    #GYZxd7sCWpy1B0hJ {
        grid-area: 2 / 2 / 3 / 3;
        position: relative;
    }

    #Hi6tq9QFfXPsMS7O {
        grid-template-columns: 0 356.35032974%;
        left: -128.17516487%;
        grid-template-rows: 0 100%;
    }

    #z2CTA7bWhrcxuzoi {
        font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.52054053em - var(--ffsd)));
    }

    #SVFHtcIRhu21XmP5 {
        font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.52054053em - var(--ffsd)));
    }

    #UeeVj2u0SOLC5eub {
        font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.52054053em - var(--ffsd)));
    }

    #PBTXDmhoLAyLZHra {
        --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.52054053em);
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #oaR6C3SNBVY7jhsE {
        min-width: 91.6rem;
    }

    #nmhQzdnlHo4Ngh6o {
        grid-area: 2 / 2 / 3 / 18;
        position: relative;
    }

    #tw8ztAt2LTbHdszs {
        grid-area: 2 / 2 / 5 / 4;
        position: relative;
    }

    #xoZDYyBabR8XrASa {
        font-size: calc(7.82221333em - var(--ffsd));
    }

    #zUzsJtHjGxLfWj2O {
        --first-font-size: 7.82221333em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #JvrRxijZ9oMwdkNL {
        min-width: 58.6101496rem;
    }

    #b0ZayqVlU57huviE {
        grid-area: 3 / 3 / 4 / 5;
        position: relative;
    }

    #nbcdE1ko1cfCBYgv {
        grid-area: 4 / 5 / 5 / 15;
        grid-template-columns: 0 0 58.47681627rem 0;
        grid-template-rows: 0 minmax(3.64902182rem, max-content) minmax(9.367849rem, max-content) minmax(3.64902182rem, max-content);
    }

    #SDmbetXCdcNiMQ0l {
        grid-area: 4 / 5 / 5 / 15;
        position: relative;
    }

    #awBJa1CduFGW55qK {
        grid-area: 2 / 2 / 5 / 4;
        position: relative;
    }

    #ZtJemiC5CLdDSFhb {
        font-size: calc(7.82221333em - var(--ffsd));
    }

    #CccrPNkLLX1p8wkt {
        --first-font-size: 7.82221333em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #hyVohMOb6Cu6scNF {
        min-width: 58.6101496rem;
    }

    #SLx52A4lNCDD7URU {
        grid-area: 3 / 3 / 4 / 5;
        position: relative;
    }

    #cgD9IXlm7Ju2J8BZ {
        grid-area: 6 / 6 / 7 / 16;
        grid-template-columns: 0 0 58.47681627rem 0;
        grid-template-rows: 0 minmax(3.64902182rem, max-content) minmax(9.367849rem, max-content) minmax(3.64902182rem, max-content);
    }

    #KD4cu3gkWafGaBvn {
        grid-area: 6 / 6 / 7 / 16;
        position: relative;
    }

    #NgiJlB4c33SbfSuc {
        stroke-width: calc(100rem * 0.0 / 375.0);
    }

    #VVgsv7JECgKIBxCA {
        grid-area: 8 / 4 / 13 / 8;
        position: relative;
    }

    #LXHFRPactaZhvcYa {
        grid-area: 9 / 9 / 12 / 12;
        position: relative;
    }

    #TjWvPjmdU6HawWdg {
        grid-area: 10 / 13 / 11 / 17;
        position: relative;
    }

    #ztROjQYjqfL44BKH {
        grid-area: 14 / 7 / 16 / 10;
        position: relative;
    }

    #FJLC1Qdi0rtkJld5 {
        grid-area: 15 / 11 / 17 / 14;
        position: relative;
    }

    #pVMtNSiLt9r47pBJ {
        grid-area: 2 / 3 / 6 / 4;
        position: relative;
    }

    #mMzMMeDtZZ360CkH {
        grid-area: 3 / 5 / 5 / 6;
        position: relative;
    }

    #M0RZTwr15ZN1amDl {
        grid-area: 5 / 2 / 7 / 11;
        position: relative;
        clip-path: polygon(calc(2.22145295% + 0%) calc((0 * 91.46666667rem) + -465.06085063%), calc(100% - (2.22145295% + 0%)) calc((0 * 91.46666667rem) + -465.06085063%), calc(100% - (2.22145295% + 0%)) calc(100% - ((0 * 91.46666667rem) + -416.35009448%)), calc(2.22145295% + 0%) calc(100% - ((0 * 91.46666667rem) + -416.35009448%)));
        margin-left: -2.32473891%;
        margin-right: -2.32473891%;
        margin-bottom: 0%;
        margin-top: 0%;
    }

    #UYhof6XFdoTeePz2 {
        grid-area: 2 / 4 / 8 / 5;
        position: relative;
    }

    #wCpLsvUbgKcrw74n {
        grid-area: 3 / 6 / 9 / 7;
        position: relative;
    }

    #Cg3Sy1q5bFb2mkZZ {
        grid-area: 4 / 8 / 10 / 9;
        position: relative;
    }

    #d3YfJLOOxLv53XlB {
        font-size: calc(13.95792574em - var(--ffsd));
    }

    #Z03aveSxG7y4bp5y {
        --first-font-size: 13.95792574em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #AxVYXKxNUfQWWpXN {
        min-width: 79.23832351rem;
    }

    #MAFrnQO60Cvo7r8P {
        grid-area: 6 / 3 / 11 / 10;
        position: relative;
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: -4.08162537%;
        margin-top: 0%;
    }

    #cpXybmKBpVREDHwu {
        grid-template-columns: 0 5.56900025rem 13.57448319rem 7.39768829rem 14.98248299rem 12.01005928rem 9.50631439rem 9.05562244rem 12.57833961rem 6.79267623rem;
        grid-template-rows: 0 minmax(4.0009135rem, max-content) minmax(1.65743957rem, max-content) minmax(4.53626578rem, max-content) minmax(0.69585835rem, max-content) minmax(2.05607409rem, max-content) minmax(0.66123653rem, max-content) minmax(0.67810372rem, max-content) 0 minmax(10.11833304rem, max-content);
    }

    #qMnoabXHnVjwInRS {
        grid-area: 18 / 3 / 19 / 19;
        grid-template-columns: 0 23.35583479rem 25.81796431rem 0 16.77106901rem 25.52179855rem;
        grid-template-rows: 0 minmax(3.86113253rem, max-content) minmax(3.96833323rem, max-content) minmax(14.28589154rem, max-content) minmax(4.86028036rem, max-content) minmax(5.25805268rem, max-content);
    }

    #t98KcVhx5k6ZiyUi {
        grid-area: 18 / 3 / 19 / 19;
        position: relative;
    }

    #o2vct82pnNq76JpZ {
        grid-template-columns: 4.26666667rem 0 13.57592357rem 2.91900163rem 0 9.11997372rem 5.29633886rem 6.4rem 5.22209556rem 6.4rem 5.63897535rem 6.4rem 4.87945907rem 9.11997372rem 0 2.91900163rem 13.57592357rem 0 4.26666667rem;
        grid-template-rows: minmax(6.54979394rem, max-content) minmax(26.03513rem, max-content) minmax(6.4rem, max-content) minmax(16.66589264rem, max-content) minmax(6.4rem, max-content) minmax(16.66589264rem, max-content) minmax(6.4rem, max-content) minmax(0.03712165rem, max-content) minmax(0.17131824rem, max-content) minmax(16.91843441rem, max-content) minmax(0.17131824rem, max-content) minmax(0.03712165rem, max-content) minmax(6.4rem, max-content) 0 minmax(16.91843441rem, max-content) 0 minmax(42.34508554rem, max-content) minmax(32.23369034rem, max-content);
    }

    #HCoMi8okURzQ2FUD {
        min-height: calc(39.11028813 * var(--1vh, 1vh));
    }

    #NE1rf1d3Ap7ric9U {
        grid-area: 2 / 2 / 4 / 4;
        position: relative;
    }

    #GfbzzPPRhlr71xg0 {
        grid-area: 3 / 3 / 5 / 5;
        position: relative;
    }

    #SseCKOv4DDk4zJ72 {
        grid-template-columns: 0 0 371.85555556% 0;
        left: -135.92777778%;
        grid-template-rows: 0 0 100% 0;
    }

    #iHRuf8jfVivYS4vk {
        grid-area: 2 / 2 / 3 / 3;
        position: relative;
    }

    #F7EsBrbFEmUGSPX5 {
        grid-area: 2 / 3 / 3 / 7;
        grid-template-columns: 0 64.32941095rem;
        grid-template-rows: 0 minmax(131.71446891rem, max-content);
    }

    #MsIJpjnmK0SqWAKR {
        grid-area: 2 / 3 / 3 / 7;
        position: relative;
    }

    #cxSaOV2oLrgXay0Z {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #qlZJ4US1Ckbg7TwL {
        font-size: calc(2.22137185em - var(--ffsd));
    }

    #MyUU1BEJsxk1dzCr {
        font-size: calc(2.22137185em - var(--ffsd));
    }

    #ag44sLAMqYnK4PQv {
        min-width: 20.33753339rem;
    }

    #FkZ9L6qQUTZB1uA6 {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #uTYNBfP5D8x8iCaD {
        grid-template-columns: 0 0.36338489rem 20.20420005rem 0.36338489rem;
        grid-template-rows: 0 minmax(0.36338489rem, max-content) minmax(20.20420005rem, max-content) minmax(0.36338489rem, max-content);
    }

    #e9xue4IC3pL4VPS2 {
        grid-template-columns: 0 20.93096984rem;
        grid-template-rows: 0 minmax(20.93096984rem, max-content);
    }

    #iXhJDRsO9EVbiecL {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #P0qQOhQwSkp7aC1Z {
        font-size: calc(2.22137185em - var(--ffsd));
    }

    #vd9F9gCezZB28BGW {
        font-size: calc(2.22137185em - var(--ffsd));
    }

    #kt7etc92XMD01Nae {
        min-width: 20.33753339rem;
    }

    #ndV4vajM7C15muWi {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #t3zIVKyazDNzzrFn {
        grid-template-columns: 0 0.36338489rem 20.20420005rem 0.36338489rem;
        grid-template-rows: 0 minmax(0.36338489rem, max-content) minmax(20.20420005rem, max-content) minmax(0.36338489rem, max-content);
    }

    #j47hG6rZtGEWAq6o {
        grid-template-columns: 0 20.93096984rem;
        grid-template-rows: 0 minmax(20.93096984rem, max-content);
    }

    #KyBDA8zW131ZPJbx {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #IWo9DmSAzsqnjsSp {
        font-size: calc(2.22137185em - var(--ffsd));
    }

    #HGOS3zqg7FCPl4im {
        font-size: calc(2.22137185em - var(--ffsd));
    }

    #SH7FuVbaZiF0qfJa {
        font-size: calc(2.22137185em - var(--ffsd));
    }

    #J6P1aGpJHch6VSsa {
        min-width: 20.33753339rem;
    }

    #WSilaAiSGbS4cjjg {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #oDpjAAGpD56VX2lY {
        grid-template-columns: 0 0.36338489rem 20.20420005rem 0.36338489rem;
        grid-template-rows: 0 minmax(0.36338489rem, max-content) minmax(20.20420005rem, max-content) minmax(0.36338489rem, max-content);
    }

    #s0oxYsLsEQx9iQnD {
        grid-template-columns: 0 20.93096984rem;
        grid-template-rows: 0 minmax(20.93096984rem, max-content);
    }

    #efp5NX8ELL6cb0m5 {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #MyT9Ep1lVyfTG2y1 {
        font-size: calc(2.22137185em - var(--ffsd));
    }

    #PDnvbnw9qDjr9qmk {
        font-size: calc(2.22137185em - var(--ffsd));
    }

    #qJJQv9HXmbrwyJx5 {
        font-size: calc(2.22137185em - var(--ffsd));
    }

    #Lgwue5AFgm7uOdXR {
        min-width: 20.33753339rem;
    }

    #TVzgVXnsETi1JwP8 {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #vCFLNiy5CSxYYczU {
        grid-template-columns: 0 0.36338489rem 20.20420005rem 0.36338489rem;
        grid-template-rows: 0 minmax(0.36338489rem, max-content) minmax(20.20420005rem, max-content) minmax(0.36338489rem, max-content);
    }

    #GuD5Uz86W29AO2PF {
        grid-template-columns: 0 20.93096984rem;
        grid-template-rows: 0 minmax(20.93096984rem, max-content);
    }

    #FCEonenHNKteaOTo {
        grid-area: 4 / 2 / 8 / 5;
        grid-template-columns: 0 0 20.93096984rem 0 3.58639614rem 0 20.93096984rem 0;
        grid-template-rows: 0 0 minmax(20.93096984rem, max-content) 0 minmax(2.18107338rem, max-content) 0 minmax(20.93096984rem, max-content) 0;
    }

    #ZoIMLEsxWCvcHkpf {
        grid-area: 4 / 2 / 8 / 5;
        position: relative;
    }

    #ERjsuCExaWs4OxTh {
        font-size: calc(2.22137185em - var(--ffsd));
    }

    #tdq1UtVKTCg5B62K {
        --first-font-size: 2.22137185em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #S8JPBsyUkgDGctX0 {
        min-width: 20.08046697rem;
    }

    #n97crLUJTMUhWzwE {
        grid-area: 5 / 6 / 6 / 8;
        position: relative;
    }

    #I0meeBGXz1lEo51F {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #Pt2SasUpq89WC7LY {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #KoKSbxUT4u8DRTFN {
        grid-area: 7 / 4 / 9 / 9;
        grid-template-columns: 0 6.58448273rem 40.03450299rem 1.18612545rem;
        grid-template-rows: 0 minmax(10.72848247rem, max-content) minmax(22.46936481rem, max-content) minmax(14.6072639rem, max-content);
    }

    #FXourizORhVFYUTf {
        grid-area: 7 / 4 / 9 / 9;
        position: relative;
    }

    #wXUfe99v8Az7ZkeL {
        grid-template-columns: 4.26666667rem 13.56862786rem 30.09292763rem 1.78678033rem 13.93183686rem 18.51786613rem 1.4292675rem 12.13936036rem 4.26666667rem;
        grid-template-rows: minmax(7.85357129rem, max-content) minmax(131.71446891rem, max-content) minmax(6.4rem, max-content) minmax(0.77745094rem, max-content) minmax(2.64307775rem, max-content) minmax(4.01941531rem, max-content) minmax(36.60306906rem, max-content) minmax(11.20204211rem, max-content) minmax(7.85357129rem, max-content);
    }

    #PB190VfyxhN1g57H {
        min-height: calc(40.81202319 * var(--1vh, 1vh));
    }

    #S24Qvk6Uub2QI1uE {
        grid-area: 2 / 2 / 3 / 3;
        position: relative;
    }

    #JAVZGggq0qfvXY8P {
        grid-template-columns: 0 100%;
        grid-template-rows: 0 100%;
    }

    #LoY9LNFKynqI28ld {
        grid-area: 2 / 2 / 5 / 4;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: -4.26666667rem;
        right: -4.26666667rem;
    }

    #cRBH4RYtElSYnavo {
        font-size: calc(7.60231293em - var(--ffsd));
    }

    #aVxuqBSp7Usil9CF {
        --first-font-size: 7.60231293em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #QQbhaOYyrM3mKnNu {
        min-width: 91.6rem;
    }

    #MZinALDTUIQ7TrGA {
        grid-area: 3 / 3 / 4 / 5;
        position: relative;
    }

    #JxjkhhxnzqlZg7HH {
        grid-template-columns: 0 0 91.46666667rem 0;
        grid-template-rows: 0 minmax(0.19010686rem, max-content) minmax(9.05835254rem, max-content) minmax(0.21030151rem, max-content);
    }

    #SauakXt7erBn7Pat {
        grid-area: 2 / 2 / 4 / 6;
        position: relative;
    }

    #hslfahugOP8kjSZc {
        font-size: calc(16.47296301em - var(--ffsd));
    }

    #DSVTPMiDe3tIX7Pr {
        --first-font-size: 16.47296301em;
        --last-font-size: var(--first-font-size);
        transform: translateX(calc(var(--first-font-size) * -0.015));
        margin-top: calc(var(--first-font-size) * 0.195);
        margin-bottom: calc(var(--last-font-size) * 0.195);
    }

    #GGOWhQPOd9S8Lb4i {
        min-width: 60.08159407rem;
    }

    #jRGK6ZndkgI1U1Pb {
        grid-area: 2 / 2 / 5 / 7;
        position: relative;
        margin-left: 0%;
        margin-right: -35.41958954%;
        margin-bottom: 0%;
        margin-top: 0%;
    }

    #doNvn665yJOENovb {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #V020GxfFggxupthb {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #RU7pATwEjzj975w2 {
        grid-template-columns: 0 1.01783761rem 16.84739152rem 1.01783761rem;
        grid-template-rows: 0 minmax(1.77206096rem, max-content) minmax(35.19037394rem, max-content) minmax(1.77206096rem, max-content);
    }

    #VrttusACrjaForDu {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
        clip-path: polygon(calc(0% + 0%) calc((0 * 35.23543263rem) + 0%), calc(100% - (0% + 0%)) calc((0 * 35.23543263rem) + 0%), calc(100% - (0% + 0%)) calc(100% - ((0.19265732 * 35.23543263rem) + 0%)), calc(0% + 0%) calc(100% - ((0.19265732 * 35.23543263rem) + 0%)));
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: -19.26573186%;
        margin-top: 0%;
    }

    #KnzuTsgySnwjtOWS {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #QU1gJxnJzUoahVQn {
        grid-template-columns: 0 13.66237896rem 7.91067472rem 13.66237896rem;
        grid-template-rows: 0 minmax(21.19122878rem, max-content) minmax(5.01339011rem, max-content) minmax(2.24244977rem, max-content);
    }

    #CR0i87UxQ2qVrDDe {
        grid-template-columns: 0 1.1118872rem 29.04144629rem 6.19398634rem 12.68908039rem 10.91186051rem;
        grid-template-rows: 0 minmax(1.09051797rem, max-content) minmax(12.94630117rem, max-content) minmax(5.66693827rem, max-content) minmax(20.12125643rem, max-content) minmax(2.65887397rem, max-content);
    }

    #UHdk1SzIMxXB9lZe {
        grid-area: 3 / 4 / 6 / 7;
        position: relative;
    }

    #qMM0li9OTe0DAyj9 {
        grid-area: 2 / 4 / 7 / 5;
        position: relative;
        clip-path: polygon(calc(0% + -10.51530523%) calc((0 * 25.29558902rem) + -7.32698905%), calc(100% - (0% + -10.51530523%)) calc((0 * 25.29558902rem) + -7.32698905%), calc(100% - (0% + -10.51530523%)) calc(100% - ((0.02961019 * 25.29558902rem) + 0%)), calc(0% + -10.51530523%) calc(100% - ((0.02961019 * 25.29558902rem) + 0%)));
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: -2.96101879%;
        margin-top: 0%;
    }

    #WvmBdZHiHvYfvHv1 {
        font-size: calc(3.82739358em - var(--ffsd));
    }

    #Pj3AsevLKx7zJbBd {
        --first-font-size: 3.82739358em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * 0.165);
        margin-bottom: calc(var(--last-font-size) * 0.165);
    }

    #kbpPhOS5kuMJcYxo {
        min-width: 31.53136432rem;
    }

    #JfXiq7lO7DArAUg2 {
        grid-area: 3 / 2 / 4 / 6;
        position: relative;
        margin-left: -9.54307356%;
        margin-right: 0%;
        margin-bottom: 0%;
        margin-top: 0%;
    }

    #J1ouP3qekHrm5kFp {
        font-size: calc(7.00561164em - var(--ffsd));
    }

    #ypA7yTvnsh3xbp1K {
        --first-font-size: 7.00561164em;
        --last-font-size: var(--first-font-size);
        transform: translateX(calc(var(--first-font-size) * -0.015));
        margin-top: calc(var(--first-font-size) * 0.195);
        margin-bottom: calc(var(--last-font-size) * 0.195);
    }

    #c8sBZODSCWAMe4JO {
        min-width: 34.18121013rem;
    }

    #yLLgNydVkdhXrszN {
        grid-area: 5 / 3 / 6 / 7;
        position: relative;
        margin-left: -1.40111726%;
        margin-right: 0%;
        margin-bottom: 0%;
        margin-top: 0%;
    }

    #JPTcfYJwQsLAkMxI {
        grid-template-columns: 0 0 4.67479571rem 25.29558902rem 1.42764626rem 2.6498458rem 0.48335377rem;
        grid-template-rows: minmax(2.1515549rem, max-content) minmax(4.58822344rem, max-content) minmax(4.5878884rem, max-content) minmax(0.29295555rem, max-content) minmax(8.37960191rem, max-content) minmax(11.51612001rem, max-content);
    }

    #XxfMmJ36fQYalmJK {
        grid-area: 5 / 3 / 7 / 5;
        position: relative;
    }

    #UoDqrptOvPSXfdM2 {
        grid-template-columns: 4.26666667rem 0 31.51840593rem 3.01282463rem 56.93543611rem 0 4.26666667rem;
        grid-template-rows: minmax(0.00221903rem, max-content) minmax(8.94044519rem, max-content) minmax(0.51831571rem, max-content) minmax(10.44922788rem, max-content) minmax(31.51634421rem, max-content) 0;
    }

    #VUhJu2YPnTPCHjhQ {
        min-height: calc(10.03900654 * var(--1vh, 1vh));
    }

    #rvy4A4iR6YPIxqPA {
        grid-area: 2 / 2 / 3 / 3;
        position: relative;
    }

    #pn1x7c4gKltz9YH1 {
        grid-template-columns: 0 100%;
        grid-template-rows: 0 100%;
    }

    #QoiLM6rQnYVKJt2S {
        font-size: calc(15.78087839em - var(--ffsd));
    }

    #kfY4PEYtfPsrzlb0 {
        --first-font-size: 15.78087839em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #LrzmuiLv5jbJD7hQ {
        min-width: 91.6rem;
    }

    #CP9ZE9qacZKht97w {
        grid-area: 2 / 2 / 5 / 11;
        position: relative;
    }

    #mj8eCSR8RIfPXM4F {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
        clip-path: polygon(calc(0% + 0%) calc((0 * 41.3433565rem) + 0%), calc(100% - (0% + 0%)) calc((0 * 41.3433565rem) + 0%), calc(100% - (0% + 0%)) calc(100% - ((0.06934369 * 41.3433565rem) + 0%)), calc(0% + 0%) calc(100% - ((0.06934369 * 41.3433565rem) + 0%)));
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: -6.93436917%;
        margin-top: 0%;
    }

    #sWzDWozhw3Qs7Zro {
        font-size: calc(1.96414288em - var(--ffsd));
    }

    #DzMwhIsGv7XkxBPS {
        font-size: calc(1.96414288em - var(--ffsd));
    }

    #fVE9QXf20wytuPgu {
        --first-font-size: 1.96414288em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #qi7tbXIBbScQrU0U {
        min-width: 5.50055515rem;
    }

    #ujNdQWpgaVj51duH {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #ku08T3jEjjkQDIib {
        grid-template-columns: 0 17.47181562rem 5.36722182rem 18.50431906rem;
        grid-template-rows: 0 minmax(1.22781228rem, max-content) minmax(5.09732668rem, max-content) minmax(27.44850977rem, max-content);
    }

    #qjHVykhdePIeTmhj {
        grid-area: 3 / 4 / 11 / 8;
        position: relative;
    }

    #dLV7M2EhCd6gQJWK {
        font-size: calc(1.96414288em - var(--ffsd));
    }

    #GhnBxxV2utZrbKxD {
        --first-font-size: 1.96414288em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #JBem8AAHgF9wUp50 {
        min-width: 11.19211201rem;
    }

    #POftSeqPB2GG9unP {
        grid-area: 4 / 6 / 6 / 9;
        position: relative;
    }

    #rcHahNQYhBrtDzMH {
        font-size: calc(3.34585em - var(--ffsd));
    }

    #NCh1C1hvuJQfho2a {
        font-size: calc(3.34585em - var(--ffsd));
    }

    #zho803dpI9iZmycT {
        --first-font-size: 3.34585em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #oNCDGrPyYKXhgfie {
        min-width: 15.52292436rem;
    }

    #LswDIwdGmH8V1FQL {
        grid-area: 7 / 7 / 8 / 10;
        position: relative;
    }

    #RDigOIja40TpB9iR {
        font-size: calc(4.31304615em - var(--ffsd));
    }

    #yRDAiXVSmolxGkcd {
        font-size: calc(4.31304615em - var(--ffsd));
    }

    #iQSRtE5gu17SF3ot {
        --first-font-size: 4.31304615em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #C7FE1Rrh6OY8XLfv {
        min-width: 14.77627794rem;
    }

    #W8vidrrjnlLMkvnZ {
        grid-area: 9 / 3 / 10 / 5;
        position: relative;
    }

    #Twu2Ek3UMoef88oU {
        grid-template-columns: 4.26666667rem 16.9098587rem 5.40537186rem 9.23757274rem 27.35159165rem 4.32055383rem 0.43363828rem 6.30458657rem 8.65136618rem 12.85212686rem 4.26666667rem;
        grid-template-rows: 0 minmax(17.65124146rem, max-content) minmax(0.84318439rem, max-content) minmax(0.38462789rem, max-content) minmax(1.96762116rem, max-content) minmax(3.17668235rem, max-content) minmax(8.58173862rem, max-content) minmax(0.42552989rem, max-content) minmax(11.15049456rem, max-content) minmax(7.24376987rem, max-content);
    }

    #trW5gSnAxYuqj39c {
        min-height: calc(10.03868213 * var(--1vh, 1vh));
    }
}

@media (min-width: 375.05px) and (max-width: 480px) {
    #GYZxd7sCWpy1B0hJ {
        grid-area: 2 / 2 / 3 / 3;
        position: relative;
    }

    #Hi6tq9QFfXPsMS7O {
        grid-template-columns: 0 228.13217076%;
        left: -64.06608538%;
        grid-template-rows: 0 100%;
    }

    #z2CTA7bWhrcxuzoi {
        font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.09417229em - var(--ffsd)));
    }

    #SVFHtcIRhu21XmP5 {
        font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.09417229em - var(--ffsd)));
    }

    #UeeVj2u0SOLC5eub {
        font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.09417229em - var(--ffsd)));
    }

    #PBTXDmhoLAyLZHra {
        --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.09417229em);
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #oaR6C3SNBVY7jhsE {
        min-width: 27.86006548rem;
    }

    #nmhQzdnlHo4Ngh6o {
        grid-area: 2 / 3 / 4 / 8;
        position: relative;
    }

    #tw8ztAt2LTbHdszs {
        grid-area: 2 / 2 / 5 / 4;
        position: relative;
    }

    #xoZDYyBabR8XrASa {
        font-size: calc(6.11110417em - var(--ffsd));
    }

    #zUzsJtHjGxLfWj2O {
        --first-font-size: 6.11110417em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #JvrRxijZ9oMwdkNL {
        min-width: 45.78917938rem;
    }

    #b0ZayqVlU57huviE {
        grid-area: 3 / 3 / 4 / 5;
        position: relative;
    }

    #nbcdE1ko1cfCBYgv {
        grid-area: 3 / 10 / 5 / 18;
        grid-template-columns: 0 0 45.68501271rem 0;
        grid-template-rows: 0 minmax(2.8507983rem, max-content) minmax(7.31863203rem, max-content) minmax(2.8507983rem, max-content);
    }

    #SDmbetXCdcNiMQ0l {
        grid-area: 3 / 10 / 5 / 18;
        position: relative;
    }

    #awBJa1CduFGW55qK {
        grid-area: 2 / 2 / 5 / 4;
        position: relative;
    }

    #ZtJemiC5CLdDSFhb {
        font-size: calc(6.11110417em - var(--ffsd));
    }

    #CccrPNkLLX1p8wkt {
        --first-font-size: 6.11110417em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #hyVohMOb6Cu6scNF {
        min-width: 45.78917938rem;
    }

    #SLx52A4lNCDD7URU {
        grid-area: 3 / 3 / 4 / 5;
        position: relative;
    }

    #cgD9IXlm7Ju2J8BZ {
        grid-area: 6 / 6 / 7 / 15;
        grid-template-columns: 0 0 45.68501271rem 0;
        grid-template-rows: 0 minmax(2.8507983rem, max-content) minmax(7.31863203rem, max-content) minmax(2.8507983rem, max-content);
    }

    #KD4cu3gkWafGaBvn {
        grid-area: 6 / 6 / 7 / 15;
        position: relative;
    }

    #NgiJlB4c33SbfSuc {
        stroke-width: calc(100rem * 0.0 / 480.0);
    }

    #VVgsv7JECgKIBxCA {
        grid-area: 8 / 4 / 17 / 5;
        position: relative;
    }

    #LXHFRPactaZhvcYa {
        grid-area: 9 / 7 / 16 / 9;
        position: relative;
    }

    #TjWvPjmdU6HawWdg {
        grid-area: 10 / 11 / 13 / 12;
        position: relative;
    }

    #ztROjQYjqfL44BKH {
        grid-area: 11 / 13 / 14 / 14;
        position: relative;
    }

    #FJLC1Qdi0rtkJld5 {
        grid-area: 12 / 16 / 15 / 17;
        position: relative;
    }

    #pVMtNSiLt9r47pBJ {
        grid-area: 2 / 3 / 6 / 4;
        position: relative;
    }

    #mMzMMeDtZZ360CkH {
        grid-area: 3 / 5 / 5 / 6;
        position: relative;
    }

    #M0RZTwr15ZN1amDl {
        grid-area: 5 / 2 / 7 / 11;
        position: relative;
        clip-path: polygon(calc(2.22145295% + 0%) calc((0 * 93.33333333rem) + -465.06085063%), calc(100% - (2.22145295% + 0%)) calc((0 * 93.33333333rem) + -465.06085063%), calc(100% - (2.22145295% + 0%)) calc(100% - ((0 * 93.33333333rem) + -416.35009448%)), calc(2.22145295% + 0%) calc(100% - ((0 * 93.33333333rem) + -416.35009448%)));
        margin-left: -2.32473891%;
        margin-right: -2.32473891%;
        margin-bottom: 0%;
        margin-top: 0%;
    }

    #UYhof6XFdoTeePz2 {
        grid-area: 2 / 4 / 8 / 5;
        position: relative;
    }

    #wCpLsvUbgKcrw74n {
        grid-area: 3 / 6 / 9 / 7;
        position: relative;
    }

    #Cg3Sy1q5bFb2mkZZ {
        grid-area: 4 / 8 / 10 / 9;
        position: relative;
    }

    #d3YfJLOOxLv53XlB {
        font-size: calc(14.24278137em - var(--ffsd));
    }

    #Z03aveSxG7y4bp5y {
        --first-font-size: 14.24278137em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #AxVYXKxNUfQWWpXN {
        min-width: 80.8235444rem;
    }

    #MAFrnQO60Cvo7r8P {
        grid-area: 6 / 3 / 11 / 10;
        position: relative;
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: -4.08162537%;
        margin-top: 0%;
    }

    #cpXybmKBpVREDHwu {
        grid-template-columns: 0 5.68265332rem 13.85151346rem 7.54866152rem 15.28824795rem 12.25516253rem 9.70032081rem 9.24043106rem 12.83504042rem 6.93130228rem;
        grid-template-rows: 0 minmax(4.0825648rem, max-content) minmax(1.69126487rem, max-content) minmax(4.62884263rem, max-content) minmax(0.71005954rem, max-content) minmax(2.09803478rem, max-content) minmax(0.67473115rem, max-content) minmax(0.69194258rem, max-content) 0 minmax(10.32482963rem, max-content);
    }

    #qMnoabXHnVjwInRS {
        grid-area: 18 / 2 / 19 / 19;
        grid-template-columns: 0 23.83248448rem 26.34486154rem 0 17.11333572rem 26.04265158rem;
        grid-template-rows: 0 minmax(3.93993115rem, max-content) minmax(4.04931962rem, max-content) minmax(14.57744035rem, max-content) minmax(4.95946976rem, max-content) minmax(5.36535988rem, max-content);
    }

    #t98KcVhx5k6ZiyUi {
        grid-area: 18 / 2 / 19 / 19;
        position: relative;
    }

    #o2vct82pnNq76JpZ {
        grid-template-columns: 3.33333333rem 7.4462109rem 2.50354866rem 13.54321422rem 0.33118653rem 1.25877749rem 10.11917192rem 3.36603972rem 1.63396028rem 0.03270639rem 13.21752689rem 1.66666667rem 13.21752689rem 1.17263647rem 0.65687386rem 13.21752689rem 2.50354866rem 7.4462109rem 3.33333333rem;
        grid-template-rows: minmax(5.11702652rem, max-content) minmax(7.58740145rem, max-content) minmax(12.75254386rem, max-content) minmax(0.26768476rem, max-content) minmax(5rem, max-content) minmax(13.02022862rem, max-content) minmax(5rem, max-content) minmax(0.02900129rem, max-content) minmax(0.13384238rem, max-content) 0 0 minmax(13.21752689rem, max-content) 0 0 minmax(0.13384238rem, max-content) minmax(0.02900129rem, max-content) minmax(33.08209808rem, max-content) minmax(32.89152075rem, max-content);
    }

    #HCoMi8okURzQ2FUD {
        min-height: calc(31.73595171 * var(--1vh, 1vh));
    }

    #NE1rf1d3Ap7ric9U {
        grid-area: 2 / 2 / 4 / 4;
        position: relative;
    }

    #GfbzzPPRhlr71xg0 {
        grid-area: 3 / 3 / 5 / 5;
        position: relative;
    }

    #SseCKOv4DDk4zJ72 {
        grid-template-columns: 0 0 304.04367666% 0;
        left: -102.02183833%;
        grid-template-rows: 0 0 100% 0;
    }

    #iHRuf8jfVivYS4vk {
        grid-area: 2 / 2 / 3 / 3;
        position: relative;
    }

    #F7EsBrbFEmUGSPX5 {
        grid-area: 2 / 3 / 3 / 7;
        grid-template-columns: 0 50.2573523rem;
        grid-template-rows: 0 minmax(102.90192884rem, max-content);
    }

    #MsIJpjnmK0SqWAKR {
        grid-area: 2 / 3 / 3 / 7;
        position: relative;
    }

    #cxSaOV2oLrgXay0Z {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #qlZJ4US1Ckbg7TwL {
        font-size: calc(2.26670597em - var(--ffsd));
    }

    #MyUU1BEJsxk1dzCr {
        font-size: calc(2.26670597em - var(--ffsd));
    }

    #ag44sLAMqYnK4PQv {
        min-width: 20.72069733rem;
    }

    #FkZ9L6qQUTZB1uA6 {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #uTYNBfP5D8x8iCaD {
        grid-template-columns: 0 0.37080091rem 20.61653067rem 0.37080091rem;
        grid-template-rows: 0 minmax(0.37080091rem, max-content) minmax(20.61653067rem, max-content) minmax(0.37080091rem, max-content);
    }

    #e9xue4IC3pL4VPS2 {
        grid-template-columns: 0 21.35813249rem;
        grid-template-rows: 0 minmax(21.35813249rem, max-content);
    }

    #iXhJDRsO9EVbiecL {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #P0qQOhQwSkp7aC1Z {
        font-size: calc(2.26670597em - var(--ffsd));
    }

    #vd9F9gCezZB28BGW {
        font-size: calc(2.26670597em - var(--ffsd));
    }

    #kt7etc92XMD01Nae {
        min-width: 20.72069733rem;
    }

    #ndV4vajM7C15muWi {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #t3zIVKyazDNzzrFn {
        grid-template-columns: 0 0.37080091rem 20.61653067rem 0.37080091rem;
        grid-template-rows: 0 minmax(0.37080091rem, max-content) minmax(20.61653067rem, max-content) minmax(0.37080091rem, max-content);
    }

    #j47hG6rZtGEWAq6o {
        grid-template-columns: 0 21.35813249rem;
        grid-template-rows: 0 minmax(21.35813249rem, max-content);
    }

    #KyBDA8zW131ZPJbx {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #IWo9DmSAzsqnjsSp {
        font-size: calc(2.26670597em - var(--ffsd));
    }

    #HGOS3zqg7FCPl4im {
        font-size: calc(2.26670597em - var(--ffsd));
    }

    #SH7FuVbaZiF0qfJa {
        font-size: calc(2.26670597em - var(--ffsd));
    }

    #J6P1aGpJHch6VSsa {
        min-width: 20.72069733rem;
    }

    #WSilaAiSGbS4cjjg {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #oDpjAAGpD56VX2lY {
        grid-template-columns: 0 0.37080091rem 20.61653067rem 0.37080091rem;
        grid-template-rows: 0 minmax(0.37080091rem, max-content) minmax(20.61653067rem, max-content) minmax(0.37080091rem, max-content);
    }

    #s0oxYsLsEQx9iQnD {
        grid-template-columns: 0 21.35813249rem;
        grid-template-rows: 0 minmax(21.35813249rem, max-content);
    }

    #efp5NX8ELL6cb0m5 {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #MyT9Ep1lVyfTG2y1 {
        font-size: calc(2.26670597em - var(--ffsd));
    }

    #PDnvbnw9qDjr9qmk {
        font-size: calc(2.26670597em - var(--ffsd));
    }

    #qJJQv9HXmbrwyJx5 {
        font-size: calc(2.26670597em - var(--ffsd));
    }

    #Lgwue5AFgm7uOdXR {
        min-width: 20.72069733rem;
    }

    #TVzgVXnsETi1JwP8 {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #vCFLNiy5CSxYYczU {
        grid-template-columns: 0 0.37080091rem 20.61653067rem 0.37080091rem;
        grid-template-rows: 0 minmax(0.37080091rem, max-content) minmax(20.61653067rem, max-content) minmax(0.37080091rem, max-content);
    }

    #GuD5Uz86W29AO2PF {
        grid-template-columns: 0 21.35813249rem;
        grid-template-rows: 0 minmax(21.35813249rem, max-content);
    }

    #FCEonenHNKteaOTo {
        grid-area: 4 / 2 / 8 / 5;
        grid-template-columns: 0 0 21.35813249rem 0 3.6595879rem 0 21.35813249rem 0;
        grid-template-rows: 0 0 minmax(21.35813249rem, max-content) 0 minmax(2.22558509rem, max-content) 0 minmax(21.35813249rem, max-content) 0;
    }

    #ZoIMLEsxWCvcHkpf {
        grid-area: 4 / 2 / 8 / 5;
        position: relative;
    }

    #ERjsuCExaWs4OxTh {
        font-size: calc(2.26670597em - var(--ffsd));
    }

    #tdq1UtVKTCg5B62K {
        --first-font-size: 2.26670597em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #S8JPBsyUkgDGctX0 {
        min-width: 20.45838466rem;
    }

    #n97crLUJTMUhWzwE {
        grid-area: 5 / 6 / 6 / 8;
        position: relative;
    }

    #I0meeBGXz1lEo51F {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #Pt2SasUpq89WC7LY {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #KoKSbxUT4u8DRTFN {
        grid-area: 7 / 4 / 9 / 9;
        grid-template-columns: 0 6.71885993rem 40.85153367rem 1.21033209rem;
        grid-template-rows: 0 minmax(10.94743109rem, max-content) minmax(22.92792327rem, max-content) minmax(14.90537133rem, max-content);
    }

    #FXourizORhVFYUTf {
        grid-area: 7 / 4 / 9 / 9;
        position: relative;
    }

    #wXUfe99v8Az7ZkeL {
        grid-template-columns: 3.33333333rem 21.53799052rem 23.01461713rem 1.82324523rem 14.21616006rem 11.20332988rem 9.15088811rem 12.38710241rem 3.33333333rem;
        grid-template-rows: minmax(3.33333333rem, max-content) minmax(102.90192884rem, max-content) minmax(5rem, max-content) minmax(0.79331729rem, max-content) minmax(2.69701811rem, max-content) minmax(4.1014442rem, max-content) minmax(37.35007047rem, max-content) minmax(11.43065522rem, max-content) minmax(3.33333333rem, max-content);
    }

    #PB190VfyxhN1g57H {
        min-height: calc(42.29616282 * var(--1vh, 1vh));
    }

    #S24Qvk6Uub2QI1uE {
        grid-area: 2 / 2 / 3 / 3;
        position: relative;
    }

    #JAVZGggq0qfvXY8P {
        grid-template-columns: 0 100%;
        grid-template-rows: 0 100%;
    }

    #LoY9LNFKynqI28ld {
        grid-area: 2 / 2 / 5 / 4;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: -3.33333333rem;
        right: -3.33333333rem;
    }

    #cRBH4RYtElSYnavo {
        font-size: calc(7.75746218em - var(--ffsd));
    }

    #aVxuqBSp7Usil9CF {
        --first-font-size: 7.75746218em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #QQbhaOYyrM3mKnNu {
        min-width: 93.4375rem;
    }

    #MZinALDTUIQ7TrGA {
        grid-area: 3 / 3 / 4 / 5;
        position: relative;
    }

    #JxjkhhxnzqlZg7HH {
        grid-template-columns: 0 0 93.33333333rem 0;
        grid-template-rows: 0 minmax(0.19398659rem, max-content) minmax(9.24321687rem, max-content) minmax(0.21459338rem, max-content);
    }

    #SauakXt7erBn7Pat {
        grid-area: 2 / 2 / 4 / 6;
        position: relative;
    }

    #hslfahugOP8kjSZc {
        font-size: calc(16.80914592em - var(--ffsd));
    }

    #DSVTPMiDe3tIX7Pr {
        --first-font-size: 16.80914592em;
        --last-font-size: var(--first-font-size);
        transform: translateX(calc(var(--first-font-size) * -0.015));
        margin-top: calc(var(--first-font-size) * 0.195);
        margin-bottom: calc(var(--last-font-size) * 0.195);
    }

    #GGOWhQPOd9S8Lb4i {
        min-width: 61.2758613rem;
    }

    #jRGK6ZndkgI1U1Pb {
        grid-area: 2 / 2 / 5 / 7;
        position: relative;
        margin-left: 0%;
        margin-right: -35.41958954%;
        margin-bottom: 0%;
        margin-top: 0%;
    }

    #doNvn665yJOENovb {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #V020GxfFggxupthb {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #RU7pATwEjzj975w2 {
        grid-template-columns: 0 1.0386098rem 17.19121584rem 1.0386098rem;
        grid-template-rows: 0 minmax(1.80822547rem, max-content) minmax(35.90854483rem, max-content) minmax(1.80822547rem, max-content);
    }

    #VrttusACrjaForDu {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
        clip-path: polygon(calc(0% + 0%) calc((0 * 35.9545231rem) + 0%), calc(100% - (0% + 0%)) calc((0 * 35.9545231rem) + 0%), calc(100% - (0% + 0%)) calc(100% - ((0.19265732 * 35.9545231rem) + 0%)), calc(0% + 0%) calc(100% - ((0.19265732 * 35.9545231rem) + 0%)));
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: -19.26573186%;
        margin-top: 0%;
    }

    #KnzuTsgySnwjtOWS {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #QU1gJxnJzUoahVQn {
        grid-template-columns: 0 13.94120302rem 8.07211707rem 13.94120302rem;
        grid-template-rows: 0 minmax(21.62370284rem, max-content) minmax(5.11570419rem, max-content) minmax(2.28821406rem, max-content);
    }

    #CR0i87UxQ2qVrDDe {
        grid-template-columns: 0 1.13457878rem 29.63412887rem 6.32039423rem 12.94804122rem 11.13455154rem;
        grid-template-rows: 0 minmax(1.11277344rem, max-content) minmax(13.2105114rem, max-content) minmax(5.78259007rem, max-content) minmax(20.53189431rem, max-content) minmax(2.7131367rem, max-content);
    }

    #UHdk1SzIMxXB9lZe {
        grid-area: 3 / 4 / 6 / 7;
        position: relative;
    }

    #qMM0li9OTe0DAyj9 {
        grid-area: 2 / 4 / 7 / 5;
        position: relative;
        clip-path: polygon(calc(0% + -10.51530523%) calc((0 * 25.81182553rem) + -7.32698905%), calc(100% - (0% + -10.51530523%)) calc((0 * 25.81182553rem) + -7.32698905%), calc(100% - (0% + -10.51530523%)) calc(100% - ((0.02961019 * 25.81182553rem) + 0%)), calc(0% + -10.51530523%) calc(100% - ((0.02961019 * 25.81182553rem) + 0%)));
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: -2.96101879%;
        margin-top: 0%;
    }

    #WvmBdZHiHvYfvHv1 {
        font-size: calc(3.90550366em - var(--ffsd));
    }

    #Pj3AsevLKx7zJbBd {
        --first-font-size: 3.90550366em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * 0.165);
        margin-bottom: calc(var(--last-font-size) * 0.165);
    }

    #kbpPhOS5kuMJcYxo {
        min-width: 32.1429738rem;
    }

    #JfXiq7lO7DArAUg2 {
        grid-area: 3 / 2 / 4 / 6;
        position: relative;
        margin-left: -9.54307356%;
        margin-right: 0%;
        margin-bottom: 0%;
        margin-top: 0%;
    }

    #J1ouP3qekHrm5kFp {
        font-size: calc(7.14858331em - var(--ffsd));
    }

    #ypA7yTvnsh3xbp1K {
        --first-font-size: 7.14858331em;
        --last-font-size: var(--first-font-size);
        transform: translateX(calc(var(--first-font-size) * -0.015));
        margin-top: calc(var(--first-font-size) * 0.195);
        margin-bottom: calc(var(--last-font-size) * 0.195);
    }

    #c8sBZODSCWAMe4JO {
        min-width: 34.84689809rem;
    }

    #yLLgNydVkdhXrszN {
        grid-area: 5 / 3 / 6 / 7;
        position: relative;
        margin-left: -1.40111726%;
        margin-right: 0%;
        margin-bottom: 0%;
        margin-top: 0%;
    }

    #JPTcfYJwQsLAkMxI {
        grid-template-columns: 0 0 4.7701997rem 25.81182553rem 1.4567819rem 2.70392429rem 0.49321813rem;
        grid-template-rows: minmax(2.19546418rem, max-content) minmax(4.68186065rem, max-content) minmax(4.68151878rem, max-content) minmax(0.29893424rem, max-content) minmax(8.5506142rem, max-content) minmax(11.75114286rem, max-content);
    }

    #XxfMmJ36fQYalmJK {
        grid-area: 5 / 3 / 7 / 5;
        position: relative;
    }

    #UoDqrptOvPSXfdM2 {
        grid-template-columns: 3.33333333rem 0 32.1616387rem 3.07431085rem 58.09738378rem 0 3.33333333rem;
        grid-template-rows: minmax(0.00173362rem, max-content) minmax(9.12290326rem, max-content) minmax(0.52889358rem, max-content) minmax(10.66247743rem, max-content) minmax(32.15953491rem, max-content) 0;
    }

    #VUhJu2YPnTPCHjhQ {
        min-height: calc(12.98408687 * var(--1vh, 1vh));
    }

    #rvy4A4iR6YPIxqPA {
        grid-area: 2 / 2 / 3 / 3;
        position: relative;
    }

    #pn1x7c4gKltz9YH1 {
        grid-template-columns: 0 100%;
        grid-template-rows: 0 100%;
    }

    #QoiLM6rQnYVKJt2S {
        font-size: calc(16.10293713em - var(--ffsd));
    }

    #kfY4PEYtfPsrzlb0 {
        --first-font-size: 16.10293713em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #LrzmuiLv5jbJD7hQ {
        min-width: 93.4375rem;
    }

    #CP9ZE9qacZKht97w {
        grid-area: 2 / 2 / 5 / 11;
        position: relative;
    }

    #mj8eCSR8RIfPXM4F {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
        clip-path: polygon(calc(0% + 0%) calc((0 * 42.18709847rem) + 0%), calc(100% - (0% + 0%)) calc((0 * 42.18709847rem) + 0%), calc(100% - (0% + 0%)) calc(100% - ((0.06934369 * 42.18709847rem) + 0%)), calc(0% + 0%) calc(100% - ((0.06934369 * 42.18709847rem) + 0%)));
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: -6.93436917%;
        margin-top: 0%;
    }

    #sWzDWozhw3Qs7Zro {
        font-size: calc(2.00422743em - var(--ffsd));
    }

    #DzMwhIsGv7XkxBPS {
        font-size: calc(2.00422743em - var(--ffsd));
    }

    #fVE9QXf20wytuPgu {
        --first-font-size: 2.00422743em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #qi7tbXIBbScQrU0U {
        min-width: 5.58092362rem;
    }

    #ujNdQWpgaVj51duH {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #ku08T3jEjjkQDIib {
        grid-template-columns: 0 17.82838328rem 5.47675695rem 18.88195823rem;
        grid-template-rows: 0 minmax(1.25286967rem, max-content) minmax(5.20135376rem, max-content) minmax(28.00868344rem, max-content);
    }

    #qjHVykhdePIeTmhj {
        grid-area: 3 / 4 / 11 / 8;
        position: relative;
    }

    #dLV7M2EhCd6gQJWK {
        font-size: calc(2.00422743em - var(--ffsd));
    }

    #GhnBxxV2utZrbKxD {
        --first-font-size: 2.00422743em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #JBem8AAHgF9wUp50 {
        min-width: 11.3886347rem;
    }

    #POftSeqPB2GG9unP {
        grid-area: 4 / 6 / 6 / 9;
        position: relative;
    }

    #rcHahNQYhBrtDzMH {
        font-size: calc(3.41413266em - var(--ffsd));
    }

    #NCh1C1hvuJQfho2a {
        font-size: calc(3.41413266em - var(--ffsd));
    }

    #zho803dpI9iZmycT {
        --first-font-size: 3.41413266em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #oNCDGrPyYKXhgfie {
        min-width: 15.80783098rem;
    }

    #LswDIwdGmH8V1FQL {
        grid-area: 7 / 7 / 8 / 10;
        position: relative;
    }

    #RDigOIja40TpB9iR {
        font-size: calc(4.4010675em - var(--ffsd));
    }

    #yRDAiXVSmolxGkcd {
        font-size: calc(4.4010675em - var(--ffsd));
    }

    #iQSRtE5gu17SF3ot {
        --first-font-size: 4.4010675em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #C7FE1Rrh6OY8XLfv {
        min-width: 15.04594687rem;
    }

    #W8vidrrjnlLMkvnZ {
        grid-area: 9 / 3 / 10 / 5;
        position: relative;
    }

    #Twu2Ek3UMoef88oU {
        grid-template-columns: 3.33333333rem 17.25495786rem 5.51568558rem 9.42609463rem 27.9097874rem 4.4087284rem 0.44248804rem 6.4332516rem 8.82792467rem 13.11441516rem 3.33333333rem;
        grid-template-rows: 0 minmax(18.01147088rem, max-content) minmax(0.86039223rem, max-content) minmax(0.39247744rem, max-content) minmax(2.00777669rem, max-content) minmax(3.2415126rem, max-content) minmax(8.75687614rem, max-content) minmax(0.43421418rem, max-content) minmax(11.37805568rem, max-content) minmax(7.39160191rem, max-content);
    }

    #trW5gSnAxYuqj39c {
        min-height: calc(12.9837986 * var(--1vh, 1vh));
    }
}

@media (min-width: 480.05px) and (max-width: 768px) {
    #GYZxd7sCWpy1B0hJ {
        grid-area: 2 / 2 / 3 / 3;
        position: relative;
    }

    #Hi6tq9QFfXPsMS7O {
        grid-template-columns: 0 145.29681222%;
        left: -22.64840611%;
        grid-template-rows: 0 100%;
    }

    #z2CTA7bWhrcxuzoi {
        font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.18385768em - var(--ffsd)));
    }

    #SVFHtcIRhu21XmP5 {
        font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.18385768em - var(--ffsd)));
    }

    #UeeVj2u0SOLC5eub {
        font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.18385768em - var(--ffsd)));
    }

    #PBTXDmhoLAyLZHra {
        --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.18385768em);
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #oaR6C3SNBVY7jhsE {
        min-width: 17.41254093rem;
    }

    #nmhQzdnlHo4Ngh6o {
        grid-area: 2 / 3 / 5 / 4;
        position: relative;
    }

    #tw8ztAt2LTbHdszs {
        grid-area: 2 / 2 / 5 / 4;
        position: relative;
    }

    #xoZDYyBabR8XrASa {
        font-size: calc(3.8194401em - var(--ffsd));
    }

    #zUzsJtHjGxLfWj2O {
        --first-font-size: 3.8194401em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #JvrRxijZ9oMwdkNL {
        min-width: 28.61823711rem;
    }

    #b0ZayqVlU57huviE {
        grid-area: 3 / 3 / 4 / 5;
        position: relative;
    }

    #nbcdE1ko1cfCBYgv {
        grid-area: 3 / 6 / 6 / 13;
        grid-template-columns: 0 0 28.55313294rem 0;
        grid-template-rows: 0 minmax(1.78174893rem, max-content) minmax(4.57414502rem, max-content) minmax(1.78174893rem, max-content);
    }

    #SDmbetXCdcNiMQ0l {
        grid-area: 3 / 6 / 6 / 13;
        position: relative;
    }

    #awBJa1CduFGW55qK {
        grid-area: 2 / 2 / 5 / 4;
        position: relative;
    }

    #ZtJemiC5CLdDSFhb {
        font-size: calc(3.8194401em - var(--ffsd));
    }

    #CccrPNkLLX1p8wkt {
        --first-font-size: 3.8194401em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #hyVohMOb6Cu6scNF {
        min-width: 28.61823711rem;
    }

    #SLx52A4lNCDD7URU {
        grid-area: 3 / 3 / 4 / 5;
        position: relative;
    }

    #cgD9IXlm7Ju2J8BZ {
        grid-area: 4 / 15 / 7 / 18;
        grid-template-columns: 0 0 28.55313294rem 0;
        grid-template-rows: 0 minmax(1.78174893rem, max-content) minmax(4.57414502rem, max-content) minmax(1.78174893rem, max-content);
    }

    #KD4cu3gkWafGaBvn {
        grid-area: 4 / 15 / 7 / 18;
        position: relative;
    }

    #NgiJlB4c33SbfSuc {
        stroke-width: calc(100rem * 0.0 / 768.0);
    }

    #VVgsv7JECgKIBxCA {
        grid-area: 8 / 5 / 17 / 7;
        position: relative;
    }

    #LXHFRPactaZhvcYa {
        grid-area: 9 / 8 / 16 / 9;
        position: relative;
    }

    #TjWvPjmdU6HawWdg {
        grid-area: 10 / 10 / 13 / 11;
        position: relative;
    }

    #ztROjQYjqfL44BKH {
        grid-area: 11 / 12 / 14 / 14;
        position: relative;
    }

    #FJLC1Qdi0rtkJld5 {
        grid-area: 12 / 16 / 15 / 17;
        position: relative;
    }

    #pVMtNSiLt9r47pBJ {
        grid-area: 2 / 3 / 6 / 4;
        position: relative;
    }

    #mMzMMeDtZZ360CkH {
        grid-area: 3 / 5 / 5 / 6;
        position: relative;
    }

    #M0RZTwr15ZN1amDl {
        grid-area: 5 / 2 / 7 / 11;
        position: relative;
        clip-path: polygon(calc(2.22145295% + 0%) calc((0 * 91.66666667rem) + -465.06085063%), calc(100% - (2.22145295% + 0%)) calc((0 * 91.66666667rem) + -465.06085063%), calc(100% - (2.22145295% + 0%)) calc(100% - ((0 * 91.66666667rem) + -416.35009448%)), calc(2.22145295% + 0%) calc(100% - ((0 * 91.66666667rem) + -416.35009448%)));
        margin-left: -2.32473891%;
        margin-right: -2.32473891%;
        margin-bottom: 0%;
        margin-top: 0%;
    }

    #UYhof6XFdoTeePz2 {
        grid-area: 2 / 4 / 8 / 5;
        position: relative;
    }

    #wCpLsvUbgKcrw74n {
        grid-area: 3 / 6 / 9 / 7;
        position: relative;
    }

    #Cg3Sy1q5bFb2mkZZ {
        grid-area: 4 / 8 / 10 / 9;
        position: relative;
    }

    #d3YfJLOOxLv53XlB {
        font-size: calc(13.98844599em - var(--ffsd));
    }

    #Z03aveSxG7y4bp5y {
        --first-font-size: 13.98844599em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #AxVYXKxNUfQWWpXN {
        min-width: 79.34306444rem;
    }

    #MAFrnQO60Cvo7r8P {
        grid-area: 6 / 3 / 11 / 10;
        position: relative;
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: -4.08162537%;
        margin-top: 0%;
    }

    #cpXybmKBpVREDHwu {
        grid-template-columns: 0 5.58117737rem 13.604165rem 7.41386399rem 15.01524352rem 12.03632034rem 9.52710079rem 9.07542336rem 12.60584327rem 6.80752903rem;
        grid-template-rows: 0 minmax(4.00966186rem, max-content) minmax(1.66106371rem, max-content) minmax(4.54618473rem, max-content) minmax(0.6973799rem, max-content) minmax(2.06056988rem, max-content) minmax(0.66268238rem, max-content) minmax(0.67958646rem, max-content) 0 minmax(10.14045767rem, max-content);
    }

    #qMnoabXHnVjwInRS {
        grid-area: 18 / 2 / 19 / 19;
        grid-template-columns: 0 23.4069044rem 25.87441759rem 0 16.80774044rem 25.57760423rem;
        grid-template-rows: 0 minmax(3.86957523rem, max-content) minmax(3.97701035rem, max-content) minmax(14.31712891rem, max-content) minmax(4.8709078rem, max-content) minmax(5.26954988rem, max-content);
    }

    #t98KcVhx5k6ZiyUi {
        grid-area: 18 / 2 / 19 / 19;
        position: relative;
    }

    #o2vct82pnNq76JpZ {
        grid-template-columns: 4.16666667rem 2.81756782rem 17.34743676rem 2.72026181rem 4.83117273rem 3.63333616rem 0.99372751rem 8.42825728rem 1.04166667rem 8.2609543rem 1.04166667rem 5.15352436rem 3.10742994rem 0.9189639rem 0.22448006rem 8.2609543rem 20.06769858rem 2.81756782rem 4.16666667rem;
        grid-template-rows: minmax(3.19814157rem, max-content) minmax(4.7421259rem, max-content) 0 minmax(7.97033992rem, max-content) minmax(0.16730297rem, max-content) 0 minmax(4.16666667rem, max-content) minmax(0.01812581rem, max-content) minmax(0.08365149rem, max-content) 0 0 minmax(8.2609543rem, max-content) 0 0 minmax(0.08365149rem, max-content) minmax(0.01812581rem, max-content) minmax(20.6763113rem, max-content) minmax(32.30417217rem, max-content);
    }

    #HCoMi8okURzQ2FUD {
        min-height: calc(25.77410706 * var(--1vh, 1vh));
    }

    #NE1rf1d3Ap7ric9U {
        grid-area: 2 / 2 / 4 / 4;
        position: relative;
    }

    #GfbzzPPRhlr71xg0 {
        grid-area: 3 / 3 / 5 / 5;
        position: relative;
    }

    #SseCKOv4DDk4zJ72 {
        grid-template-columns: 0 0 235.10062174% 0;
        left: -67.55031087%;
        grid-template-rows: 0 0 100% 0;
    }

    #iHRuf8jfVivYS4vk {
        grid-area: 2 / 2 / 3 / 3;
        position: relative;
    }

    #F7EsBrbFEmUGSPX5 {
        grid-area: 2 / 3 / 3 / 7;
        grid-template-columns: 0 31.41084519rem;
        grid-template-rows: 0 minmax(64.31370552rem, max-content);
    }

    #MsIJpjnmK0SqWAKR {
        grid-area: 2 / 3 / 3 / 7;
        position: relative;
    }

    #cxSaOV2oLrgXay0Z {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #qlZJ4US1Ckbg7TwL {
        font-size: calc(2.22622908em - var(--ffsd));
    }

    #MyUU1BEJsxk1dzCr {
        font-size: calc(2.22622908em - var(--ffsd));
    }

    #ag44sLAMqYnK4PQv {
        min-width: 20.3134825rem;
    }

    #FkZ9L6qQUTZB1uA6 {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #uTYNBfP5D8x8iCaD {
        grid-template-columns: 0 0.36417947rem 20.24837833rem 0.36417947rem;
        grid-template-rows: 0 minmax(0.36417947rem, max-content) minmax(20.24837833rem, max-content) minmax(0.36417947rem, max-content);
    }

    #e9xue4IC3pL4VPS2 {
        grid-template-columns: 0 20.97673727rem;
        grid-template-rows: 0 minmax(20.97673727rem, max-content);
    }

    #iXhJDRsO9EVbiecL {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #P0qQOhQwSkp7aC1Z {
        font-size: calc(2.22622908em - var(--ffsd));
    }

    #vd9F9gCezZB28BGW {
        font-size: calc(2.22622908em - var(--ffsd));
    }

    #kt7etc92XMD01Nae {
        min-width: 20.3134825rem;
    }

    #ndV4vajM7C15muWi {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #t3zIVKyazDNzzrFn {
        grid-template-columns: 0 0.36417947rem 20.24837833rem 0.36417947rem;
        grid-template-rows: 0 minmax(0.36417947rem, max-content) minmax(20.24837833rem, max-content) minmax(0.36417947rem, max-content);
    }

    #j47hG6rZtGEWAq6o {
        grid-template-columns: 0 20.97673727rem;
        grid-template-rows: 0 minmax(20.97673727rem, max-content);
    }

    #KyBDA8zW131ZPJbx {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #IWo9DmSAzsqnjsSp {
        font-size: calc(2.22622908em - var(--ffsd));
    }

    #HGOS3zqg7FCPl4im {
        font-size: calc(2.22622908em - var(--ffsd));
    }

    #SH7FuVbaZiF0qfJa {
        font-size: calc(2.22622908em - var(--ffsd));
    }

    #J6P1aGpJHch6VSsa {
        min-width: 20.3134825rem;
    }

    #WSilaAiSGbS4cjjg {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #oDpjAAGpD56VX2lY {
        grid-template-columns: 0 0.36417947rem 20.24837833rem 0.36417947rem;
        grid-template-rows: 0 minmax(0.36417947rem, max-content) minmax(20.24837833rem, max-content) minmax(0.36417947rem, max-content);
    }

    #s0oxYsLsEQx9iQnD {
        grid-template-columns: 0 20.97673727rem;
        grid-template-rows: 0 minmax(20.97673727rem, max-content);
    }

    #efp5NX8ELL6cb0m5 {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #MyT9Ep1lVyfTG2y1 {
        font-size: calc(2.22622908em - var(--ffsd));
    }

    #PDnvbnw9qDjr9qmk {
        font-size: calc(2.22622908em - var(--ffsd));
    }

    #qJJQv9HXmbrwyJx5 {
        font-size: calc(2.22622908em - var(--ffsd));
    }

    #Lgwue5AFgm7uOdXR {
        min-width: 20.3134825rem;
    }

    #TVzgVXnsETi1JwP8 {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #vCFLNiy5CSxYYczU {
        grid-template-columns: 0 0.36417947rem 20.24837833rem 0.36417947rem;
        grid-template-rows: 0 minmax(0.36417947rem, max-content) minmax(20.24837833rem, max-content) minmax(0.36417947rem, max-content);
    }

    #GuD5Uz86W29AO2PF {
        grid-template-columns: 0 20.97673727rem;
        grid-template-rows: 0 minmax(20.97673727rem, max-content);
    }

    #FCEonenHNKteaOTo {
        grid-area: 4 / 2 / 8 / 5;
        grid-template-columns: 0 0 20.97673727rem 0 3.59423811rem 0 20.97673727rem 0;
        grid-template-rows: 0 0 minmax(20.97673727rem, max-content) 0 minmax(2.18584249rem, max-content) 0 minmax(20.97673727rem, max-content) 0;
    }

    #ZoIMLEsxWCvcHkpf {
        grid-area: 4 / 2 / 8 / 5;
        position: relative;
    }

    #ERjsuCExaWs4OxTh {
        font-size: calc(2.22622908em - var(--ffsd));
    }

    #tdq1UtVKTCg5B62K {
        --first-font-size: 2.22622908em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #S8JPBsyUkgDGctX0 {
        min-width: 20.05585398rem;
    }

    #n97crLUJTMUhWzwE {
        grid-area: 5 / 6 / 6 / 8;
        position: relative;
    }

    #I0meeBGXz1lEo51F {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #Pt2SasUpq89WC7LY {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #KoKSbxUT4u8DRTFN {
        grid-area: 7 / 4 / 9 / 9;
        grid-template-columns: 0 6.59888029rem 40.12204199rem 1.18871902rem;
        grid-template-rows: 0 minmax(10.75194125rem, max-content) minmax(22.51849607rem, max-content) minmax(14.63920398rem, max-content);
    }

    #FXourizORhVFYUTf {
        grid-area: 7 / 4 / 9 / 9;
        position: relative;
    }

    #wXUfe99v8Az7ZkeL {
        grid-template-columns: 4.16666667rem 30.12791074rem 13.62911463rem 1.79068728rem 13.96230006rem 2.02874322rem 17.96200659rem 12.16590415rem 4.16666667rem;
        grid-template-rows: minmax(4.16666667rem, max-content) minmax(64.31370552rem, max-content) minmax(4.16666667rem, max-content) minmax(0.77915091rem, max-content) minmax(2.64885707rem, max-content) minmax(4.02820412rem, max-content) minmax(36.68310493rem, max-content) minmax(11.22653637rem, max-content) minmax(4.16666667rem, max-content);
    }

    #PB190VfyxhN1g57H {
        min-height: calc(41.70434645 * var(--1vh, 1vh));
    }

    #S24Qvk6Uub2QI1uE {
        grid-area: 2 / 2 / 3 / 3;
        position: relative;
    }

    #JAVZGggq0qfvXY8P {
        grid-template-columns: 0 100%;
        grid-template-rows: 0 100%;
    }

    #LoY9LNFKynqI28ld {
        grid-area: 2 / 2 / 5 / 4;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: -4.16666667rem;
        right: -4.16666667rem;
    }

    #cRBH4RYtElSYnavo {
        font-size: calc(7.61893607em - var(--ffsd));
    }

    #aVxuqBSp7Usil9CF {
        --first-font-size: 7.61893607em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #QQbhaOYyrM3mKnNu {
        min-width: 91.73177083rem;
    }

    #MZinALDTUIQ7TrGA {
        grid-area: 3 / 3 / 4 / 5;
        position: relative;
    }

    #JxjkhhxnzqlZg7HH {
        grid-template-columns: 0 0 91.66666667rem 0;
        grid-template-rows: 0 minmax(0.19052254rem, max-content) minmax(9.07815943rem, max-content) minmax(0.21076135rem, max-content);
    }

    #SauakXt7erBn7Pat {
        grid-area: 2 / 2 / 4 / 6;
        position: relative;
    }

    #hslfahugOP8kjSZc {
        font-size: calc(16.5089826em - var(--ffsd));
    }

    #DSVTPMiDe3tIX7Pr {
        --first-font-size: 16.5089826em;
        --last-font-size: var(--first-font-size);
        transform: translateX(calc(var(--first-font-size) * -0.015));
        margin-top: calc(var(--first-font-size) * 0.195);
        margin-bottom: calc(var(--last-font-size) * 0.195);
    }

    #GGOWhQPOd9S8Lb4i {
        min-width: 60.14444711rem;
    }

    #jRGK6ZndkgI1U1Pb {
        grid-area: 2 / 2 / 5 / 7;
        position: relative;
        margin-left: 0%;
        margin-right: -35.41958954%;
        margin-bottom: 0%;
        margin-top: 0%;
    }

    #doNvn665yJOENovb {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #V020GxfFggxupthb {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #RU7pATwEjzj975w2 {
        grid-template-columns: 0 1.0200632rem 16.88422984rem 1.0200632rem;
        grid-template-rows: 0 minmax(1.77593573rem, max-content) minmax(35.26732082rem, max-content) minmax(1.77593573rem, max-content);
    }

    #VrttusACrjaForDu {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
        clip-path: polygon(calc(0% + 0%) calc((0 * 35.31247804rem) + 0%), calc(100% - (0% + 0%)) calc((0 * 35.31247804rem) + 0%), calc(100% - (0% + 0%)) calc(100% - ((0.19265732 * 35.31247804rem) + 0%)), calc(0% + 0%) calc(100% - ((0.19265732 * 35.31247804rem) + 0%)));
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: -19.26573186%;
        margin-top: 0%;
    }

    #KnzuTsgySnwjtOWS {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #QU1gJxnJzUoahVQn {
        grid-template-columns: 0 13.69225296rem 7.92797212rem 13.69225296rem;
        grid-template-rows: 0 minmax(21.23756529rem, max-content) minmax(5.02435233rem, max-content) minmax(2.24735309rem, max-content);
    }

    #CR0i87UxQ2qVrDDe {
        grid-template-columns: 0 1.11431844rem 29.104948rem 6.20753004rem 12.71682619rem 10.93572026rem;
        grid-template-rows: 0 minmax(1.09290249rem, max-content) minmax(12.97460941rem, max-content) minmax(5.67932954rem, max-content) minmax(20.16525334rem, max-content) minmax(2.66468783rem, max-content);
    }

    #UHdk1SzIMxXB9lZe {
        grid-area: 3 / 4 / 6 / 7;
        position: relative;
    }

    #qMM0li9OTe0DAyj9 {
        grid-area: 2 / 4 / 7 / 5;
        position: relative;
        clip-path: polygon(calc(0% + -10.51530523%) calc((0 * 25.35090008rem) + -7.32698905%), calc(100% - (0% + -10.51530523%)) calc((0 * 25.35090008rem) + -7.32698905%), calc(100% - (0% + -10.51530523%)) calc(100% - ((0.02961019 * 25.35090008rem) + 0%)), calc(0% + -10.51530523%) calc(100% - ((0.02961019 * 25.35090008rem) + 0%)));
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: -2.96101879%;
        margin-top: 0%;
    }

    #WvmBdZHiHvYfvHv1 {
        font-size: calc(3.83576252em - var(--ffsd));
    }

    #Pj3AsevLKx7zJbBd {
        --first-font-size: 3.83576252em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * 0.165);
        margin-bottom: calc(var(--last-font-size) * 0.165);
    }

    #kbpPhOS5kuMJcYxo {
        min-width: 31.53178974rem;
    }

    #JfXiq7lO7DArAUg2 {
        grid-area: 3 / 2 / 4 / 6;
        position: relative;
        margin-left: -9.54307356%;
        margin-right: 0%;
        margin-bottom: 0%;
        margin-top: 0%;
    }

    #J1ouP3qekHrm5kFp {
        font-size: calc(7.02093004em - var(--ffsd));
    }

    #ypA7yTvnsh3xbp1K {
        --first-font-size: 7.02093004em;
        --last-font-size: var(--first-font-size);
        transform: translateX(calc(var(--first-font-size) * -0.015));
        margin-top: calc(var(--first-font-size) * 0.195);
        margin-bottom: calc(var(--last-font-size) * 0.195);
    }

    #c8sBZODSCWAMe4JO {
        min-width: 34.18742967rem;
    }

    #yLLgNydVkdhXrszN {
        grid-area: 5 / 3 / 6 / 7;
        position: relative;
        margin-left: -1.40111726%;
        margin-right: 0%;
        margin-bottom: 0%;
        margin-top: 0%;
    }

    #JPTcfYJwQsLAkMxI {
        grid-template-columns: 0 0 4.68501757rem 25.35090008rem 1.43076793rem 2.65563993rem 0.48441066rem;
        grid-template-rows: minmax(2.15625946rem, max-content) minmax(4.59825599rem, max-content) minmax(4.59792023rem, max-content) minmax(0.29359613rem, max-content) minmax(8.39792466rem, max-content) minmax(11.54130103rem, max-content);
    }

    #XxfMmJ36fQYalmJK {
        grid-area: 5 / 3 / 7 / 5;
        position: relative;
    }

    #UoDqrptOvPSXfdM2 {
        grid-template-columns: 4.16666667rem 0 31.58732372rem 3.01941244rem 57.0599305rem 0 4.16666667rem;
        grid-template-rows: minmax(0.00108351rem, max-content) minmax(8.95999427rem, max-content) minmax(0.51944906rem, max-content) minmax(10.47207605rem, max-content) minmax(31.5852575rem, max-content) 0;
    }

    #VUhJu2YPnTPCHjhQ {
        min-height: calc(16.26085608 * var(--1vh, 1vh));
    }

    #rvy4A4iR6YPIxqPA {
        grid-area: 2 / 2 / 3 / 3;
        position: relative;
    }

    #pn1x7c4gKltz9YH1 {
        grid-template-columns: 0 100%;
        grid-template-rows: 0 100%;
    }

    #QoiLM6rQnYVKJt2S {
        font-size: calc(15.81538468em - var(--ffsd));
    }

    #kfY4PEYtfPsrzlb0 {
        --first-font-size: 15.81538468em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #LrzmuiLv5jbJD7hQ {
        min-width: 91.73177083rem;
    }

    #CP9ZE9qacZKht97w {
        grid-area: 2 / 2 / 5 / 11;
        position: relative;
    }

    #mj8eCSR8RIfPXM4F {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
        clip-path: polygon(calc(0% + 0%) calc((0 * 41.43375742rem) + 0%), calc(100% - (0% + 0%)) calc((0 * 41.43375742rem) + 0%), calc(100% - (0% + 0%)) calc(100% - ((0.06934369 * 41.43375742rem) + 0%)), calc(0% + 0%) calc(100% - ((0.06934369 * 41.43375742rem) + 0%)));
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: -6.93436917%;
        margin-top: 0%;
    }

    #sWzDWozhw3Qs7Zro {
        font-size: calc(1.96843765em - var(--ffsd));
    }

    #DzMwhIsGv7XkxBPS {
        font-size: calc(1.96843765em - var(--ffsd));
    }

    #fVE9QXf20wytuPgu {
        --first-font-size: 1.96843765em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #qi7tbXIBbScQrU0U {
        min-width: 5.44406189rem;
    }

    #ujNdQWpgaVj51duH {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #ku08T3jEjjkQDIib {
        grid-template-columns: 0 17.5100193rem 5.37895772rem 18.5447804rem;
        grid-template-rows: 0 minmax(1.230497rem, max-content) minmax(5.10847244rem, max-content) minmax(27.50852838rem, max-content);
    }

    #qjHVykhdePIeTmhj {
        grid-area: 3 / 4 / 11 / 8;
        position: relative;
    }

    #dLV7M2EhCd6gQJWK {
        font-size: calc(1.96843765em - var(--ffsd));
    }

    #GhnBxxV2utZrbKxD {
        --first-font-size: 1.96843765em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #JBem8AAHgF9wUp50 {
        min-width: 11.14806384rem;
    }

    #POftSeqPB2GG9unP {
        grid-area: 4 / 6 / 6 / 9;
        position: relative;
    }

    #rcHahNQYhBrtDzMH {
        font-size: calc(3.353166em - var(--ffsd));
    }

    #NCh1C1hvuJQfho2a {
        font-size: calc(3.353166em - var(--ffsd));
    }

    #zho803dpI9iZmycT {
        --first-font-size: 3.353166em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #oNCDGrPyYKXhgfie {
        min-width: 15.4883459rem;
    }

    #LswDIwdGmH8V1FQL {
        grid-area: 7 / 7 / 8 / 10;
        position: relative;
    }

    #RDigOIja40TpB9iR {
        font-size: calc(4.32247701em - var(--ffsd));
    }

    #yRDAiXVSmolxGkcd {
        font-size: calc(4.32247701em - var(--ffsd));
    }

    #iQSRtE5gu17SF3ot {
        --first-font-size: 4.32247701em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #C7FE1Rrh6OY8XLfv {
        min-width: 14.74006687rem;
    }

    #W8vidrrjnlLMkvnZ {
        grid-area: 9 / 3 / 10 / 5;
        position: relative;
    }

    #Twu2Ek3UMoef88oU {
        grid-template-columns: 4.16666667rem 16.94683361rem 5.41719119rem 9.25777151rem 27.41139834rem 4.3300011rem 0.43458647rem 6.3183721rem 8.67028316rem 12.88022917rem 4.16666667rem;
        grid-template-rows: 0 minmax(17.68983747rem, max-content) minmax(0.84502808rem, max-content) minmax(0.38546892rem, max-content) minmax(1.97192354rem, max-content) minmax(3.18362845rem, max-content) minmax(8.60050335rem, max-content) minmax(0.42646035rem, max-content) minmax(11.17487611rem, max-content) minmax(7.25960902rem, max-content);
    }

    #trW5gSnAxYuqj39c {
        min-height: calc(16.26069041 * var(--1vh, 1vh));
    }
}

@media (min-width: 768.05px) and (max-width: 1024px) {
    #GYZxd7sCWpy1B0hJ {
        grid-area: 2 / 2 / 3 / 3;
        position: relative;
    }

    #Hi6tq9QFfXPsMS7O {
        grid-template-columns: 0 124.64288586%;
        left: -12.32144293%;
        grid-template-rows: 0 100%;
    }

    #z2CTA7bWhrcxuzoi {
        font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.38789326em - var(--ffsd)));
    }

    #SVFHtcIRhu21XmP5 {
        font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.38789326em - var(--ffsd)));
    }

    #UeeVj2u0SOLC5eub {
        font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.38789326em - var(--ffsd)));
    }

    #PBTXDmhoLAyLZHra {
        --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.38789326em);
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #oaR6C3SNBVY7jhsE {
        min-width: 13.0594057rem;
    }

    #nmhQzdnlHo4Ngh6o {
        grid-area: 2 / 3 / 5 / 4;
        position: relative;
    }

    #tw8ztAt2LTbHdszs {
        grid-area: 2 / 2 / 5 / 4;
        position: relative;
    }

    #xoZDYyBabR8XrASa {
        font-size: calc(2.86458008em - var(--ffsd));
    }

    #zUzsJtHjGxLfWj2O {
        --first-font-size: 2.86458008em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #JvrRxijZ9oMwdkNL {
        min-width: 21.46367783rem;
    }

    #b0ZayqVlU57huviE {
        grid-area: 3 / 3 / 4 / 5;
        position: relative;
    }

    #nbcdE1ko1cfCBYgv {
        grid-area: 3 / 6 / 6 / 13;
        grid-template-columns: 0 0 21.41484971rem 0;
        grid-template-rows: 0 minmax(1.3363117rem, max-content) minmax(3.43060876rem, max-content) minmax(1.3363117rem, max-content);
    }

    #SDmbetXCdcNiMQ0l {
        grid-area: 3 / 6 / 6 / 13;
        position: relative;
    }

    #awBJa1CduFGW55qK {
        grid-area: 2 / 2 / 5 / 4;
        position: relative;
    }

    #ZtJemiC5CLdDSFhb {
        font-size: calc(2.86458008em - var(--ffsd));
    }

    #CccrPNkLLX1p8wkt {
        --first-font-size: 2.86458008em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #hyVohMOb6Cu6scNF {
        min-width: 21.46367783rem;
    }

    #SLx52A4lNCDD7URU {
        grid-area: 3 / 3 / 4 / 5;
        position: relative;
    }

    #cgD9IXlm7Ju2J8BZ {
        grid-area: 4 / 15 / 7 / 18;
        grid-template-columns: 0 0 21.41484971rem 0;
        grid-template-rows: 0 minmax(1.3363117rem, max-content) minmax(3.43060876rem, max-content) minmax(1.3363117rem, max-content);
    }

    #KD4cu3gkWafGaBvn {
        grid-area: 4 / 15 / 7 / 18;
        position: relative;
    }

    #NgiJlB4c33SbfSuc {
        stroke-width: calc(100rem * 0.0 / 1024.0);
    }

    #VVgsv7JECgKIBxCA {
        grid-area: 8 / 5 / 17 / 7;
        position: relative;
    }

    #LXHFRPactaZhvcYa {
        grid-area: 9 / 8 / 16 / 9;
        position: relative;
    }

    #TjWvPjmdU6HawWdg {
        grid-area: 10 / 10 / 13 / 11;
        position: relative;
    }

    #ztROjQYjqfL44BKH {
        grid-area: 11 / 12 / 14 / 14;
        position: relative;
    }

    #FJLC1Qdi0rtkJld5 {
        grid-area: 12 / 16 / 15 / 17;
        position: relative;
    }

    #pVMtNSiLt9r47pBJ {
        grid-area: 2 / 3 / 6 / 4;
        position: relative;
    }

    #mMzMMeDtZZ360CkH {
        grid-area: 3 / 5 / 5 / 6;
        position: relative;
    }

    #M0RZTwr15ZN1amDl {
        grid-area: 5 / 2 / 7 / 11;
        position: relative;
        clip-path: polygon(calc(2.22145295% + 0%) calc((0 * 93.75rem) + -465.06085063%), calc(100% - (2.22145295% + 0%)) calc((0 * 93.75rem) + -465.06085063%), calc(100% - (2.22145295% + 0%)) calc(100% - ((0 * 93.75rem) + -416.35009448%)), calc(2.22145295% + 0%) calc(100% - ((0 * 93.75rem) + -416.35009448%)));
        margin-left: -2.32473891%;
        margin-right: -2.32473891%;
        margin-bottom: 0%;
        margin-top: 0%;
    }

    #UYhof6XFdoTeePz2 {
        grid-area: 2 / 4 / 8 / 5;
        position: relative;
    }

    #wCpLsvUbgKcrw74n {
        grid-area: 3 / 6 / 9 / 7;
        position: relative;
    }

    #Cg3Sy1q5bFb2mkZZ {
        grid-area: 4 / 8 / 10 / 9;
        position: relative;
    }

    #d3YfJLOOxLv53XlB {
        font-size: calc(14.30636521em - var(--ffsd));
    }

    #Z03aveSxG7y4bp5y {
        --first-font-size: 14.30636521em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #AxVYXKxNUfQWWpXN {
        min-width: 81.12856022rem;
    }

    #MAFrnQO60Cvo7r8P {
        grid-area: 6 / 3 / 11 / 10;
        position: relative;
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: -4.08162537%;
        margin-top: 0%;
    }

    #cpXybmKBpVREDHwu {
        grid-template-columns: 0 5.70802231rem 13.91335057rem 7.5823609rem 15.35649905rem 12.30987308rem 9.74362581rem 9.28168298rem 12.89233971rem 6.96224559rem;
        grid-template-rows: 0 minmax(4.10079053rem, max-content) minmax(1.69881516rem, max-content) minmax(4.64950711rem, max-content) minmax(0.71322945rem, max-content) minmax(2.10740101rem, max-content) minmax(0.67774335rem, max-content) minmax(0.6950316rem, max-content) 0 minmax(10.37092262rem, max-content);
    }

    #qMnoabXHnVjwInRS {
        grid-area: 18 / 2 / 19 / 19;
        grid-template-columns: 0 23.9388795rem 26.46247253rem 0 17.18973454rem 26.15891342rem;
        grid-template-rows: 0 minmax(3.95752013rem, max-content) minmax(4.06739694rem, max-content) minmax(14.64251821rem, max-content) minmax(4.98161025rem, max-content) minmax(5.38931238rem, max-content);
    }

    #t98KcVhx5k6ZiyUi {
        grid-area: 18 / 2 / 19 / 19;
        position: relative;
    }

    #o2vct82pnNq76JpZ {
        grid-template-columns: 3.125rem 14.61317586rem 13.01057757rem 2.04019636rem 3.62337955rem 2.72500212rem 0.74529563rem 6.32119296rem 0.78125rem 6.19571573rem 0.78125rem 3.86514327rem 2.33057246rem 0.68922292rem 0.16836005rem 6.19571573rem 15.05077393rem 14.61317586rem 3.125rem;
        grid-template-rows: minmax(2.39860618rem, max-content) minmax(3.55659443rem, max-content) 0 minmax(5.97775494rem, max-content) minmax(0.12547723rem, max-content) 0 minmax(3.125rem, max-content) minmax(0.01359435rem, max-content) minmax(0.06273862rem, max-content) 0 0 minmax(6.19571573rem, max-content) 0 0 minmax(0.06273862rem, max-content) minmax(0.01359435rem, max-content) minmax(15.50723347rem, max-content) minmax(33.0383579rem, max-content);
    }

    #HCoMi8okURzQ2FUD {
        min-height: calc(24.18719423 * var(--1vh, 1vh));
    }

    #NE1rf1d3Ap7ric9U {
        grid-area: 2 / 2 / 4 / 4;
        position: relative;
    }

    #GfbzzPPRhlr71xg0 {
        grid-area: 3 / 3 / 5 / 5;
        position: relative;
    }

    #SseCKOv4DDk4zJ72 {
        grid-template-columns: 0 0 203.18262388% 0;
        left: -51.59131194%;
        grid-template-rows: 0 0 100% 0;
    }

    #iHRuf8jfVivYS4vk {
        grid-area: 2 / 2 / 3 / 3;
        position: relative;
    }

    #F7EsBrbFEmUGSPX5 {
        grid-area: 2 / 3 / 3 / 6;
        grid-template-columns: 0 23.55813389rem;
        grid-template-rows: 0 minmax(48.23527914rem, max-content);
    }

    #MsIJpjnmK0SqWAKR {
        grid-area: 2 / 3 / 3 / 6;
        position: relative;
    }

    #cxSaOV2oLrgXay0Z {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #qlZJ4US1Ckbg7TwL {
        font-size: calc(2.27682519em - var(--ffsd));
    }

    #MyUU1BEJsxk1dzCr {
        font-size: calc(2.27682519em - var(--ffsd));
    }

    #ag44sLAMqYnK4PQv {
        min-width: 20.75739688rem;
    }

    #FkZ9L6qQUTZB1uA6 {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #uTYNBfP5D8x8iCaD {
        grid-template-columns: 0 0.37245627rem 20.70856875rem 0.37245627rem;
        grid-template-rows: 0 minmax(0.37245627rem, max-content) minmax(20.70856875rem, max-content) minmax(0.37245627rem, max-content);
    }

    #e9xue4IC3pL4VPS2 {
        grid-template-columns: 0 21.4534813rem;
        grid-template-rows: 0 minmax(21.4534813rem, max-content);
    }

    #iXhJDRsO9EVbiecL {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #P0qQOhQwSkp7aC1Z {
        font-size: calc(2.27682519em - var(--ffsd));
    }

    #vd9F9gCezZB28BGW {
        font-size: calc(2.27682519em - var(--ffsd));
    }

    #kt7etc92XMD01Nae {
        min-width: 20.75739688rem;
    }

    #ndV4vajM7C15muWi {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #t3zIVKyazDNzzrFn {
        grid-template-columns: 0 0.37245627rem 20.70856875rem 0.37245627rem;
        grid-template-rows: 0 minmax(0.37245627rem, max-content) minmax(20.70856875rem, max-content) minmax(0.37245627rem, max-content);
    }

    #j47hG6rZtGEWAq6o {
        grid-template-columns: 0 21.4534813rem;
        grid-template-rows: 0 minmax(21.4534813rem, max-content);
    }

    #KyBDA8zW131ZPJbx {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #IWo9DmSAzsqnjsSp {
        font-size: calc(2.27682519em - var(--ffsd));
    }

    #HGOS3zqg7FCPl4im {
        font-size: calc(2.27682519em - var(--ffsd));
    }

    #SH7FuVbaZiF0qfJa {
        font-size: calc(2.27682519em - var(--ffsd));
    }

    #J6P1aGpJHch6VSsa {
        min-width: 20.75739688rem;
    }

    #WSilaAiSGbS4cjjg {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #oDpjAAGpD56VX2lY {
        grid-template-columns: 0 0.37245627rem 20.70856875rem 0.37245627rem;
        grid-template-rows: 0 minmax(0.37245627rem, max-content) minmax(20.70856875rem, max-content) minmax(0.37245627rem, max-content);
    }

    #s0oxYsLsEQx9iQnD {
        grid-template-columns: 0 21.4534813rem;
        grid-template-rows: 0 minmax(21.4534813rem, max-content);
    }

    #efp5NX8ELL6cb0m5 {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #MyT9Ep1lVyfTG2y1 {
        font-size: calc(2.27682519em - var(--ffsd));
    }

    #PDnvbnw9qDjr9qmk {
        font-size: calc(2.27682519em - var(--ffsd));
    }

    #qJJQv9HXmbrwyJx5 {
        font-size: calc(2.27682519em - var(--ffsd));
    }

    #Lgwue5AFgm7uOdXR {
        min-width: 20.75739688rem;
    }

    #TVzgVXnsETi1JwP8 {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #vCFLNiy5CSxYYczU {
        grid-template-columns: 0 0.37245627rem 20.70856875rem 0.37245627rem;
        grid-template-rows: 0 minmax(0.37245627rem, max-content) minmax(20.70856875rem, max-content) minmax(0.37245627rem, max-content);
    }

    #GuD5Uz86W29AO2PF {
        grid-template-columns: 0 21.4534813rem;
        grid-template-rows: 0 minmax(21.4534813rem, max-content);
    }

    #FCEonenHNKteaOTo {
        grid-area: 4 / 2 / 8 / 5;
        grid-template-columns: 0 0 21.4534813rem 0 3.67592534rem 0 21.4534813rem 0;
        grid-template-rows: 0 0 minmax(21.4534813rem, max-content) 0 minmax(2.23552073rem, max-content) 0 minmax(21.4534813rem, max-content) 0;
    }

    #ZoIMLEsxWCvcHkpf {
        grid-area: 4 / 2 / 8 / 5;
        position: relative;
    }

    #ERjsuCExaWs4OxTh {
        font-size: calc(2.27682519em - var(--ffsd));
    }

    #tdq1UtVKTCg5B62K {
        --first-font-size: 2.27682519em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #S8JPBsyUkgDGctX0 {
        min-width: 20.49391316rem;
    }

    #n97crLUJTMUhWzwE {
        grid-area: 5 / 7 / 6 / 8;
        position: relative;
    }

    #I0meeBGXz1lEo51F {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #Pt2SasUpq89WC7LY {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #KoKSbxUT4u8DRTFN {
        grid-area: 7 / 4 / 9 / 9;
        grid-template-columns: 0 6.74885484rem 41.03390658rem 1.21573536rem;
        grid-template-rows: 0 minmax(10.99630355rem, max-content) minmax(23.03028007rem, max-content) minmax(14.97191316rem, max-content);
    }

    #FXourizORhVFYUTf {
        grid-area: 7 / 4 / 9 / 9;
        position: relative;
    }

    #wXUfe99v8Az7ZkeL {
        grid-template-columns: 3.125rem 35.09593305rem 9.65557016rem 1.83138472rem 12.07117901rem 2.20844605rem 20.44508504rem 12.44240197rem 3.125rem;
        grid-template-rows: minmax(3.125rem, max-content) minmax(48.23527914rem, max-content) minmax(3.125rem, max-content) minmax(0.79685888rem, max-content) minmax(2.70905837rem, max-content) minmax(4.11975422rem, max-content) minmax(37.51681186rem, max-content) minmax(11.48168493rem, max-content) minmax(3.125rem, max-content);
    }

    #PB190VfyxhN1g57H {
        min-height: calc(39.42798302 * var(--1vh, 1vh));
    }

    #S24Qvk6Uub2QI1uE {
        grid-area: 2 / 2 / 3 / 3;
        position: relative;
    }

    #JAVZGggq0qfvXY8P {
        grid-template-columns: 0 100%;
        grid-template-rows: 0 100%;
    }

    #LoY9LNFKynqI28ld {
        grid-area: 2 / 2 / 5 / 4;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: -3.125rem;
        right: -3.125rem;
    }

    #cRBH4RYtElSYnavo {
        font-size: calc(7.7920937em - var(--ffsd));
    }

    #aVxuqBSp7Usil9CF {
        --first-font-size: 7.7920937em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #QQbhaOYyrM3mKnNu {
        min-width: 93.79882812rem;
    }

    #MZinALDTUIQ7TrGA {
        grid-area: 3 / 3 / 4 / 5;
        position: relative;
    }

    #JxjkhhxnzqlZg7HH {
        grid-template-columns: 0 0 93.75rem 0;
        grid-template-rows: 0 minmax(0.1948526rem, max-content) minmax(9.28448124rem, max-content) minmax(0.21555138rem, max-content);
    }

    #SauakXt7erBn7Pat {
        grid-area: 2 / 2 / 4 / 6;
        position: relative;
    }

    #hslfahugOP8kjSZc {
        font-size: calc(16.88418675em - var(--ffsd));
    }

    #DSVTPMiDe3tIX7Pr {
        --first-font-size: 16.88418675em;
        --last-font-size: var(--first-font-size);
        transform: translateX(calc(var(--first-font-size) * -0.015));
        margin-top: calc(var(--first-font-size) * 0.195);
        margin-bottom: calc(var(--last-font-size) * 0.195);
    }

    #GGOWhQPOd9S8Lb4i {
        min-width: 61.49361068rem;
    }

    #jRGK6ZndkgI1U1Pb {
        grid-area: 2 / 2 / 5 / 7;
        position: relative;
        margin-left: 0%;
        margin-right: -35.41958954%;
        margin-bottom: 0%;
        margin-top: 0%;
    }

    #doNvn665yJOENovb {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #V020GxfFggxupthb {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #RU7pATwEjzj975w2 {
        grid-template-columns: 0 1.04324645rem 17.26796234rem 1.04324645rem;
        grid-template-rows: 0 minmax(1.81629791rem, max-content) minmax(36.06885084rem, max-content) minmax(1.81629791rem, max-content);
    }

    #VrttusACrjaForDu {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
        clip-path: polygon(calc(0% + 0%) calc((0 * 36.11503436rem) + 0%), calc(100% - (0% + 0%)) calc((0 * 36.11503436rem) + 0%), calc(100% - (0% + 0%)) calc(100% - ((0.19265732 * 36.11503436rem) + 0%)), calc(0% + 0%) calc(100% - ((0.19265732 * 36.11503436rem) + 0%)));
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: -19.26573186%;
        margin-top: 0%;
    }

    #KnzuTsgySnwjtOWS {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #QU1gJxnJzUoahVQn {
        grid-template-columns: 0 14.00344053rem 8.1081533rem 14.00344053rem;
        grid-template-rows: 0 minmax(21.72023723rem, max-content) minmax(5.13854216rem, max-content) minmax(2.2984293rem, max-content);
    }

    #CR0i87UxQ2qVrDDe {
        grid-template-columns: 0 1.13964386rem 29.76642409rem 6.34861027rem 13.00584497rem 11.18425936rem;
        grid-template-rows: 0 minmax(1.11774118rem, max-content) minmax(13.26948689rem, max-content) minmax(5.80840521rem, max-content) minmax(20.62355456rem, max-content) minmax(2.72524892rem, max-content);
    }

    #UHdk1SzIMxXB9lZe {
        grid-area: 3 / 4 / 6 / 7;
        position: relative;
    }

    #qMM0li9OTe0DAyj9 {
        grid-area: 2 / 4 / 7 / 5;
        position: relative;
        clip-path: polygon(calc(0% + -10.51530523%) calc((0 * 25.9270569rem) + -7.32698905%), calc(100% - (0% + -10.51530523%)) calc((0 * 25.9270569rem) + -7.32698905%), calc(100% - (0% + -10.51530523%)) calc(100% - ((0.02961019 * 25.9270569rem) + 0%)), calc(0% + -10.51530523%) calc(100% - ((0.02961019 * 25.9270569rem) + 0%)));
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: -2.96101879%;
        margin-top: 0%;
    }

    #WvmBdZHiHvYfvHv1 {
        font-size: calc(3.92293894em - var(--ffsd));
    }

    #Pj3AsevLKx7zJbBd {
        --first-font-size: 3.92293894em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * 0.165);
        margin-bottom: calc(var(--last-font-size) * 0.165);
    }

    #kbpPhOS5kuMJcYxo {
        min-width: 32.23066565rem;
    }

    #JfXiq7lO7DArAUg2 {
        grid-area: 3 / 2 / 4 / 6;
        position: relative;
        margin-left: -9.54307356%;
        margin-right: 0%;
        margin-bottom: 0%;
        margin-top: 0%;
    }

    #J1ouP3qekHrm5kFp {
        font-size: calc(7.18049663em - var(--ffsd));
    }

    #ypA7yTvnsh3xbp1K {
        --first-font-size: 7.18049663em;
        --last-font-size: var(--first-font-size);
        transform: translateX(calc(var(--first-font-size) * -0.015));
        margin-top: calc(var(--first-font-size) * 0.195);
        margin-bottom: calc(var(--last-font-size) * 0.195);
    }

    #c8sBZODSCWAMe4JO {
        min-width: 34.94666102rem;
    }

    #yLLgNydVkdhXrszN {
        grid-area: 5 / 3 / 6 / 7;
        position: relative;
        margin-left: -1.40111726%;
        margin-right: 0%;
        margin-bottom: 0%;
        margin-top: 0%;
    }

    #JPTcfYJwQsLAkMxI {
        grid-template-columns: 0 0 4.79149524rem 25.9270569rem 1.46328539rem 2.71599538rem 0.49542rem;
        grid-template-rows: minmax(2.20526536rem, max-content) minmax(4.70276181rem, max-content) minmax(4.70241841rem, max-content) minmax(0.30026877rem, max-content) minmax(8.58878658rem, max-content) minmax(11.80360332rem, max-content);
    }

    #XxfMmJ36fQYalmJK {
        grid-area: 5 / 3 / 7 / 5;
        position: relative;
    }

    #UoDqrptOvPSXfdM2 {
        grid-template-columns: 3.125rem 0 32.30521744rem 3.08803545rem 58.3567471rem 0 3.125rem;
        grid-template-rows: minmax(0.00081263rem, max-content) minmax(9.1636305rem, max-content) minmax(0.53125472rem, max-content) minmax(10.71007778rem, max-content) minmax(32.30310426rem, max-content) 0;
    }

    #VUhJu2YPnTPCHjhQ {
        min-height: calc(18.19245306 * var(--1vh, 1vh));
    }

    #rvy4A4iR6YPIxqPA {
        grid-area: 2 / 2 / 3 / 3;
        position: relative;
    }

    #pn1x7c4gKltz9YH1 {
        grid-template-columns: 0 100%;
        grid-template-rows: 0 100%;
    }

    #QoiLM6rQnYVKJt2S {
        font-size: calc(16.17482524em - var(--ffsd));
    }

    #kfY4PEYtfPsrzlb0 {
        --first-font-size: 16.17482524em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #LrzmuiLv5jbJD7hQ {
        min-width: 93.79882812rem;
    }

    #CP9ZE9qacZKht97w {
        grid-area: 2 / 2 / 5 / 11;
        position: relative;
    }

    #mj8eCSR8RIfPXM4F {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
        clip-path: polygon(calc(0% + 0%) calc((0 * 42.37543373rem) + 0%), calc(100% - (0% + 0%)) calc((0 * 42.37543373rem) + 0%), calc(100% - (0% + 0%)) calc(100% - ((0.06934369 * 42.37543373rem) + 0%)), calc(0% + 0%) calc(100% - ((0.06934369 * 42.37543373rem) + 0%)));
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: -6.93436917%;
        margin-top: 0%;
    }

    #sWzDWozhw3Qs7Zro {
        font-size: calc(2.01317487em - var(--ffsd));
    }

    #DzMwhIsGv7XkxBPS {
        font-size: calc(2.01317487em - var(--ffsd));
    }

    #fVE9QXf20wytuPgu {
        --first-font-size: 2.01317487em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #qi7tbXIBbScQrU0U {
        min-width: 5.55003489rem;
    }

    #ujNdQWpgaVj51duH {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #ku08T3jEjjkQDIib {
        grid-template-columns: 0 17.90797428rem 5.50120676rem 18.96625268rem;
        grid-template-rows: 0 minmax(1.25846284rem, max-content) minmax(5.22457409rem, max-content) minmax(28.1337222rem, max-content);
    }

    #qjHVykhdePIeTmhj {
        grid-area: 3 / 4 / 11 / 8;
        position: relative;
    }

    #dLV7M2EhCd6gQJWK {
        font-size: calc(2.01317487em - var(--ffsd));
    }

    #GhnBxxV2utZrbKxD {
        --first-font-size: 2.01317487em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #JBem8AAHgF9wUp50 {
        min-width: 11.38367325rem;
    }

    #POftSeqPB2GG9unP {
        grid-area: 4 / 6 / 6 / 9;
        position: relative;
    }

    #rcHahNQYhBrtDzMH {
        font-size: calc(3.42937432em - var(--ffsd));
    }

    #NCh1C1hvuJQfho2a {
        font-size: calc(3.42937432em - var(--ffsd));
    }

    #zho803dpI9iZmycT {
        --first-font-size: 3.42937432em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #oNCDGrPyYKXhgfie {
        min-width: 15.82259808rem;
    }

    #LswDIwdGmH8V1FQL {
        grid-area: 7 / 7 / 8 / 10;
        position: relative;
    }

    #RDigOIja40TpB9iR {
        font-size: calc(4.42071513em - var(--ffsd));
    }

    #yRDAiXVSmolxGkcd {
        font-size: calc(4.42071513em - var(--ffsd));
    }

    #iQSRtE5gu17SF3ot {
        --first-font-size: 4.42071513em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #C7FE1Rrh6OY8XLfv {
        min-width: 15.05731271rem;
    }

    #W8vidrrjnlLMkvnZ {
        grid-area: 9 / 3 / 10 / 5;
        position: relative;
    }

    #Twu2Ek3UMoef88oU {
        grid-template-columns: 3.125rem 17.33198892rem 5.54030917rem 9.46817541rem 28.03438466rem 4.42841022rem 0.44446343rem 6.46197147rem 8.86733505rem 13.17296166rem 3.125rem;
        grid-template-rows: 0 minmax(18.09187923rem, max-content) minmax(0.86423327rem, max-content) minmax(0.39422957rem, max-content) minmax(2.01673998rem, max-content) minmax(3.25598364rem, max-content) minmax(8.79596934rem, max-content) minmax(0.43615263rem, max-content) minmax(11.42885057rem, max-content) minmax(7.42460013rem, max-content);
    }

    #trW5gSnAxYuqj39c {
        min-height: calc(18.19236969 * var(--1vh, 1vh));
    }
}

@media (min-width: 1024.05px) {
    #GYZxd7sCWpy1B0hJ {
        grid-area: 2 / 2 / 3 / 3;
        position: relative;
    }

    #Hi6tq9QFfXPsMS7O {
        grid-template-columns: 0 100%;
        grid-template-rows: 0 100%;
    }

    #z2CTA7bWhrcxuzoi {
        font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.7900459em - var(--ffsd)));
    }

    #SVFHtcIRhu21XmP5 {
        font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.7900459em - var(--ffsd)));
    }

    #UeeVj2u0SOLC5eub {
        font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.7900459em - var(--ffsd)));
    }

    #PBTXDmhoLAyLZHra {
        --first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.7900459em);
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #oaR6C3SNBVY7jhsE {
        min-width: 9.78977411rem;
    }

    #nmhQzdnlHo4Ngh6o {
        grid-area: 2 / 3 / 10 / 4;
        position: relative;
    }

    #tw8ztAt2LTbHdszs {
        grid-area: 2 / 2 / 5 / 4;
        position: relative;
    }

    #xoZDYyBabR8XrASa {
        font-size: calc(2.14738653em - var(--ffsd));
    }

    #zUzsJtHjGxLfWj2O {
        --first-font-size: 2.14738653em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #JvrRxijZ9oMwdkNL {
        min-width: 16.08990198rem;
    }

    #b0ZayqVlU57huviE {
        grid-area: 3 / 3 / 4 / 5;
        position: relative;
    }

    #nbcdE1ko1cfCBYgv {
        grid-area: 3 / 5 / 11 / 6;
        grid-template-columns: 0 0 16.05329876rem 0;
        grid-template-rows: 0 minmax(1.00174464rem, max-content) minmax(2.57170086rem, max-content) minmax(1.00174464rem, max-content);
    }

    #SDmbetXCdcNiMQ0l {
        grid-area: 3 / 5 / 11 / 6;
        position: relative;
    }

    #awBJa1CduFGW55qK {
        grid-area: 2 / 2 / 5 / 4;
        position: relative;
    }

    #ZtJemiC5CLdDSFhb {
        font-size: calc(2.14738653em - var(--ffsd));
    }

    #CccrPNkLLX1p8wkt {
        --first-font-size: 2.14738653em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #hyVohMOb6Cu6scNF {
        min-width: 16.08990198rem;
    }

    #SLx52A4lNCDD7URU {
        grid-area: 3 / 3 / 4 / 5;
        position: relative;
    }

    #cgD9IXlm7Ju2J8BZ {
        grid-area: 4 / 7 / 12 / 8;
        grid-template-columns: 0 0 16.05329876rem 0;
        grid-template-rows: 0 minmax(1.00174464rem, max-content) minmax(2.57170086rem, max-content) minmax(1.00174464rem, max-content);
    }

    #KD4cu3gkWafGaBvn {
        grid-area: 4 / 7 / 12 / 8;
        position: relative;
    }

    #NgiJlB4c33SbfSuc {
        stroke-width: calc(100rem * 0.0 / 1366.0);
    }

    #VVgsv7JECgKIBxCA {
        grid-area: 5 / 9 / 17 / 10;
        position: relative;
    }

    #LXHFRPactaZhvcYa {
        grid-area: 6 / 11 / 16 / 12;
        position: relative;
    }

    #TjWvPjmdU6HawWdg {
        grid-area: 7 / 13 / 13 / 14;
        position: relative;
    }

    #ztROjQYjqfL44BKH {
        grid-area: 8 / 15 / 14 / 16;
        position: relative;
    }

    #FJLC1Qdi0rtkJld5 {
        grid-area: 9 / 17 / 15 / 18;
        position: relative;
    }

    #pVMtNSiLt9r47pBJ {
        grid-area: 2 / 3 / 6 / 4;
        position: relative;
    }

    #mMzMMeDtZZ360CkH {
        grid-area: 3 / 5 / 5 / 6;
        position: relative;
    }

    #M0RZTwr15ZN1amDl {
        grid-area: 5 / 2 / 7 / 11;
        position: relative;
        clip-path: polygon(calc(2.22145295% + 0%) calc((0 * 100rem) + -465.06085063%), calc(100% - (2.22145295% + 0%)) calc((0 * 100rem) + -465.06085063%), calc(100% - (2.22145295% + 0%)) calc(100% - ((0 * 100rem) + -416.35009448%)), calc(2.22145295% + 0%) calc(100% - ((0 * 100rem) + -416.35009448%)));
        margin-left: -2.32473891%;
        margin-right: -2.32473891%;
        margin-bottom: 0%;
        margin-top: 0%;
    }

    #UYhof6XFdoTeePz2 {
        grid-area: 2 / 4 / 8 / 5;
        position: relative;
    }

    #wCpLsvUbgKcrw74n {
        grid-area: 3 / 6 / 9 / 7;
        position: relative;
    }

    #Cg3Sy1q5bFb2mkZZ {
        grid-area: 4 / 8 / 10 / 9;
        position: relative;
    }

    #d3YfJLOOxLv53XlB {
        font-size: calc(15.26012289em - var(--ffsd));
    }

    #Z03aveSxG7y4bp5y {
        --first-font-size: 15.26012289em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #AxVYXKxNUfQWWpXN {
        min-width: 86.52165079rem;
    }

    #MAFrnQO60Cvo7r8P {
        grid-area: 6 / 3 / 11 / 10;
        position: relative;
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: -4.08162537%;
        margin-top: 0%;
    }

    #cpXybmKBpVREDHwu {
        grid-template-columns: 0 6.08855713rem 14.84090728rem 8.08785162rem 16.38026566rem 13.13053128rem 10.39320086rem 9.90046185rem 13.75182902rem 7.4263953rem;
        grid-template-rows: 0 minmax(4.37417657rem, max-content) minmax(1.8120695rem, max-content) minmax(4.95947425rem, max-content) minmax(0.76077808rem, max-content) minmax(2.24789441rem, max-content) minmax(0.72292624rem, max-content) minmax(0.74136705rem, max-content) 0 minmax(11.06231746rem, max-content);
    }

    #qMnoabXHnVjwInRS {
        grid-area: 18 / 2 / 19 / 19;
        grid-template-columns: 0 25.5348048rem 28.22663737rem 0 18.33571685rem 27.90284098rem;
        grid-template-rows: 0 minmax(4.2213548rem, max-content) minmax(4.33855674rem, max-content) minmax(15.61868609rem, max-content) minmax(5.3137176rem, max-content) minmax(5.74859987rem, max-content);
    }

    #t98KcVhx5k6ZiyUi {
        grid-area: 18 / 2 / 19 / 19;
        position: relative;
    }

    #o2vct82pnNq76JpZ {
        grid-template-columns: 0 2.94796107rem 9.75317089rem 4.24560888rem 16.05329876rem 2.26374119rem 16.05329876rem 17.60423429rem 4.75896254rem 0.55869892rem 4.73858096rem 0.58565154rem 4.64451896rem 0.58565154rem 4.64451896rem 0.64287332rem 4.64451896rem 5.27471046rem;
        grid-template-rows: minmax(1.79807667rem, max-content) minmax(2.66614399rem, max-content) 0 minmax(0.13370034rem, max-content) minmax(0.01019079rem, max-content) minmax(0.047031rem, max-content) 0 0 minmax(4.29020603rem, max-content) minmax(0.09406199rem, max-content) 0 minmax(0.26025094rem, max-content) 0 0 minmax(0.047031rem, max-content) minmax(0.01019079rem, max-content) minmax(11.62474896rem, max-content) minmax(35.24091509rem, max-content);
    }

    #HCoMi8okURzQ2FUD {
        min-height: calc(25 * var(--1vh, 1vh));
    }

    #NE1rf1d3Ap7ric9U {
        grid-area: 2 / 2 / 4 / 4;
        position: relative;
    }

    #GfbzzPPRhlr71xg0 {
        grid-area: 3 / 3 / 5 / 5;
        position: relative;
    }

    #SseCKOv4DDk4zJ72 {
        grid-template-columns: 0 0 100% 0;
        grid-template-rows: 0 0 100% 0;
    }

    #iHRuf8jfVivYS4vk {
        grid-area: 2 / 2 / 3 / 3;
        position: relative;
    }

    #F7EsBrbFEmUGSPX5 {
        grid-area: 2 / 2 / 8 / 3;
        grid-template-columns: 0 17.65997738rem;
        grid-template-rows: 0 minmax(36.15880369rem, max-content);
    }

    #MsIJpjnmK0SqWAKR {
        grid-area: 2 / 2 / 8 / 3;
        position: relative;
    }

    #cxSaOV2oLrgXay0Z {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #qlZJ4US1Ckbg7TwL {
        font-size: calc(1.79004392em - var(--ffsd));
    }

    #MyUU1BEJsxk1dzCr {
        font-size: calc(1.79004392em - var(--ffsd));
    }

    #ag44sLAMqYnK4PQv {
        min-width: 16.31771596rem;
    }

    #FkZ9L6qQUTZB1uA6 {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #uTYNBfP5D8x8iCaD {
        grid-template-columns: 0 0.29282577rem 16.28111274rem 0.29282577rem;
        grid-template-rows: 0 minmax(0.29282577rem, max-content) minmax(16.28111274rem, max-content) minmax(0.29282577rem, max-content);
    }

    #e9xue4IC3pL4VPS2 {
        grid-template-columns: 0 16.86676428rem;
        grid-template-rows: 0 minmax(16.86676428rem, max-content);
    }

    #iXhJDRsO9EVbiecL {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #P0qQOhQwSkp7aC1Z {
        font-size: calc(1.79004392em - var(--ffsd));
    }

    #vd9F9gCezZB28BGW {
        font-size: calc(1.79004392em - var(--ffsd));
    }

    #kt7etc92XMD01Nae {
        min-width: 16.31771596rem;
    }

    #ndV4vajM7C15muWi {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #t3zIVKyazDNzzrFn {
        grid-template-columns: 0 0.29282577rem 16.28111274rem 0.29282577rem;
        grid-template-rows: 0 minmax(0.29282577rem, max-content) minmax(16.28111274rem, max-content) minmax(0.29282577rem, max-content);
    }

    #j47hG6rZtGEWAq6o {
        grid-template-columns: 0 16.86676428rem;
        grid-template-rows: 0 minmax(16.86676428rem, max-content);
    }

    #KyBDA8zW131ZPJbx {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #IWo9DmSAzsqnjsSp {
        font-size: calc(1.79004392em - var(--ffsd));
    }

    #HGOS3zqg7FCPl4im {
        font-size: calc(1.79004392em - var(--ffsd));
    }

    #SH7FuVbaZiF0qfJa {
        font-size: calc(1.79004392em - var(--ffsd));
    }

    #J6P1aGpJHch6VSsa {
        min-width: 16.31771596rem;
    }

    #WSilaAiSGbS4cjjg {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #oDpjAAGpD56VX2lY {
        grid-template-columns: 0 0.29282577rem 16.28111274rem 0.29282577rem;
        grid-template-rows: 0 minmax(0.29282577rem, max-content) minmax(16.28111274rem, max-content) minmax(0.29282577rem, max-content);
    }

    #s0oxYsLsEQx9iQnD {
        grid-template-columns: 0 16.86676428rem;
        grid-template-rows: 0 minmax(16.86676428rem, max-content);
    }

    #efp5NX8ELL6cb0m5 {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #MyT9Ep1lVyfTG2y1 {
        font-size: calc(1.79004392em - var(--ffsd));
    }

    #PDnvbnw9qDjr9qmk {
        font-size: calc(1.79004392em - var(--ffsd));
    }

    #qJJQv9HXmbrwyJx5 {
        font-size: calc(1.79004392em - var(--ffsd));
    }

    #Lgwue5AFgm7uOdXR {
        min-width: 16.31771596rem;
    }

    #TVzgVXnsETi1JwP8 {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #vCFLNiy5CSxYYczU {
        grid-template-columns: 0 0.29282577rem 16.28111274rem 0.29282577rem;
        grid-template-rows: 0 minmax(0.29282577rem, max-content) minmax(16.28111274rem, max-content) minmax(0.29282577rem, max-content);
    }

    #GuD5Uz86W29AO2PF {
        grid-template-columns: 0 16.86676428rem;
        grid-template-rows: 0 minmax(16.86676428rem, max-content);
    }

    #FCEonenHNKteaOTo {
        grid-area: 3 / 4 / 7 / 6;
        grid-template-columns: 0 0 16.86676428rem 0 2.89001889rem 0 16.86676428rem 0;
        grid-template-rows: 0 0 minmax(16.86676428rem, max-content) 0 minmax(1.75757029rem, max-content) 0 minmax(16.86676428rem, max-content) 0;
    }

    #ZoIMLEsxWCvcHkpf {
        grid-area: 3 / 4 / 7 / 6;
        position: relative;
    }

    #ERjsuCExaWs4OxTh {
        font-size: calc(1.79004392em - var(--ffsd));
    }

    #tdq1UtVKTCg5B62K {
        --first-font-size: 1.79004392em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #S8JPBsyUkgDGctX0 {
        min-width: 16.1105646rem;
    }

    #n97crLUJTMUhWzwE {
        grid-area: 4 / 7 / 5 / 8;
        position: relative;
    }

    #I0meeBGXz1lEo51F {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #Pt2SasUpq89WC7LY {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #KoKSbxUT4u8DRTFN {
        grid-area: 6 / 5 / 9 / 9;
        grid-template-columns: 0 5.3059614rem 32.26092866rem 0.95581325rem;
        grid-template-rows: 0 minmax(8.64531296rem, max-content) minmax(18.10644621rem, max-content) minmax(11.77094415rem, max-content);
    }

    #FXourizORhVFYUTf {
        grid-area: 6 / 5 / 9 / 9;
        position: relative;
    }

    #wXUfe99v8Az7ZkeL {
        grid-template-columns: 4.16203667rem 17.65997738rem 3.86612355rem 35.18370959rem 1.43983785rem 11.22666603rem 16.07396138rem 9.78223805rem 0.60544949rem;
        grid-template-rows: minmax(9.09926721rem, max-content) minmax(0.33385243rem, max-content) minmax(0.62649184rem, max-content) minmax(2.1298664rem, max-content) minmax(3.23895792rem, max-content) minmax(29.49578268rem, max-content) minmax(0.33385243rem, max-content) minmax(8.69306821rem, max-content) minmax(2.27140847rem, max-content);
    }

    #PB190VfyxhN1g57H {
        min-height: calc(25 * var(--1vh, 1vh));
    }

    #S24Qvk6Uub2QI1uE {
        grid-area: 2 / 2 / 3 / 3;
        position: relative;
    }

    #JAVZGggq0qfvXY8P {
        grid-template-columns: 0 100%;
        grid-template-rows: 0 100%;
    }

    #LoY9LNFKynqI28ld {
        grid-area: 2 / 2 / 5 / 4;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: calc(min(1366px - 100vw, 0px) / 2);
        right: calc(min(1366px - 100vw, 0px) / 2);
    }

    #cRBH4RYtElSYnavo {
        font-size: calc(8.31156662em - var(--ffsd));
    }

    #aVxuqBSp7Usil9CF {
        --first-font-size: 8.31156662em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #QQbhaOYyrM3mKnNu {
        min-width: 100.03660322rem;
    }

    #MZinALDTUIQ7TrGA {
        grid-area: 3 / 3 / 4 / 5;
        position: relative;
    }

    #JxjkhhxnzqlZg7HH {
        grid-template-columns: 0 0 100rem 0;
        grid-template-rows: 0 minmax(0.20784277rem, max-content) minmax(9.90344665rem, max-content) minmax(0.22992148rem, max-content);
    }

    #SauakXt7erBn7Pat {
        grid-area: 2 / 2 / 4 / 6;
        position: relative;
    }

    #hslfahugOP8kjSZc {
        font-size: calc(18.0097992em - var(--ffsd));
    }

    #DSVTPMiDe3tIX7Pr {
        --first-font-size: 18.0097992em;
        --last-font-size: var(--first-font-size);
        transform: translateX(calc(var(--first-font-size) * -0.015));
        margin-top: calc(var(--first-font-size) * 0.195);
        margin-bottom: calc(var(--last-font-size) * 0.195);
    }

    #GGOWhQPOd9S8Lb4i {
        min-width: 65.57770461rem;
    }

    #jRGK6ZndkgI1U1Pb {
        grid-area: 2 / 2 / 5 / 7;
        position: relative;
        margin-left: 0%;
        margin-right: -35.41958954%;
        margin-bottom: 0%;
        margin-top: 0%;
    }

    #doNvn665yJOENovb {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
    }

    #V020GxfFggxupthb {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #RU7pATwEjzj975w2 {
        grid-template-columns: 0 1.11279622rem 18.41915983rem 1.11279622rem;
        grid-template-rows: 0 minmax(1.93738444rem, max-content) minmax(38.47344089rem, max-content) minmax(1.93738444rem, max-content);
    }

    #VrttusACrjaForDu {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
        clip-path: polygon(calc(0% + 0%) calc((0 * 38.52270332rem) + 0%), calc(100% - (0% + 0%)) calc((0 * 38.52270332rem) + 0%), calc(100% - (0% + 0%)) calc(100% - ((0.19265732 * 38.52270332rem) + 0%)), calc(0% + 0%) calc(100% - ((0.19265732 * 38.52270332rem) + 0%)));
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: -19.26573186%;
        margin-top: 0%;
    }

    #KnzuTsgySnwjtOWS {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #QU1gJxnJzUoahVQn {
        grid-template-columns: 0 14.93700323rem 8.64869686rem 14.93700323rem;
        grid-template-rows: 0 minmax(23.16825304rem, max-content) minmax(5.48111163rem, max-content) minmax(2.45165792rem, max-content);
    }

    #CR0i87UxQ2qVrDDe {
        grid-template-columns: 0 1.21562012rem 31.75085236rem 6.77185096rem 13.8729013rem 11.92987665rem;
        grid-template-rows: 0 minmax(1.19225726rem, max-content) minmax(14.15411935rem, max-content) minmax(6.19563222rem, max-content) minmax(21.99845819rem, max-content) minmax(2.90693218rem, max-content);
    }

    #UHdk1SzIMxXB9lZe {
        grid-area: 3 / 4 / 6 / 7;
        position: relative;
    }

    #qMM0li9OTe0DAyj9 {
        grid-area: 2 / 4 / 7 / 5;
        position: relative;
        clip-path: polygon(calc(0% + -10.51530523%) calc((0 * 27.65552736rem) + -7.32698905%), calc(100% - (0% + -10.51530523%)) calc((0 * 27.65552736rem) + -7.32698905%), calc(100% - (0% + -10.51530523%)) calc(100% - ((0.02961019 * 27.65552736rem) + 0%)), calc(0% + -10.51530523%) calc(100% - ((0.02961019 * 27.65552736rem) + 0%)));
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: -2.96101879%;
        margin-top: 0%;
    }

    #WvmBdZHiHvYfvHv1 {
        font-size: calc(4.1844682em - var(--ffsd));
    }

    #Pj3AsevLKx7zJbBd {
        --first-font-size: 4.1844682em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * 0.165);
        margin-bottom: calc(var(--last-font-size) * 0.165);
    }

    #kbpPhOS5kuMJcYxo {
        min-width: 34.36389658rem;
    }

    #JfXiq7lO7DArAUg2 {
        grid-area: 3 / 2 / 4 / 6;
        position: relative;
        margin-left: -9.54307356%;
        margin-right: 0%;
        margin-bottom: 0%;
        margin-top: 0%;
    }

    #J1ouP3qekHrm5kFp {
        font-size: calc(7.6591964em - var(--ffsd));
    }

    #ypA7yTvnsh3xbp1K {
        --first-font-size: 7.6591964em;
        --last-font-size: var(--first-font-size);
        transform: translateX(calc(var(--first-font-size) * -0.015));
        margin-top: calc(var(--first-font-size) * 0.195);
        margin-bottom: calc(var(--last-font-size) * 0.195);
    }

    #c8sBZODSCWAMe4JO {
        min-width: 37.26095831rem;
    }

    #yLLgNydVkdhXrszN {
        grid-area: 5 / 3 / 6 / 7;
        position: relative;
        margin-left: -1.40111726%;
        margin-right: 0%;
        margin-bottom: 0%;
        margin-top: 0%;
    }

    #JPTcfYJwQsLAkMxI {
        grid-template-columns: 0 0 5.11092825rem 27.65552736rem 1.56083775rem 2.89706174rem 0.528448rem;
        grid-template-rows: minmax(2.35228305rem, max-content) minmax(5.01627927rem, max-content) minmax(5.01591297rem, max-content) minmax(0.32028668rem, max-content) minmax(9.16137235rem, max-content) minmax(12.59051021rem, max-content);
    }

    #XxfMmJ36fQYalmJK {
        grid-area: 5 / 3 / 7 / 5;
        position: relative;
    }

    #UoDqrptOvPSXfdM2 {
        grid-template-columns: 0 0 34.45889861rem 3.29390448rem 62.24719691rem 0;
        grid-template-rows: minmax(0.00060918rem, max-content) minmax(9.7745392rem, max-content) minmax(0.5666717rem, max-content) minmax(11.42408296rem, max-content) minmax(34.45664454rem, max-content) 0;
    }

    #VUhJu2YPnTPCHjhQ {
        min-height: calc(25 * var(--1vh, 1vh));
    }

    #rvy4A4iR6YPIxqPA {
        grid-area: 2 / 2 / 3 / 3;
        position: relative;
    }

    #pn1x7c4gKltz9YH1 {
        grid-template-columns: 0 100%;
        grid-template-rows: 0 100%;
    }

    #QoiLM6rQnYVKJt2S {
        font-size: calc(17.25314692em - var(--ffsd));
    }

    #kfY4PEYtfPsrzlb0 {
        --first-font-size: 17.25314692em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #LrzmuiLv5jbJD7hQ {
        min-width: 100.03660322rem;
    }

    #CP9ZE9qacZKht97w {
        grid-area: 2 / 2 / 5 / 11;
        position: relative;
    }

    #mj8eCSR8RIfPXM4F {
        grid-area: 2 / 2 / 5 / 5;
        position: relative;
        clip-path: polygon(calc(0% + 0%) calc((0 * 45.20046264rem) + 0%), calc(100% - (0% + 0%)) calc((0 * 45.20046264rem) + 0%), calc(100% - (0% + 0%)) calc(100% - ((0.06934369 * 45.20046264rem) + 0%)), calc(0% + 0%) calc(100% - ((0.06934369 * 45.20046264rem) + 0%)));
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: -6.93436917%;
        margin-top: 0%;
    }

    #sWzDWozhw3Qs7Zro {
        font-size: calc(2.14738653em - var(--ffsd));
    }

    #DzMwhIsGv7XkxBPS {
        font-size: calc(2.14738653em - var(--ffsd));
    }

    #fVE9QXf20wytuPgu {
        --first-font-size: 2.14738653em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #qi7tbXIBbScQrU0U {
        min-width: 5.9045571rem;
    }

    #ujNdQWpgaVj51duH {
        grid-area: 3 / 3 / 4 / 4;
        position: relative;
    }

    #ku08T3jEjjkQDIib {
        grid-template-columns: 0 19.10183923rem 5.86795388rem 20.23066953rem;
        grid-template-rows: 0 minmax(1.34236036rem, max-content) minmax(5.57287903rem, max-content) minmax(30.00930368rem, max-content);
    }

    #qjHVykhdePIeTmhj {
        grid-area: 3 / 4 / 11 / 8;
        position: relative;
    }

    #dLV7M2EhCd6gQJWK {
        font-size: calc(2.14738653em - var(--ffsd));
    }

    #GhnBxxV2utZrbKxD {
        --first-font-size: 2.14738653em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #JBem8AAHgF9wUp50 {
        min-width: 12.12710469rem;
    }

    #POftSeqPB2GG9unP {
        grid-area: 4 / 6 / 6 / 9;
        position: relative;
    }

    #rcHahNQYhBrtDzMH {
        font-size: calc(3.65799928em - var(--ffsd));
    }

    #NCh1C1hvuJQfho2a {
        font-size: calc(3.65799928em - var(--ffsd));
    }

    #zho803dpI9iZmycT {
        --first-font-size: 3.65799928em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #oNCDGrPyYKXhgfie {
        min-width: 16.86195784rem;
    }

    #LswDIwdGmH8V1FQL {
        grid-area: 7 / 7 / 8 / 10;
        position: relative;
    }

    #RDigOIja40TpB9iR {
        font-size: calc(4.71542947em - var(--ffsd));
    }

    #yRDAiXVSmolxGkcd {
        font-size: calc(4.71542947em - var(--ffsd));
    }

    #iQSRtE5gu17SF3ot {
        --first-font-size: 4.71542947em;
        --last-font-size: var(--first-font-size);
        margin-top: calc(var(--first-font-size) * -0.1);
        margin-bottom: calc(var(--last-font-size) * -0.1);
    }

    #C7FE1Rrh6OY8XLfv {
        min-width: 16.04565344rem;
    }

    #W8vidrrjnlLMkvnZ {
        grid-area: 9 / 3 / 10 / 5;
        position: relative;
    }

    #Twu2Ek3UMoef88oU {
        grid-template-columns: 0 18.48745485rem 5.90966312rem 10.09938711rem 29.90334364rem 4.72363757rem 0.47409433rem 6.89276957rem 9.45849072rem 14.0511591rem;
        grid-template-rows: 0 minmax(19.29800451rem, max-content) minmax(0.92184882rem, max-content) minmax(0.42051154rem, max-content) minmax(2.15118932rem, max-content) minmax(3.47304921rem, max-content) minmax(9.38236729rem, max-content) minmax(0.46522947rem, max-content) minmax(12.19077394rem, max-content) minmax(7.91957347rem, max-content);
    }

    #trW5gSnAxYuqj39c {
        min-height: calc(25 * var(--1vh, 1vh));
    }
}

@keyframes pop-RIGHT-df5a5649-6c47-4b6e-9eb0-9ba4589b3a7c {
    0% {
        transform: scale(0);
        animation-timing-function: cubic-bezier(0.52, 2.1, 0.43, 1.22);
    }

    35% {
        transform: scale(0.937);
        animation-timing-function: cubic-bezier(0.37, -0.25, 0.07, 2.15);
    }

    77% {
        transform: scale(0.995);
        animation-timing-function: cubic-bezier(0.28, -0.03, 0.48, 1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes pulse {
    0% {
        background-color: rgba(226, 226, 226, 0.05);
    }

    50% {
        background-color: rgba(226, 226, 226, 0.1);
    }

    100% {
        background-color: rgba(226, 226, 226, 0.05);
    }
}

@keyframes pop-RIGHT-0c3a75b7-a649-4aed-8d41-1ff3ed1c3919 {
    0% {
        transform: scale(0);
        animation-timing-function: cubic-bezier(0.52, 2.1, 0.43, 1.22);
    }

    35% {
        transform: scale(0.937);
        animation-timing-function: cubic-bezier(0.37, -0.25, 0.07, 2.15);
    }

    77% {
        transform: scale(0.995);
        animation-timing-function: cubic-bezier(0.28, -0.03, 0.48, 1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes pop-LEFT-bcc88dc6-8cb3-408d-945f-18fba6c49308 {
    0% {
        transform: scale(0);
        animation-timing-function: cubic-bezier(0.52, 2.1, 0.43, 1.22);
    }

    35% {
        transform: scale(0.937);
        animation-timing-function: cubic-bezier(0.37, -0.25, 0.07, 2.15);
    }

    77% {
        transform: scale(0.995);
        animation-timing-function: cubic-bezier(0.28, -0.03, 0.48, 1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes pop-LEFT-94ac6c46-caf6-4895-a3fd-b7910b9c8782 {
    0% {
        transform: scale(0);
        animation-timing-function: cubic-bezier(0.52, 2.1, 0.43, 1.22);
    }

    35% {
        transform: scale(0.937);
        animation-timing-function: cubic-bezier(0.37, -0.25, 0.07, 2.15);
    }

    77% {
        transform: scale(0.995);
        animation-timing-function: cubic-bezier(0.28, -0.03, 0.48, 1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes pop-RIGHT-999b0874-cb56-441a-88a9-b1506f7bc6ca {
    0% {
        transform: scale(0);
        animation-timing-function: cubic-bezier(0.52, 2.1, 0.43, 1.22);
    }

    35% {
        transform: scale(0.937);
        animation-timing-function: cubic-bezier(0.37, -0.25, 0.07, 2.15);
    }

    77% {
        transform: scale(0.995);
        animation-timing-function: cubic-bezier(0.28, -0.03, 0.48, 1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes pop-LEFT-ac31b8ee-2252-432c-bdfe-ee56b2aaf0aa {
    0% {
        transform: scale(0);
        animation-timing-function: cubic-bezier(0.52, 2.1, 0.43, 1.22);
    }

    35% {
        transform: scale(0.937);
        animation-timing-function: cubic-bezier(0.37, -0.25, 0.07, 2.15);
    }

    77% {
        transform: scale(0.995);
        animation-timing-function: cubic-bezier(0.28, -0.03, 0.48, 1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes pop-RIGHT-aeceb9cb-7108-422b-b092-a7fbf2fd154e {
    0% {
        transform: scale(0);
        animation-timing-function: cubic-bezier(0.52, 2.1, 0.43, 1.22);
    }

    35% {
        transform: scale(0.937);
        animation-timing-function: cubic-bezier(0.37, -0.25, 0.07, 2.15);
    }

    77% {
        transform: scale(0.995);
        animation-timing-function: cubic-bezier(0.28, -0.03, 0.48, 1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes pop-RIGHT-07a4b2ed-c2d8-41a7-80d2-6f007f174412 {
    0% {
        transform: scale(0);
        animation-timing-function: cubic-bezier(0.52, 2.1, 0.43, 1.22);
    }

    35% {
        transform: scale(0.937);
        animation-timing-function: cubic-bezier(0.37, -0.25, 0.07, 2.15);
    }

    77% {
        transform: scale(0.995);
        animation-timing-function: cubic-bezier(0.28, -0.03, 0.48, 1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes pop-RIGHT-0a852ff3-bde8-4e05-b13f-0ff7954775ca {
    0% {
        transform: scale(0);
        animation-timing-function: cubic-bezier(0.52, 2.1, 0.43, 1.22);
    }

    35% {
        transform: scale(0.937);
        animation-timing-function: cubic-bezier(0.37, -0.25, 0.07, 2.15);
    }

    77% {
        transform: scale(0.995);
        animation-timing-function: cubic-bezier(0.28, -0.03, 0.48, 1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes pop-LEFT-ed1129e2-d319-4d87-aa67-994f72a7835a {
    0% {
        transform: scale(0);
        animation-timing-function: cubic-bezier(0.52, 2.1, 0.43, 1.22);
    }

    35% {
        transform: scale(0.937);
        animation-timing-function: cubic-bezier(0.37, -0.25, 0.07, 2.15);
    }

    77% {
        transform: scale(0.995);
        animation-timing-function: cubic-bezier(0.28, -0.03, 0.48, 1.2);
    }

    100% {
        transform: scale(1);
    }
}